import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TableFilterService {
  pipe: DatePipe;

  filterValues = {
    Mfg: "",
    ModelID: "",
    Type: "",
    Status: "",
    TAssetID: "",
    AstType: "",
    MSN: "",
    Operator: "",
    RegistNo: "",
    OrgName: "",
    orgName: "",
    userName: "",
    role: "",
    name: "",
    status: "",
    modelNumber: "",
    lessor: "",
    operator: "",
    registration: "",
    AssetID: "",
    contract: "",
  };

  constructor() {
    this.pipe = new DatePipe('en');
  }

  createConfFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);

      return (
        data.Mfg.toLowerCase().indexOf(searchTerms.Mfg.toLowerCase()) >= 0 &&
        data.ModelID.toLowerCase().indexOf(searchTerms.ModelID.toLowerCase()) >= 0 &&
        data.Type.toLowerCase().indexOf(searchTerms.Type.toLowerCase()) >= 0 &&
        data.Status.toLowerCase().indexOf(searchTerms.Status.toLowerCase()) >= 0
      );
    };
    return filterFunction;
  }

  createAssetFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);

      return (
        data.TAssetID.toLowerCase().indexOf(searchTerms.TAssetID.toLowerCase()) >= 0 &&
        data.ModelID.toLowerCase().indexOf(searchTerms.ModelID.toLowerCase()) >= 0 &&
        data.MSN.toLowerCase().indexOf(searchTerms.MSN.toLowerCase()) >= 0 &&
        data.AstType.toLowerCase().indexOf(searchTerms.AstType.toLowerCase()) >= 0 &&
        data.Operator.toLowerCase().indexOf(searchTerms.Operator.toLowerCase()) >= 0 &&
        data.RegistNo.toLowerCase().indexOf(searchTerms.RegistNo.toLowerCase()) >= 0
      );
    };
    return filterFunction;
  }

  createOrgFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);

      return (
        data.OrgName.toLowerCase().indexOf(searchTerms.OrgName.toLowerCase()) >= 0 &&
        data.Status.toLowerCase().indexOf(searchTerms.Status.toLowerCase()) >= 0
      );
    };
    return filterFunction;
  }

  createUserFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);

      return (
        data.orgName.toLowerCase().indexOf(searchTerms.orgName.toLowerCase()) >= 0 &&
        data.userName.toLowerCase().indexOf(searchTerms.userName.toLowerCase()) >= 0 &&
        data.role.toLowerCase().indexOf(searchTerms.role.toLowerCase()) >= 0 &&
        data.name.toLowerCase().indexOf(searchTerms.name.toLowerCase()) >= 0 &&
        data.status.toLowerCase().indexOf(searchTerms.status.toLowerCase()) >= 0
      );
    };
    return filterFunction;
  }

  createContractFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);

      return (
        data.ModelID.toLowerCase().indexOf(searchTerms.modelNumber.toLowerCase()) >= 0 &&
        data.MSN.toLowerCase().indexOf(searchTerms.MSN.toLowerCase()) >= 0 &&
        data.LessorID.toLowerCase().indexOf(searchTerms.lessor.toLowerCase()) >= 0 &&
        data.Operator.toLowerCase().indexOf(searchTerms.operator.toLowerCase()) >= 0 &&
        data.RegistNo.toLowerCase().indexOf(searchTerms.registration.toLowerCase()) >= 0 &&
        data.ContNo.toLowerCase().indexOf(searchTerms.contract.toLowerCase()) >= 0 &&
        data.Status.toLowerCase().indexOf(searchTerms.status.toLowerCase()) >= 0
      );
    };
    return filterFunction;
  }

  createContMSNFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data, filter): boolean {
      let searchTerms = JSON.parse(filter);

      return (
        data.lessor.toLowerCase().indexOf(searchTerms.lessor.toLowerCase()) >= 0 &&
        data.operator.toLowerCase().indexOf(searchTerms.Operator.toLowerCase()) >= 0 &&
        data.modelNumb.toLowerCase().indexOf(searchTerms.ModelID.toLowerCase()) >= 0 &&
        data.MSN.toLowerCase().indexOf(searchTerms.MSN.toLowerCase()) >= 0 &&
        data.registration.toLowerCase().indexOf(searchTerms.RegistNo.toLowerCase()) >= 0 &&
        data.assetId.toLowerCase().indexOf(searchTerms.AssetID.toLowerCase()) >= 0
      );
    };
    return filterFunction;
  }

}
