<div class="container">
  <mat-card class="login-card custom-card col-lg-4 col-md-6 col-sm-10">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-card-title class="custom-card-title justify-content-center">
        <h5>SIGN IN</h5>
      </mat-card-title>

      <mat-card-content class="row">
        <mat-form-field class="col-sm-10 mt-4" appearance="legacy">
          <mat-label>
            <mat-icon matSuffix mat-icon>person</mat-icon> Username
          </mat-label>
          <input matInput placeholder="" formControlName="username">
          <mat-error *ngIf="submitted || f.username.errors?.required">
            Please provide a valid user name
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-sm-10" appearance="legacy">
          <mat-label>
            <mat-icon matSuffix class="d-inline" mat-icon>lock</mat-icon> Password
          </mat-label>
          <input type="password" matInput placeholder="" formControlName="password" #pwd>
          <mat-icon matSuffix class="pwd-icon" (click)="pwd.type=pwd.type=='password'?'text':'password'">
            {{ pwd.type == 'password' ? 'visibility_off' : 'visibility'  }}
          </mat-icon>
          <mat-error *ngIf="submitted || f.password.errors?.required">
            Please provide a valid password
          </mat-error>
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions class="row">
        <button type="submit" class="primary-btn m-3" mat-button>Login</button>
        <div class="col-sm-10 mb-2 ml-3">
          <a href="javascript:void(0)" class="forgot-pwd mt-2">
            Forgot password?
          </a>
        </div>
        <div class="col-sm-12">
          <span class="noaccnt">
            Don't have an account?
            <a href="javascript:void(0)" class="ml-2">Sign Up</a>
          </span>
        </div>
      </mat-card-actions>

    </form>
  </mat-card>
</div>
