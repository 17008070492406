<div class="confMngt">
  <h4><strong> <a [routerLink]="['/conf-mngt']">
        <mat-icon>arrow_back</mat-icon>
      </a> Create New Model </strong></h4>
  <mat-divider></mat-divider>

  <div class="confMngt-table mt-3 table-responsive">
    <form [formGroup]="confMngtForm" (ngSubmit)="onSubmit()">

      <table mat-table [dataSource]="newConfSource">
        <ng-container matColumnDef="manufacturer">
          <th mat-header-cell *matHeaderCellDef> Manufacturer </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="manufacturer" placeholder="Manufacturer" [value]="element.manufacturer">
                <mat-option *ngFor="let opt of manufacturerOpts" [value]="opt.viewValue">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.manufacturer.errors?.required || f.manufacturer.touched">
                Please enter the manufacturer
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="modelNumber">
          <th mat-header-cell *matHeaderCellDef> Model Number </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="modelNumber" placeholder="Model Number" [value]="element.modelNumber">
              <mat-error *ngIf="submitted || f.modelNumber.errors?.required || f.modelNumber.touched">
                Please enter the model number
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Asset Type </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ this.confMngtForm.value.type }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="status" placeholder="Status" [value]="element.status">
                <mat-option *ngFor="let opt of recordStatus" [value]="opt.viewValue">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.status.errors?.required || f.status.touched">
                Please select the status
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
            <button type="submit" mat-button class="save-btn" [disabled]="isSaved">
              <mat-icon>save</mat-icon> Save
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </form>
  </div>

  <div class="confMngt-table mt-3 table-responsive">
    <form [formGroup]="confMngtDetailsForm" (ngSubmit)="onSubmitDetails()">

      <table mat-table formArrayName="confMngtDetails" [dataSource]="newConfDetailsSource">
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> Position </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <ng-container *ngIf="rowIndex == 0 || rowIndex == 3">
              <span class="no-edit"></span>
            </ng-container>
            <ng-container *ngIf="rowIndex != 0 && rowIndex != 3">
              <mat-form-field floatLabel="never">
                <mat-select formControlName="position" placeholder="Position" [value]="element.position" required>
                  <mat-option *ngFor="let opt of positionOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Asset Type </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <ng-container *ngIf="rowIndex == 0">
              <span class="no-edit">Airframe</span>
            </ng-container>
            <ng-container *ngIf="rowIndex != 0">
              <mat-form-field floatLabel="never">
                <mat-select formControlName="type" placeholder="Asset Type" [value]="element.type" required>
                  <mat-option *ngFor="let opt of assetTypeOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="partNumber">
          <th mat-header-cell *matHeaderCellDef> Part Number </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <ng-container *ngIf="rowIndex == 0">
              <span class="no-edit"></span>
            </ng-container>
            <ng-container *ngIf="rowIndex != 0">
              <mat-form-field floatLabel="never">
                <input matInput formControlName="partNumber" placeholder="Part Number" [value]="element.partNumber"
                  required>
              </mat-form-field>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
      </table>

      <div class="table-actions mt-2 mb-2">
        <div class="float-right mb-2">
          <button type="button" mat-button class="primary-btn mr-2" (click)="addRow()" [disabled]="isDetailsSaved">
            <mat-icon>create</mat-icon> New Row
          </button>
          <button type="submit" mat-button class="save-btn mr-2" [disabled]="isDetailsSaved">
            <mat-icon>save</mat-icon> Save
          </button>
        </div>
      </div>

    </form>
  </div>
  <br>
</div>
