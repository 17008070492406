<div class="orgMngt">
  <h4><strong> <a [routerLink]="['/org-master']">
        <mat-icon>arrow_back</mat-icon>
      </a> Create New Organization </strong></h4>
  <mat-divider></mat-divider>

  <form [formGroup]="orgMngtForm" (ngSubmit)="onSubmit()">
    <div class="orgMngt-table mt-3 table-responsive">

      <table mat-table [dataSource]="newOrgSource">
        <ng-container matColumnDef="orgName">
          <th mat-header-cell *matHeaderCellDef> Organization Name </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="orgName" placeholder="Organization Name" [value]="element.orgName">
              <mat-error *ngIf="submitted || f.orgName.errors?.required || f.orgName.touched">
                Please enter the orgName
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="orgType">
          <th mat-header-cell *matHeaderCellDef> Organization Type </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="orgType" placeholder="Organization Type" [value]="element.orgType">
                <mat-option *ngFor="let opt of orgTypeOpts" [value]="opt.viewValue">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.orgType.errors?.required || f.orgType.touched">
                Please enter the orgType
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef> Address </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="address" placeholder="Address" [value]="element.address">
              <mat-error *ngIf="submitted || f.address.errors?.required || f.address.touched">
                Please enter the address
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="status" placeholder="Status" [value]="element.status">
                <mat-option *ngFor="let opt of statusOpts" [value]="opt.value">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.status.errors?.required || f.status.touched">
                Please select the status
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <div class="orgMngt-table mt-3 table-responsive">
      <table mat-table [dataSource]="newOrgDetailsSource">
        <ng-container matColumnDef="bankName">
          <th mat-header-cell *matHeaderCellDef> Bank Name </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="bankName" placeholder="Bank Name" [value]="element.bankName">
              <mat-error *ngIf="submitted || f.bankName.errors?.required || f.bankName.touched">
                Please enter the bankName
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef> Number </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="number" placeholder="Number" [value]="element.number">
              <mat-error *ngIf="submitted || f.number.errors?.required || f.number.touched">
                Please enter the number
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="accntNumb">
          <th mat-header-cell *matHeaderCellDef> Account Number </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="accntNumb" placeholder="Account Number" [value]="element.accntNumb">
              <mat-error *ngIf="submitted || f.accntNumb.errors?.required || f.accntNumb.touched">
                Please enter the account number
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
      </table>
    </div>

    <div class="table-actions mt-2 mb-2">
      <div class="float-right">
        <button type="submit" mat-button class="save-btn mr-2">
          <mat-icon>save</mat-icon> Save
        </button>
      </div>
    </div>
  </form>

</div>
