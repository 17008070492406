export class User {
  constructor(
    public userName: string,
    public role: string,
    public orgName: string,
    public orgId: string,
    public name: string,
    private token?: string
  ) { }

  public get getToken() {
    return this.token;
  }
}
