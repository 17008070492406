import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { FormControl, FormArray } from '@angular/forms';
import { TableFilterService } from 'src/app/services/table-filter.service';
import { GetDetailService } from 'src/app/services/getDetails.service';

@Component({
  selector: 'app-contract-mngt',
  templateUrl: './contract-mngt.component.html',
  styleUrls: ['./contract-mngt.component.scss']
})
export class ContractMngtComponent implements OnInit {

  currentUser: any;
  col1Clicked: boolean = false;
  col2Clicked: boolean = false;
  col3Clicked: boolean = false;
  col4Clicked: boolean = false;
  col5Clicked: boolean = false;
  col6Clicked: boolean = false;
  col7Clicked: boolean = false;

  sortedRes: any = [];

  contractMngtData: any = [];
  UpdatedcontractMngtData: FormArray;
  displayedColumns: any[] = ['modelNumber', 'MSN', 'lessor', 'operator', 'registration', 'contract', 'startDate', 'endDate', 'status'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('cmSort', { static: true, read: MatSort }) cmSort: MatSort;

  modelNumberFilter = new FormControl("");
  MSNFilter = new FormControl("");
  lessorFilter = new FormControl("");
  operatorFilter = new FormControl("");
  registrationFilter = new FormControl("");
  contractFilter = new FormControl("");
  statusFilter = new FormControl("");

  constructor(
    private tableFilter: TableFilterService,
    private router: Router,
    private gd: GetDetailService) {
  }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.getContent();

    this.modelNumberFilter.valueChanges.subscribe(modelNumber => {
      this.tableFilter.filterValues.modelNumber = modelNumber;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.MSNFilter.valueChanges.subscribe(MSN => {
      this.tableFilter.filterValues.MSN = MSN;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.lessorFilter.valueChanges.subscribe(lessor => {
      this.tableFilter.filterValues.lessor = lessor;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.operatorFilter.valueChanges.subscribe(operator => {
      this.tableFilter.filterValues.operator = operator;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.registrationFilter.valueChanges.subscribe(registration => {
      this.tableFilter.filterValues.registration = registration;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.contractFilter.valueChanges.subscribe(contract => {
      this.tableFilter.filterValues.contract = contract;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.statusFilter.valueChanges.subscribe(status => {
      this.tableFilter.filterValues.status = status;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
  }

  getContent() {

    if (this.currentUser.role == "Admin") {
      this.gd.getAllContracts()
        .subscribe(res => {
          console.log("all contracts :", res);
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }

          this.dataSource = new MatTableDataSource(this.sortedRes);
          this.dataSource.sort = this.cmSort;
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = this.tableFilter.createContractFilter();

        });
    } else if (this.currentUser.role == "Lessor Manager" || this.currentUser.role == "Lessor Accountant") {
      this.gd.getAllContractsLessor(this.currentUser.orgName)
        .subscribe(res => {
          console.log("all contracts :", res);
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }

          this.dataSource = new MatTableDataSource(this.sortedRes);
          this.dataSource.sort = this.cmSort;
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = this.tableFilter.createContractFilter();

        });
    }

  }

  clear(dataFilter) {
    if (dataFilter == 'modelNumberFilter') {
      this.modelNumberFilter.setValue('')
    } else if (dataFilter == 'MSNFilter') {
      this.MSNFilter.setValue('')
    } else if (dataFilter == 'lessorFilter') {
      this.lessorFilter.setValue('')
    } else if (dataFilter == 'operatorFilter') {
      this.operatorFilter.setValue('')
    } else if (dataFilter == 'operatorFilter') {
      this.operatorFilter.setValue('')
    } else if (dataFilter == 'registrationFilter') {
      this.registrationFilter.setValue('')
    } else if (dataFilter == 'contractFilter') {
      this.contractFilter.setValue('')
    } else if (dataFilter == 'statusFilter') {
      this.statusFilter.setValue('')
    }
  }

  onRedirectToContractDetails(contract, row) {
    this.router.navigateByUrl('/contract-mngt/' + contract)
  }

  createContract(val1, val2) {
    this.router.navigateByUrl('/contract-mngt/' + val1 + '/' + val2)
  }

}
