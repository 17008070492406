<div class="orgMngt">
  <h4><strong> <a [routerLink]="['/user-mngt']">
        <mat-icon>arrow_back</mat-icon>
      </a> Create New User </strong></h4>
  <mat-divider></mat-divider>

  <div class="orgMngt-table mt-3 table-responsive">
    <form [formGroup]="userMngtForm" (ngSubmit)="onSubmit()">

      <table mat-table [dataSource]="newOrgSource">
        <ng-container matColumnDef="orgName">
          <th mat-header-cell *matHeaderCellDef> Organization Name </th>
          <ng-container *ngIf="isAdmin == false">
            <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ currentUser?.orgName }} </td>
          </ng-container>
          <ng-container *ngIf="isAdmin == true">
            <td mat-cell *matCellDef="let element">
              <mat-form-field floatLabel="never">
                <mat-select formControlName="orgName" placeholder="Organization Name" [value]="element.orgName">
                  <mat-option *ngFor="let opt of orgOpts" [value]="opt.value">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="submitted || f.orgName.errors?.required || f.orgName.touched">
                  Please select the Organization Name
                </mat-error>
              </mat-form-field>
            </td>
          </ng-container>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef> User Name </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="userName" placeholder="User Name" [value]="element.userName">
              <mat-error *ngIf="submitted || f.userName.errors?.required || f.userName.touched">
                Please enter the user name
              </mat-error>
              <mat-hint>E.g. LRM-234</mat-hint>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef> Role </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="role" placeholder="Role" [value]="element.role">
                <mat-option *ngFor="let opt of roleOpts" [value]="opt.value">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.role.errors?.required || f.role.touched">
                Please select the role
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="name" placeholder="Name" [value]="element.name">
              <mat-error *ngIf="submitted || f.name.errors?.required || f.name.touched">
                Please enter the name
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="module">
          <th mat-header-cell *matHeaderCellDef> Module </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="module" placeholder="Module" multiple [value]="element.module">
                <mat-option *ngFor="let opt of moduleOpts" [value]="opt.value">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.module.errors?.required || f.module.touched">
                Please select the module
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="status" placeholder="Status" [value]="element.status">
                <mat-option *ngFor="let opt of statusOpts" [value]="opt.value">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.status.errors?.required || f.status.touched">
                Please select the status
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="table-actions mt-2 mb-2">
        <div class="float-right">
          <button type="submit" mat-button class="save-btn mr-2">
            <mat-icon>save</mat-icon> Save
          </button>
        </div>
      </div>
    </form>
  </div>

</div>
