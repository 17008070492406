import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { GetDetailService } from 'src/app/services/getDetails.service';
import { CreateDetails } from 'src/app/services/createDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContractMsnDialogComponent } from 'src/app/helpers/dialog/contract-msn-dialog/contract-msn-dialog.component';

@Component({
  selector: 'app-create-new-contract',
  templateUrl: './create-new-contract.component.html',
  styleUrls: ['./create-new-contract.component.scss']
})
export class CreateNewContractComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private alert: MatSnackBar,
    private gd: GetDetailService,
    private cd: CreateDetails,
    private snotService: SnotifyService) {
    this.newContractData = [
      { contract: '', lessor: '', operator: '', modelNumber: '', MSN: '', startDate: '', endDate: '', status: '' },
    ]
    this.newContractSource = new MatTableDataSource(this.newContractData);
    this.showModelDetails = false
  }

  currentUser: any;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDetailsSaved: boolean = false;

  contractMngtForm: FormGroup;
  get f() { return this.contractMngtForm.controls; }

  contractMngtDetailsForm: FormGroup;
  get fd() { return this.contractMngtDetailsForm.controls; }

  contNumb: any;
  showModelDetails: boolean = false;

  pickListValues = new PickList();
  recordStatus = this.pickListValues.recStatus
  positionOpts = this.pickListValues.position
  assetTypeOpts = this.pickListValues.type
  paramsOpts = this.pickListValues.utilParams
  statusOpts = this.pickListValues.billStatus

  modelNumbOpts = []
  lessorOpts = []
  lesseeOpts = []

  newContractData: any = [];
  displayedColumns: any[] = ['contract', 'lessor', 'operator', 'modelNumber', 'registration', 'MSN', 'startDate', 'endDate', 'status', 'action'];
  newContractSource: MatTableDataSource<any>;

  newContractDetailsData: any = [];
  updatedContractDetailsData: any = [];

  displayedDetailsColumns: any[] = ['position', 'type', 'partNumber', 'utilParam1', 'utilRate1', 'utilParam2', 'utilRate2', 'utilParam3', 'utilRate3', 'utilParam4', 'utilRate4', 'rentalRate1', 'rentalRate2', 'rentalRate3', 'rentalRate4'];
  newContractDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  contractMSNDialogRef: MatDialogRef<ContractMsnDialogComponent>;
  selectedMSNDetails: any = {}

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.contractMngtForm = this.fb.group({
      contract: [''],
      lessor: [{ value: '', disabled: true }],
      operator: [{ value: '', disabled: true }],
      modelNumber: [{ value: '', disabled: true }],
      registration: [{ value: '', disabled: true }],
      MSN: [{ value: '', disabled: true }, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      status: [null, Validators.required],
    })
    this.getContent();
  }

  private setcontractMngtDetailsForm() {
    const rowDetailsCtrl = this.contractMngtDetailsForm.get('contractMngtDetails') as FormArray;
    (this.newContractDetailsData).forEach((rowDetails) => {
      rowDetailsCtrl.push(this.setcontractMngtDetailsFormArray(rowDetails))
    })
  };
  private setcontractMngtDetailsFormArray(rowDetails) {
    return this.fb.group({
      position: [rowDetails.position],
      type: [rowDetails.type],
      partNumber: [rowDetails.partNumber],
      utilParam1: [rowDetails.utilParam1],
      utilRate1: [rowDetails.utilRate1],
      utilParam2: [rowDetails.utilParam2],
      utilRate2: [rowDetails.utilRate2],
      utilParam3: [rowDetails.utilParam3],
      utilRate3: [rowDetails.utilRate3],
      utilParam4: [rowDetails.utilParam4],
      utilRate4: [rowDetails.utilRate4],
      rentalRate1: [rowDetails.rentalRate1],
      rentalRate2: [rowDetails.rentalRate2],
      rentalRate3: [rowDetails.rentalRate3],
      rentalRate4: [rowDetails.rentalRate4],
    });
  }

  getContent() {
    this.gd.getAllModels()
      .subscribe(res => {
        console.log("all assets :", res);
        res.forEach(element => {
          this.modelNumbOpts.push({ viewValue: element.ModelID })
        });
      });

    this.gd.getAllOrgs()
      .subscribe(res => {
        console.log("all orgs :", res);
        res.forEach(element => {
          if (element.OrgType == 'Lessor') {
            this.lessorOpts.push({ viewValue: element.OrgName })
          }
          else if (element.OrgType == 'Lessee') {
            this.lesseeOpts.push({ viewValue: element.OrgName })
          }
        });
      });
  }

  onSubmit() {
    console.log("saveNewContractData table: ", this.newContractData);

    if (this.f.MSN.value == '') {
      return this.alert.open("Please select MSN", "OK", { duration: 4000, verticalPosition: 'top' });
    }

    this.submitted = true;
    if (this.contractMngtForm.invalid) {
      console.log("Invalid Form: ", this.contractMngtForm);
      return;
    }

    console.log("Valid Form: ", this.contractMngtForm);

    const startDt = JSON.stringify(Math.round((this.contractMngtForm.value.startDate).getTime() / 1000));
    const endDt = JSON.stringify(Math.round((this.contractMngtForm.value.endDate).getTime() / 1000));

    var data = {
      "ModelID": this.f.modelNumber.value,
      "MSN": this.f.MSN.value,
      "Operator": this.f.operator.value,
      "RegistNo": this.f.registration.value,
      "TAssetID": this.selectedMSNDetails.assetId,
      "LessorID": this.f.lessor.value,
      "StartDt": startDt,
      "EndDt": endDt,
      "Status": this.contractMngtForm.value.status,
      "CreateBy": this.currentUser.userName
    }
    console.log("new contract data: ", data);

    this.cd.createContract(data)
      .subscribe(r => {
        console.log("r :" + r, JSON.stringify(r));
        this.contNumb = r.Data
        if (r.status == 'success') {
          this.isSaved = true
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("New ContractID: " + this.contNumb + " created successfully!", snConfig);

          this.gd.getModelDetails(data.ModelID)
            .subscribe(res => {
              console.log("model details  :", res);
              this.newContractDetailsData = []
              res.forEach(el => {
                if (el.AstType == 'Airframe') {
                  this.newContractDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, utilParam1: 'FH: 6 Years', utilRate1: '', utilParam2: 'FH: 8 Years', utilRate2: '', utilParam3: 'FH: 10 Years', utilRate3: '', utilParam4: 'FH: 12 Years', utilRate4: '', rentalRate1: '', rentalRate2: '', rentalRate3: '', rentalRate4: '' })
                } else if (el.AstType == 'APU') {
                  this.newContractDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, utilParam1: 'APU Cycles', utilRate1: '', utilParam2: 'APU Hours', utilRate2: '' })
                } else {
                  this.newContractDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, utilParam1: 'FC', utilRate1: '', utilParam2: 'FH', utilRate2: '' })
                }
              });
              this.newContractDetailsData.sort((a, b) => a.type.localeCompare(b.type))

              this.newContractDetailsSource = new MatTableDataSource(this.newContractDetailsData);
            })

          setTimeout(() => {
            this.contractMngtDetailsForm = this.fb.group({
              contractMngtDetails: this.fb.array([])
            })
            this.setcontractMngtDetailsForm();
            this.contractMngtDetailsForm.get('contractMngtDetails').valueChanges.subscribe(contractMngtDetails => {
              this.updatedContractDetailsData = contractMngtDetails
            });

            this.showModelDetails = true
          }, 2000);

        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("New ContractID: " + this.contNumb + " creation failed!", snConfig);
        }
      });
  }

  onViewContractMSN(index) {
    this.selectedMSNDetails = {}
    this.contractMSNDialogRef = this.dialog.open(ContractMsnDialogComponent, {
      data: {},
      disableClose: true
    });

    this.contractMSNDialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.selectedMSNDetails = result

      this.f.lessor.setValue(this.selectedMSNDetails.lessor)
      this.f.operator.setValue(this.selectedMSNDetails.operator)
      this.f.modelNumber.setValue(this.selectedMSNDetails.modelNumb)
      this.f.MSN.setValue(this.selectedMSNDetails.MSN)
      this.f.registration.setValue(this.selectedMSNDetails.registration)

    })
  }

  onSubmitDetails() {
    console.log("saveNewContractDetails table: ", this.newContractDetailsData, this.contractMngtDetailsForm);

    this.submitted = true;
    if (this.contractMngtDetailsForm.invalid) {
      console.log("Invalid Form: ", this.contractMngtDetailsForm);
      return;
    }

    console.log("Valid Form: ", this.contractMngtDetailsForm);
    let data = []
    for (let i = 0; i < this.updatedContractDetailsData.length; i++) {
      var obj = {
        "ContNo": this.contNumb,
        "Position": this.newContractDetailsData[i].position,
        "AstType": this.newContractDetailsData[i].type,
        "PartNo": this.newContractDetailsData[i].partNumber,
        "RR1": this.updatedContractDetailsData[i].rentalRate1,
        "RR2": this.updatedContractDetailsData[i].rentalRate2,
        "RR3": this.updatedContractDetailsData[i].rentalRate3,
        "RR4": this.updatedContractDetailsData[i].rentalRate4,
        "Param1": this.updatedContractDetailsData[i].utilParam1,
        "Rate1": this.updatedContractDetailsData[i].utilRate1,
        "Param2": this.updatedContractDetailsData[i].utilParam2,
        "Rate2": this.updatedContractDetailsData[i].utilRate2,
        "Param3": this.updatedContractDetailsData[i].utilParam3,
        "Rate3": this.updatedContractDetailsData[i].utilRate3,
        "Param4": this.updatedContractDetailsData[i].utilParam4,
        "Rate4": this.updatedContractDetailsData[i].utilRate4,
        "CreateBy": this.currentUser.userName
      }
      data.push(obj)
    }
    console.log("new contract details data: ", data);

    this.cd.createContractDetails(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.isDetailsSaved = true
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("New details of ContractID: " + this.contNumb + " created successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("New details of ContractID: " + this.contNumb + " creation failed!", snConfig);
        }
      });
  }

}


