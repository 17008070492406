import { Injectable, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class TableEditService {

  currentUser: any;

  userMngtData: any = [];
  userMngtSource: MatTableDataSource<any>;

  updatedDataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  }

  update(index, field, control, data) {
    if (control.valid) {
      console.log("update fileld: ", index, field, control, data);
      data = data.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value
          }
        }
        return e;
      });
      this.onChangeDataSource(data);
    }

  }

  onChangeDataSource(updatedValue) {
    this.updatedDataSource = new MatTableDataSource(updatedValue);
  }

}
