<div class="assetMngt">
  <h4><strong> <a [routerLink]="['/asset-mngt']">
        <mat-icon>arrow_back</mat-icon>
      </a> Details of Asset Number : {{ id }} </strong></h4>
  <div class="editInfo m-3" *ngIf="activeTab !== 2">
    <small>* Blue colored data are editable in the table, click on the data to edit </small>
  </div>
  <mat-divider></mat-divider>

  <div class="assetMngt-table mt-3 table-responsive" *ngIf="activeTab == 0">
    <table mat-table [dataSource]="confDataSource">
      <ng-container matColumnDef="asset">
        <th mat-header-cell *matHeaderCellDef> Top Asset </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.asset }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef> Model Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.modelNumber }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="MSN">
        <th mat-header-cell *matHeaderCellDef> MSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="age">
        <th mat-header-cell *matHeaderCellDef> Age (Years) </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.age }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="lessor">
        <th mat-header-cell *matHeaderCellDef> Lessor </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.lessor }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef> Operator </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.operator }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="registration">
        <th mat-header-cell *matHeaderCellDef> Registration </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateConfField(index, 'registration')">
            <ng-template viewMode *ngIf="element?.registration == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{element?.registration}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getConfControl(index, 'registration')" (change)="changeConfVal()"
                  editableOnEnter>
                <mat-error *ngIf="getConfControl(index, 'registration').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef> Contract </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateConfField(index, 'contract')">
            <ng-template viewMode *ngIf="element?.contract == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{element?.contract}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.contract" placeholder="Contract" matInput
                  [formControl]="getConfControl(index, 'contract')" (selectionChange)="changeConfVal()" editableOnEnter>
                  <mat-option *ngFor="let opt of contNoOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getConfControl(index, 'contract').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateConfField(index, 'status')">
            <ng-template viewMode *ngIf="element?.status == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{element?.status}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.status" placeholder="Status" matInput
                  [formControl]="getConfControl(index, 'status')" (selectionChange)="changeConfVal()" editableOnEnter>
                  <mat-option *ngFor="let opt of assetStatusOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getConfControl(index, 'status').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <button mat-button class="save-btn" (click)="onSubmitConf()" [disabled]="noChangeConf">
            <mat-icon>save</mat-icon> Save
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedConfColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedConfColumns;"></tr>

    </table>
  </div>

  <div class="assetMngt-table mt-3 table-responsive" *ngIf="activeTab == 1">
    <table mat-table [dataSource]="utilDataSource">
      <ng-container matColumnDef="asset">
        <th mat-header-cell *matHeaderCellDef> Top Asset </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.asset }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef> Model Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.modelNumber }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="MSN">
        <th mat-header-cell *matHeaderCellDef> MSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="mnftYear">
        <th mat-header-cell *matHeaderCellDef> Age (Years) </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.mnftYear }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="lessor">
        <th mat-header-cell *matHeaderCellDef> Lessor </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.lessor }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef> Operator </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.operator }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="registration">
        <th mat-header-cell *matHeaderCellDef> Registration </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateUtilField(index, 'registration')">
            <ng-template viewMode *ngIf="element?.registration == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{element?.registration}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getUtilControl(index, 'registration')" (change)="changeUtilVal()"
                  editableOnEnter>
                <mat-error *ngIf="getUtilControl(index, 'registration').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef> Contract </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.contract }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editableDate (update)="updateUtilField(index, 'startDate')">
            <ng-template viewMode *ngIf='startDt == ""'>
              -----
            </ng-template>
            <ng-template viewMode>
              {{((startDt)) | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="startDate" (click)="startDate.open()"
                  [max]="getUtilControl(index, 'endDate')" [(value)]="element.startDate"
                  [formControl]="getUtilControl(index, 'startDate')"
                  (dateChange)="changeUtilVal();onStartDtChange($event)" placeholder="Start Date" editableOnEnter>
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
                <mat-hint align="end">Click the Date picker icon</mat-hint>
                <mat-error *ngIf="getUtilControl(index, 'startDate').hasError('required')">
                  Please provide a valid Start Date
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editableDate>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editableDate (update)="updateUtilField(index, 'endDate')">
            <ng-template viewMode *ngIf="endDt == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{((endDt)) | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="endDate" (click)="endDate.open()" [min]="element.startDate"
                  [(value)]="element.endDate" [formControl]="getUtilControl(index, 'endDate')"
                  (dateChange)="changeUtilVal();onEndDtChange($event)" placeholder="End Date" editableOnEnter>
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
                <mat-hint align="end">Click the Date picker icon</mat-hint>
                <mat-error *ngIf="getUtilControl(index, 'endDate').hasError('required')">
                  Please provide a valid End Date
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editableDate>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="utilStatus">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateUtilField(index, 'utilStatus')">
            <ng-template viewMode>
              {{element?.utilStatus}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.utilStatus" placeholder="Status" matInput
                  [formControl]="getUtilControl(index, 'utilStatus')" (selectionChange)="changeUtilVal()"
                  editableOnEnter>
                  <mat-option *ngFor="let opt of billStatusOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getUtilControl(index, 'utilStatus').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <button mat-button class="save-btn" (click)="onSubmitUtil()" [disabled]="noChangeUtil">
            <mat-icon>save</mat-icon> Save
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedUtilColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedUtilColumns;"></tr>

    </table>
  </div>

  <div class="assetMngt-table mt-3 table-responsive" *ngIf="activeTab == 2">
    <table mat-table [dataSource]="mntncDataSource">
      <ng-container matColumnDef="asset">
        <th mat-header-cell *matHeaderCellDef> Top Asset </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.asset }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef> Model Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.modelNumber }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="MSN">
        <th mat-header-cell *matHeaderCellDef> MSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="mnftYear">
        <th mat-header-cell *matHeaderCellDef> Age (Years) </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.mnftYear }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="lessor">
        <th mat-header-cell *matHeaderCellDef> Lessor </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.lessor }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef> Operator </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.operator }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="registration">
        <th mat-header-cell *matHeaderCellDef> Registration </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.registration }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef> Contract </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.contract }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedMntncColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedMntncColumns;"></tr>

    </table>
  </div>

  <div class="assetMngt-table mt-3 table-responsive" *ngIf="activeTab == 3">
    <table mat-table [dataSource]="billDataSource">
      <ng-container matColumnDef="asset">
        <th mat-header-cell *matHeaderCellDef> Top Asset </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.asset }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef> Model Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.modelNumber }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="MSN">
        <th mat-header-cell *matHeaderCellDef> MSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="mnftYear">
        <th mat-header-cell *matHeaderCellDef> Age (Years) </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.mnftYear }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="lessor">
        <th mat-header-cell *matHeaderCellDef> Lessor </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.lessor }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef> Operator </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.operator }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="registration">
        <th mat-header-cell *matHeaderCellDef> Registration </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.registration }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef> Contract </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.contract }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="openDate">
        <th mat-header-cell *matHeaderCellDef> Opening Date </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editableDate (update)="updateBillField(index, 'openDate')">
            <ng-template viewMode *ngIf="openDt == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{((openDt)) | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="openDate" (click)="openDate.open()"
                  [max]="getBillControl(index, 'endDate')" [(value)]="element.openDate"
                  [formControl]="getBillControl(index, 'openDate')"
                  (dateChange)="changeBillVal();onOpenDtChange($event)" placeholder="Opening Date" editableOnEnter>
                <mat-datepicker-toggle matSuffix [for]="openDate"></mat-datepicker-toggle>
                <mat-datepicker #openDate></mat-datepicker>
                <mat-hint align="end">Click the Date picker icon</mat-hint>
                <mat-error *ngIf="getBillControl(index, 'openDate').hasError('required')">
                  Please provide a valid Start Date
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editableDate>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="closeDate">
        <th mat-header-cell *matHeaderCellDef> Closing Date </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editableDate (update)="updateBillField(index, 'closeDate')">
            <ng-template viewMode *ngIf="closeDt == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{((closeDt)) | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="closeDate" (click)="closeDate.open()" [min]="element.openDate"
                  [(value)]="element.closeDate" [formControl]="getBillControl(index, 'closeDate')"
                  (dateChange)="changeBillVal();onCloseDtChange($event)" placeholder="Closing Date" editableOnEnter>
                <mat-datepicker-toggle matSuffix [for]="closeDate"></mat-datepicker-toggle>
                <mat-datepicker #closeDate></mat-datepicker>
                <mat-hint align="end">Click the Date picker icon</mat-hint>
                <mat-error *ngIf="getBillControl(index, 'closeDate').hasError('required')">
                  Please provide a valid End Date
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editableDate>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="totalAmnt">
        <th mat-header-cell *matHeaderCellDef> Total Amount </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ allTotalAmnt | currency: 'USD' }}
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="wireRef">
        <th mat-header-cell *matHeaderCellDef> Wire Ref. </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateBillField(index, 'wireRef')">
            <ng-template viewMode *ngIf="element?.wireRef == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{element?.wireRef}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getBillControl(index, 'wireRef')" (change)="changeBillVal()"
                  editableOnEnter>
                <mat-error *ngIf="getBillControl(index, 'wireRef').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="billStatus">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateBillField(index, 'billStatus')">
            <ng-template viewMode>
              {{element?.billStatus}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.billStatus" placeholder="Status" matInput
                  [formControl]="getBillControl(index, 'billStatus')" (selectionChange)="changeBillVal()"
                  editableOnEnter>
                  <mat-option *ngFor="let opt of billStatusOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getBillControl(index, 'billStatus').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="revNumb">
        <th mat-header-cell *matHeaderCellDef> Rev # </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.revNumb }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <button mat-button class="save-btn" (click)="onSubmitBill()" [disabled]="noChangeBill">
            <mat-icon>save</mat-icon> Save
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedBillColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedBillColumns;"></tr>

    </table>
  </div>

  <br><br>

  <div>
    <mat-card class="asset-card">
      <mat-card-content>
        <mat-tab-group mat-stretch-tabs dynamicHeight (selectedTabChange)="tabChanged($event)">
          <mat-tab>
            <ng-template mat-tab-label>
              <strong>Configuration</strong>
            </ng-template>
            <div class="mat-tab-content">
              <app-asset-mngt-configuration></app-asset-mngt-configuration>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <strong>Utilization</strong>
            </ng-template>
            <div class="mat-tab-content" *ngIf="assetDetailsUtil.length == 0">
              <app-asset-mngt-utilization [startDt]="startDt" [endDt]="endDt"></app-asset-mngt-utilization>
            </div>
            <div class="mat-tab-content" *ngIf="assetDetailsUtil.length > 0">
              <app-view-utilization [assetUtilDetailsData]="assetUtilDetailsData"></app-view-utilization>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <strong>Maintenance</strong>
            </ng-template>
            <div class="mat-tab-content">
              <app-asset-mngt-maintenance></app-asset-mngt-maintenance>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <strong>Billing</strong>
            </ng-template>
            <div class="mat-tab-content" *ngIf="assetDetailsBill.length == 0 && isLessee == false">
              <app-asset-mngt-billing [openDt]="openDt" [closeDt]="closeDt" [assetDetailsUtil]="assetDetailsUtil">
              </app-asset-mngt-billing>
            </div>
            <div *ngIf="assetDetailsBill.length == 0 && isLessee == true">
              <mat-toolbar-row class="toolbar-row">
                <span class="noRecords">No records found!</span>
              </mat-toolbar-row>
            </div>
            <div class="mat-tab-content" *ngIf="assetDetailsBill.length > 0">
              <app-view-billing [assetBillDetailsData]="assetBillDetailsData"></app-view-billing>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>

  <br>

  <div *ngIf="activeTab == 1">
    <mat-card class="custom-card col-sm-12">
      <mat-card-title class="custom-card-title col-sm-12">
        <h4 class="text-white">Additional Information</h4>
      </mat-card-title>
      <mat-card-content>
        <div class="addInfo-form mt-3">
          <form [formGroup]="addInfoForm" (ngSubmit)="onSubmitAddInfo()">
            <div class="row">

              <mat-label class="col-sm-2">Engine P1 MSN currently installed on Airframe</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput formControlName="currentMSN1" (change)="changeFormVal()">
                <mat-error *ngIf="submitted || f.currentMSN1.errors?.required || f.currentMSN1.touched">
                  Please enter the engine MSN
                </mat-error>
              </mat-form-field>

              <mat-label class="col-sm-2">Engine P2 MSN currently installed on Airframe</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput formControlName="currentMSN2" (change)="changeFormVal()">
                <mat-error *ngIf="submitted || f.currentMSN2.errors?.required || f.currentMSN2.touched">
                  Please enter the engine MSN
                </mat-error>
              </mat-form-field>

              <mat-label class="col-sm-2">Registration</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput formControlName="registration" (change)="changeFormVal()">
                <mat-error *ngIf="submitted || f.registration.errors?.required || f.registration.touched">
                  Please enter the registration
                </mat-error>
              </mat-form-field>

              <mat-label class="col-sm-2">Engine P1 Present location</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput formControlName="engLocation1" (change)="changeFormVal()">
                <mat-error *ngIf="submitted || f.engLocation1.errors?.required || f.engLocation1.touched">
                  Please enter the engine present location
                </mat-error>
              </mat-form-field>

              <mat-label class="col-sm-2"> Engine P2 Present location</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput formControlName="engLocation2" (change)="changeFormVal()">
                <mat-error *ngIf="submitted || f.engLocation2.errors?.required || f.engLocation2.touched">
                  Please enter the engine present location
                </mat-error>
              </mat-form-field>

              <mat-label class="col-sm-2">APU MSN currently installed on Airframe</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput formControlName="apuMSN" (change)="changeFormVal()">
                <mat-error *ngIf="submitted || f.apuMSN.errors?.required || f.apuMSN.touched">
                  Please enter the APU MSN
                </mat-error>
              </mat-form-field>

              <mat-label class="col-sm-2">Average thrust derate reported for Engine P1</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput formControlName="thrust1" (change)="changeFormVal()">
                <mat-error *ngIf="submitted || f.thrust1.errors?.required || f.thrust1.touched">
                  Please enter the average thrust derate reported
                </mat-error>
              </mat-form-field>

              <mat-label class="col-sm-2">Average thrust derate reported for Engine P2</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput formControlName="thrust2" (change)="changeFormVal()">
                <mat-error *ngIf="submitted || f.thrust2.errors?.required || f.thrust2.touched">
                  Please enter the average thrust derate reported
                </mat-error>
              </mat-form-field>

              <mat-label class="col-sm-2">Present location of APU</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput formControlName="apuLocation" (change)="changeFormVal()">
                <mat-error *ngIf="submitted || f.apuLocation.errors?.required || f.apuLocation.touched">
                  Please enter the APU present location
                </mat-error>
              </mat-form-field>

              <mat-label class="col-sm-2">Engine P1 Next shop visit</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput [matDatepicker]="startDate" (click)="startDate.open()" formControlName="nextVisit1"
                  [min]="today" (dateChange)="changeFormVal()">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
                <mat-hint align="end">Click the Date picker icon</mat-hint>
                <mat-error *ngIf="submitted || f.nextVisit1.errors?.required || f.nextVisit1.touched">
                  Please select a date
                </mat-error>
              </mat-form-field>

              <mat-label class="col-sm-2">Engine P2 Next shop visit</mat-label>
              <mat-form-field class="col-sm-2" appearance="outline">
                <input matInput [matDatepicker]="endDate" (click)="endDate.open()" formControlName="nextVisit2"
                  [min]="today" (dateChange)="changeFormVal()">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
                <mat-hint align="end">Click the Date picker icon</mat-hint>
                <mat-error *ngIf="submitted || f.nextVisit2.errors?.required || f.nextVisit2.touched">
                  Please select a date
                </mat-error>
              </mat-form-field>

              <div class="form-actions col-sm-12" align="end">
                <button type="submit" class="save-btn" mat-button [disabled]="noChangeForm">
                  <mat-icon>save</mat-icon>Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <br><br>

</div>
