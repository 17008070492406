import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { APIConstants } from '../restapi';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class GetDetailService {

  constructor(
    private http: HttpClient,
  ) { }

  public getUserAccess(role): Observable<any> {
    return this.http.get(APIConstants.GETUSERACCESS + role);
  }

  public getAllOrgs(): Observable<any> {
    return this.http.get(APIConstants.GETALLORGS);
  }

  public getAllOrgsLessor(orgName): Observable<any> {
    return this.http.get(APIConstants.GETALLORGSLESSOR + orgName);
  }

  public getOrgDetails(orgId): Observable<any> {
    return this.http.get(APIConstants.GETORGDETAILS + orgId);
  }

  public getAllUsers(): Observable<any> {
    return this.http.get(APIConstants.GETALLUSERS);
  }

  public getAllUsersLessor(orgName): Observable<any> {
    return this.http.get(APIConstants.GETALLUSERSLESSOR + orgName);
  }

  public getUserDetails(userName): Observable<any> {
    return this.http.get(APIConstants.GETUSERDETAILS + userName);
  }

  public getAllModels(): Observable<any> {
    return this.http.get(APIConstants.GETALLMODELS);
  }

  public getAllModelsLessor(orgName): Observable<any> {
    return this.http.get(APIConstants.GETALLMODELSLESSOR + orgName);
  }

  public getModel(modelNumber): Observable<any> {
    return this.http.get(APIConstants.GETMODEL + modelNumber);
  }

  public getModelDetails(modelNumber): Observable<any> {
    return this.http.get(APIConstants.GETMODELDETAILS + modelNumber);
  }

  public getAllAssets(): Observable<any> {
    return this.http.get(APIConstants.GETALLASSETS);
  }

  public getAllAssetsLessor(orgName): Observable<any> {
    return this.http.get(APIConstants.GETALLASSETSLESSOR + orgName);
  }

  public getAllAssetsOperator(orgName): Observable<any> {
    return this.http.get(APIConstants.GETALLASSETSOPERATOR + orgName);
  }

  public getAsset(topAssetId): Observable<any> {
    return this.http.get(APIConstants.GETASSET + topAssetId);
  }

  public getAssetDetails(topAssetId): Observable<any> {
    return this.http.get(APIConstants.GETASSETDETAILS + topAssetId);
  }

  public getAssetDetailsUtil(topAssetId, startDt, endDt): Observable<any> {
    return this.http.get(APIConstants.GETASSETDETAILSUTIL + topAssetId + '/' + startDt + '/' + endDt);
  }

  public getAssetUtilRates(topAssetId, openDt, closeDt): Observable<any> {
    return this.http.get(APIConstants.GETASSETUTILRATES + topAssetId + '/' + openDt + '/' + closeDt);
  }

  public getAssetDetailsBill(topAssetId, openDt, closeDt): Observable<any> {
    return this.http.get(APIConstants.GETASSETDETAILSBILL + topAssetId + '/' + openDt + '/' + closeDt);
  }

  public getAllContracts(): Observable<any> {
    return this.http.get(APIConstants.GETALLCONTRACTS);
  }

  public getAllContractsLessor(orgName): Observable<any> {
    return this.http.get(APIConstants.GETALLCONTRACTSLESSOR + orgName);
  }

  public getContract(contNumb): Observable<any> {
    return this.http.get(APIConstants.GETCONTRACT + contNumb);
  }

  public getContractDetails(contNumb): Observable<any> {
    return this.http.get(APIConstants.GETCONTRACTDETAILS + contNumb);
  }

  public getContractMSN(lessor): Observable<any> {
    return this.http.get(APIConstants.GETCONTRACTMSN + lessor);
  }

  public getLessorUtilReport(assetType, orgName, startDt, endDt): Observable<any> {
    return this.http.get(APIConstants.GETLESSORUTILREPORT + assetType + '/' + orgName + '/' + startDt + '/' + endDt);
  }

  public getLesseeUtilReport(assetType, orgName, startDt, endDt): Observable<any> {
    return this.http.get(APIConstants.GETLESSEEUTILREPORT + assetType + '/' + orgName + '/' + startDt + '/' + endDt);
  }

  public getLessorBillReport(assetType, orgName, startDt, endDt): Observable<any> {
    return this.http.get(APIConstants.GETLESSORBILLREPORT + assetType + '/' + orgName + '/' + startDt + '/' + endDt);
  }

  public getLesseeBillReport(assetType, orgName, startDt, endDt): Observable<any> {
    return this.http.get(APIConstants.GETLESSEEBILLREPORT + assetType + '/' + orgName + '/' + startDt + '/' + endDt);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private dataSource = new BehaviorSubject<any>({ Lessor: "", Operator: "", ContNo: "" });
  currentData = this.dataSource.asObservable();

  private billSource = new BehaviorSubject<any>({ TotalAmnt: "" });
  currentBill = this.billSource.asObservable();

  constructor() {
  }

  changeData(data) {
    console.log("change data", data)
    this.dataSource.next(data)
  }

  billData(data) {
    console.log("bill data", data)
    this.billSource.next(data)
  }
}

