import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-view-utilization',
  templateUrl: './view-utilization.component.html',
  styleUrls: ['./view-utilization.component.scss']
})
export class ViewUtilizationComponent implements OnInit {

  @Input() assetUtilDetailsData: [];

  constructor() { }

  currentUser: any;

  assetDetailsData: Asset[] = []
  sortedRes: any = [];

  displayedDetailsColumns: any[] = ['position', 'type', 'partNumber', 'MSN', 'asset', 'parameter1', 'value1', 'parameter2', 'value2', 'parameter3', 'value3'];
  dataDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.getContent()
  }

  getContent() {
    this.assetDetailsData = this.assetUtilDetailsData
    this.sortedRes = []

    if (this.assetUtilDetailsData != []) {
      this.sortedRes = this.assetDetailsData.sort((a, b) => a.type.localeCompare(b.type))
    }

    this.dataDetailsSource = new MatTableDataSource(this.sortedRes);
    this.dataDetailsSource.paginator = this.paginator;

  }

}

export interface Asset {
  position: string;
  type: string;
  partNumb: string;
  msn: number;
  assetNumb: number;
  totalAmnt: number;
  utilRates?: any;
}
