import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-view-billing',
  templateUrl: './view-billing.component.html',
  styleUrls: ['./view-billing.component.scss']
})
export class ViewBillingComponent implements OnInit {

  @Input() assetBillDetailsData: [];

  constructor() { }

  currentUser: any;

  assetDetailsData: any = [];
  sortedRes: any = [];

  displayedDetailsColumns: any[] = ['position', 'type', 'partNumber', 'MSN', 'asset', 'totalAmt', 'parameter1', 'value1', 'rate1', 'parameter2', 'value2', 'rate2', 'parameter3', 'value3', 'rate3', 'parameter4', 'value4', 'rate4'];
  dataDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.getContent()
  }

  getContent() {
    this.assetDetailsData = this.assetBillDetailsData
    this.sortedRes = []

    if (this.assetBillDetailsData != []) {
      this.sortedRes = this.assetDetailsData.sort((a, b) => a.type.localeCompare(b.type))
    }

    this.dataDetailsSource = new MatTableDataSource(this.sortedRes);
    this.dataDetailsSource.paginator = this.paginator;
  }

}
