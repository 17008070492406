<div class="home-content">
  <h4><strong>KPI Reports</strong></h4>
  <mat-divider></mat-divider>
  <div class="row mt-4">
    <div class="aircraft-age-chart col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart" *ngIf="aircraftAgeChartData">
        <canvas baseChart [chartType]="'bar'" [datasets]="aircraftAgeChartData" [labels]="aircraftAgeChartLabels"
          [colors]="chartColors" [options]="aircraftAgeChartOptions" [plugins]="aircraftAgeChartPlugins"
          [legend]="true">
        </canvas>
      </div>
    </div>
    <div class="remaining-lease-chart col-sm-12 col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart" *ngIf="remngLeaseChartData">
        <canvas baseChart [chartType]="'bar'" [datasets]="remngLeaseChartData" [labels]="remngLeaseChartLabels"
          [colors]="chartColors" [options]="remngLeaseChartOptions" [plugins]="remngLeaseChartPlugins" [legend]="true">
        </canvas>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="asset-type-chart col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart pb-2" *ngIf="assetTypeChartData" style="height: auto;">
        <canvas baseChart [chartType]="'pie'" [datasets]="assetTypeChartData" [labels]="assetTypeChartLabels"
          [colors]="chartColors" [options]="assetTypeChartOptions" [plugins]="assetTypeChartPlugins" [legend]="true">
        </canvas>
      </div>
    </div>
    <div class="lease-rents-chart col-sm-12 col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart pb-2" *ngIf="leaseRentsChartData" style="height: auto;">
        <canvas baseChart [chartType]="'pie'" [datasets]="leaseRentsChartData" [labels]="leaseRentsChartLabels"
          [colors]="chartColors" [options]="leaseRentsChartOptions" [plugins]="leaseRentsChartPlugins" [legend]="true">
        </canvas>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="market-Type-chart col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart pb-2" *ngIf="marketTypeChartData" style="height: auto;">
        <canvas baseChart [chartType]="'pie'" [datasets]="marketTypeChartData" [labels]="marketTypeChartLabels"
          [colors]="pieChartColors" [options]="marketTypeChartOptions" [plugins]="marketTypeChartPlugins"
          [legend]="true">
        </canvas>
      </div>
    </div>
    <div class="region-chart col-sm-12 col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart pb-2" *ngIf="regionChartData" style="height: auto;">
        <canvas baseChart [chartType]="'pie'" [datasets]="regionChartData" [labels]="regionChartLabels"
          [colors]="pieChartColors" [options]="regionChartOptions" [plugins]="regionChartPlugins" [legend]="true">
        </canvas>
      </div>
    </div>
  </div>

</div>
