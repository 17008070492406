<div class="assetMngt">
  <div class="editInfo m-3" *ngIf="canEdit == true">
    <small>* Blue colored data are editable in the table, click on the data to edit </small>
  </div>

  <div class="assetMngt-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataDetailsSource">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Position </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.position }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Asset Type </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.type }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="partNumber">
        <th mat-header-cell *matHeaderCellDef> Part Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.partNumber }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="MSN">
        <th mat-header-cell *matHeaderCellDef> MSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="asset">
        <th mat-header-cell *matHeaderCellDef> Asset </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.asset }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="performedCheckType">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Check Type </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'performedCheckType')">
            <ng-template viewMode *ngIf="element?.performedCheckType== '-----'">
              -----
            </ng-template>
            <ng-template viewMode>
              {{element?.performedCheckType}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.performedCheckType" placeholder="Check Type" matInput
                  [formControl]="getDetailsControl(index, 'performedCheckType')" (selectionChange)="changeVal()"
                  editableOnEnter>
                  <mat-option *ngFor="let opt of checkTypeOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getDetailsControl(index, 'performedCheckType').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="performedCmpltDate">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Completion Date </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editableDate (update)="updateDetailsField(index, 'performedCmpltDate')">
            <ng-template viewMode *ngIf="element?.performedCmpltDate == '-----'">
              -----
            </ng-template>
            <ng-template viewMode *ngIf="lastCmpltDt[index] != '-----'">
              {{((lastCmpltDt[index])*1000) | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template viewMode *ngIf="element?.performedCmpltDate != '-----'">
              {{((element?.performedCmpltDate)*1000) | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="performedCmpltDate" (click)="performedCmpltDate.open()"
                  [(value)]="element.performedCmpltDate" [formControl]="getDetailsControl(index, 'performedCmpltDate')"
                  (dateChange)="changeVal();formatDate($event, index, 'lcmplt')" placeholder="Completion Date"
                  editableOnEnter>
                <mat-datepicker-toggle matSuffix [for]="performedCmpltDate"></mat-datepicker-toggle>
                <mat-datepicker #performedCmpltDate></mat-datepicker>
                <mat-hint align="end">Click the Date picker icon</mat-hint>
                <mat-error *ngIf="getDetailsControl(index, 'performedCmpltDate').hasError('required')">
                  Please provide a valid Completion Date
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editableDate>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="performedParam1">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.performedParam1 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="performedValue1">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Value </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'performedValue1')">
            <ng-template viewMode>
              {{element?.performedValue1}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'performedValue1')" (change)="changeVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'performedValue1').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="performedParam2">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.performedParam2 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="performedValue2">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Value </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'performedValue2')">
            <ng-template viewMode>
              {{element?.performedValue2}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'performedValue2')" (change)="changeVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'performedValue2').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="dueCheckType">
        <th mat-header-cell *matHeaderCellDef> Check Type </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'dueCheckType')">
            <ng-template viewMode *ngIf="element?.dueCheckType == '-----'">
              -----
            </ng-template>
            <ng-template viewMode>
              {{element?.dueCheckType}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.dueCheckType" placeholder="Check Type" matInput
                  [formControl]="getDetailsControl(index, 'dueCheckType')" (selectionChange)="changeVal()"
                  editableOnEnter>
                  <mat-option *ngFor="let opt of checkTypeOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getDetailsControl(index, 'dueCheckType').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="dueCmpltDate">
        <th mat-header-cell *matHeaderCellDef> Completion Date </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editableDate (update)="updateDetailsField(index, 'dueCmpltDate')">
            <ng-template viewMode *ngIf="element?.dueCmpltDate== '-----'">
              -----
            </ng-template>
            <ng-template viewMode *ngIf="nextCmpltDt[index] != '-----'">
              {{((nextCmpltDt[index])*1000) | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template viewMode *ngIf="element?.dueCmpltDate != '-----'">
              {{((element?.dueCmpltDate)*1000) | date:"MM/dd/yyyy"}}
            </ng-template>

            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="dueCmpltDate" (click)="dueCmpltDate.open()"
                  [(value)]="element.dueCmpltDate" [formControl]="getDetailsControl(index, 'dueCmpltDate')"
                  (dateChange)="changeVal();formatDate($event, index,'ncmplt')" placeholder="Completion Date"
                  editableOnEnter>
                <mat-datepicker-toggle matSuffix [for]="dueCmpltDate"></mat-datepicker-toggle>
                <mat-datepicker #dueCmpltDate></mat-datepicker>
                <mat-hint align="end">Click the Date picker icon</mat-hint>
                <mat-error *ngIf="getDetailsControl(index, 'dueCmpltDate').hasError('required')">
                  Please provide a valid Completion Date
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editableDate>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="dueParam1">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.dueParam1 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="dueValue1">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'dueValue1')">
            <ng-template viewMode>
              {{element?.dueValue1}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'dueValue1')" (change)="changeVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'dueValue1').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="dueParam2">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.dueParam2 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="dueValue2">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'dueValue2')">
            <ng-template viewMode>
              {{element?.dueValue2}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'dueValue2')" (change)="changeVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'dueValue2').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="header-row-first-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" class="header-row-first-group"></th>
      </ng-container>

      <ng-container matColumnDef="header-row-second-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="6" class="header-row-second-group"> Last Maintenance
          Performed </th>
      </ng-container>

      <ng-container matColumnDef="header-row-third-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="6" class="header-row-third-group"> Next Maintenance Due
        </th>
      </ng-container>

      <tr mat-header-row
        *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group']"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
    </table>

    <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
      <mat-paginator class="paginator" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>

  </div>

  <div *ngIf="sortedRes.length === 0">
    <mat-toolbar-row class="toolbar-row">
      <span class="noRecords">No records found!</span>
    </mat-toolbar-row>
  </div>

  <div class="table-actions col-sm-12 m-3">
    <div class="float-right">
      <button type="submit" [disabled]="canEdit == false" mat-button class="save-btn mr-2" (click)="onSubmitDetails()"
        [disabled]="noChangeYet">
        <mat-icon>save</mat-icon> Save
      </button>
    </div>
  </div>

</div>
