// AMB instance
import { environment } from 'src/environments/environment.prod';
// AMB instance
const endpoint = environment.baseUrl;

export const APIConstants: any = {
  'GETUSERACCESS': endpoint + 'getRoleBaseAccess/',

  'GETALLORGS': endpoint + 'getAllOrganization',
  'GETALLORGSLESSOR': endpoint + 'getAllOrganizationLessor/',
  'GETORGDETAILS': endpoint + 'getOrganization/',
  'CREATEORG': endpoint + 'createOrganization',
  'UPDATEORGDETAILS': endpoint + 'updateOrganization',

  'GETALLUSERS': endpoint + 'getAllUserDetails',
  'GETALLUSERSLESSOR': endpoint + 'getAllUserDetailsLessor/',
  'GETUSERDETAILS': endpoint + 'getUserDetails/',
  'CREATEUSER': endpoint + 'createUserDetails',
  'UPDATEUSERDETAILS': endpoint + 'updateUserDetails',

  'GETALLMODELS': endpoint + 'getAllModel',
  'GETALLMODELSLESSOR': endpoint + 'getAllModelsLessor/',
  'GETMODEL': endpoint + 'getModel/',
  'GETMODELDETAILS': endpoint + 'getModelDetails/',
  'CREATEMODEL': endpoint + 'createModel',
  'CREATEMODELDETAILS': endpoint + 'createModelDetails',
  'UPDATEMODEL': endpoint + 'updateModel/',
  'UPDATEMODELDETAILS': endpoint + 'updateModelDetails/',

  'GETALLASSETS': endpoint + 'getAllAsset',
  'GETALLASSETSLESSOR': endpoint + 'getAllAssetLessor/',
  'GETALLASSETSOPERATOR': endpoint + 'getAllAssetOperator/',
  'GETASSET': endpoint + 'getAsset/',
  'GETASSETDETAILS': endpoint + 'getAssetDetails/',
  'CREATEASSET': endpoint + 'createAsset',
  'CREATEASSETDETAILS': endpoint + 'createAssetDetails',
  'UPDATEASSET': endpoint + 'updateAsset/',
  'UPDATEASSETDETAILS': endpoint + 'updateAssetDetails/',
  'CREATEASSETUTIL': endpoint + 'createAssetUtilization/',
  'GETASSETDETAILSUTIL': endpoint + 'getAssetDetailsUtilization/',
  'GETASSETUTILRATES': endpoint + 'getUtilRates/',
  'CREATEASSETBILL': endpoint + 'createAssetBilling/',
  'GETASSETDETAILSBILL': endpoint + 'getAssetDetailsBilling/',

  'GETALLCONTRACTS': endpoint + 'getAllContract',
  'GETALLCONTRACTSLESSOR': endpoint + 'getAllContractLessor/',
  'GETCONTRACT': endpoint + 'getContract/',
  'GETCONTRACTDETAILS': endpoint + 'getContractDetails/',
  'GETCONTRACTMSN': endpoint + 'getMsn/',
  'CREATECONTRACT': endpoint + 'createContract',
  'CREATECONTRACTDETAILS': endpoint + 'createContractDetails',
  'UPDATECONTRACT': endpoint + 'updateContract/',
  'UPDATECONTRACTDETAILS': endpoint + 'updateContractDetails/',

  'GETLESSORUTILREPORT': endpoint + 'getLessorMntUtilReport/',
  'GETLESSEEUTILREPORT': endpoint + 'getLesseeMntUtilReport/',
  'GETLESSORBILLREPORT': endpoint + 'getLessorMntBillReport/',
  'GETLESSEEBILLREPORT': endpoint + 'getLesseeMntBillReport/',

};
