import { Injectable } from '@angular/core';
import { APIConstants } from '../restapi';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateDetails {

  constructor(
    private http: HttpClient
  ) { }

  public createUser(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.post(APIConstants.CREATEUSER, data, { headers }));

  }

  public createOrg(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.post(APIConstants.CREATEORG, data, { headers }));

  }

  public createModel(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.post(APIConstants.CREATEMODEL, data, { headers }));

  }

  public createModelDetails(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.post(APIConstants.CREATEMODELDETAILS, data, { headers }));

  }

  public createAsset(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.post(APIConstants.CREATEASSET, data, { headers }));

  }

  public createAssetDetails(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.post(APIConstants.CREATEASSETDETAILS, data, { headers }));

  }

  public createAssetUtil(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.post(APIConstants.CREATEASSETUTIL, data, { headers }));

  }

  public createAssetBill(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.post(APIConstants.CREATEASSETBILL, data, { headers }));

  }

  public createContract(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.post(APIConstants.CREATECONTRACT, data, { headers }));

  }

  public createContractDetails(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.post(APIConstants.CREATECONTRACTDETAILS, data, { headers }));

  }

}
