import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lra-home',
  templateUrl: './lra-home.component.html',
  styleUrls: ['./lra-home.component.scss']
})
export class LraHomeComponent implements OnInit {

  public pieChartColors: Array<any> = [
    {
      backgroundColor: [
        'rgb(255, 192, 0, 1)',
        'rgb(0, 96, 124, 1)',
        'rgb(207, 207, 207, 1)',
      ],
      borderWidth: 2,
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  public aircraftUtilChartData: Array<any> = [
    { data: [140, 360], label: 'Month' },
    { data: [145, 355], label: 'Yearly Average' }
  ];
  public aircraftUtilChartLabels: Array<any> = ['Cycles', 'Hours'];
  public aircraftUtilChartColors: Array<any> = [
    { backgroundColor: 'rgb(0, 96, 124)' },
    { backgroundColor: 'rgb(255, 192, 0)' },
  ]
  public aircraftUtilChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'Portfolio Average Aircraft Utilization',
      padding: 20
    },
    legend: {
      display: true,
      position: "bottom",
    },
    plugins: {
      labels: {
        render: function (args) {
          let max = 100.0; //Custom maximum value
          return (args.value * 100 / max) + '%';
        },
        precision: 2,
        showZero: true,
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          min: 0.0,
          max: 400.0,
          padding: 10
        },
        scaleLabel: {
          display: true,
          padding: 2
        }
      }],
      xAxes: [{
        barPercentage: 0.4,
        gridLines: {
          display: false
        }
      }]
    }
  };


  public avgMntncChartData: Array<any> = [
    { data: [134600, 129100], label: 'Amount' }
  ];
  public avgMntncChartLabels: Array<any> = ['Month', 'Yearly Average'];
  public avgMntncChartColors: Array<any> = [
    { backgroundColor: ['rgb(0, 96, 124)', 'rgb(255, 192, 0)'] }
  ]
  public avgMntncChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'Portfolio Average Maintenance Reserve Invoice',
      padding: 20
    },
    legend: {
      display: false
    },
    plugins: {
      labels: {
        render: function (args, index, values) {
          if (parseInt(args.value) >= 1000) {
            return '$' + args.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            return '$' + args.value;
          }
        },
        precision: 2,
        showZero: true,
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          min: 126000.0,
          max: 135000.0,
          callback: function (value, index, values) {
            if (parseInt(value) >= 1000) {
              return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
              return '$' + value;
            }
          },
          padding: 10
        },
        scaleLabel: {
          display: true,
          padding: 2
        }
      }],
      xAxes: [{
        barPercentage: 0.2,
        scaleLabel: {
          display: true,
          padding: 2
        },
        gridLines: {
          display: false
        }
      }]
    }
  };

  public invoiceStatusChartData: Array<any> = [
    { data: [75, 201, 126], label: 'Value' }
  ];
  public invoiceStatusChartLabels: Array<any> = ['Invoices Outstanding', 'Invoices Issued', 'Invoices Paid'];
  public invoiceStatusChartOptions: any = {
    responsive: true,
    title: {
      display: true,
      text: 'Portfolio Maintenance Reserve Invoice Status',
      padding: 20
    },
    legend: { position: "right" },
    plugins: {
      labels: {
        render: 'value',
        position: 'outside',
      }
    },
  };


  public reserveInvoiceChartData: Array<any> = [
    { data: [8438623, 27458623, 19020000], label: 'Amount' }
  ];
  public reserveInvoiceChartLabels: Array<any> = ['Invoices Outstanding', 'Invoices Issued', 'Invoices Paid'];
  public reserveInvoiceChartOptions: any = {
    responsive: true,
    title: {
      display: true,
      text: 'Portfolio Maintenance Reserve Invoices',
      padding: 20
    },
    legend: { position: "right" },
    plugins: {
      labels: {
        render: function (args, index, values) {
          if (parseInt(args.value) >= 1000) {
            return '$' + args.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          } else {
            return '$' + args.value;
          }
        },
        position: 'outside',
      }
    },
  };


  public reportStatusChartData: Array<any> = [
    { data: [18, 201], label: 'Value' }
  ];
  public reportStatusChartLabels: Array<any> = ['Utilization Reports Received', 'Utilization Reports Overdue'];
  public reportStatusChartOptions: any = {
    responsive: true,
    title: {
      display: true,
      text: 'Portfolio Utilization Reports Status',
      padding: 20
    },
    legend: { position: "right" },
    plugins: {
      labels: {
        render: 'value',
        position: 'outside',
      }
    },
  };

}
