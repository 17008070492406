<div class="assetMngt">
  <div class="viewLabel mt-4 pl-2">
    * Displaying Billing details for selected date range
  </div>
  <div class="assetMngt-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataDetailsSource">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Position </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.position }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Asset Type </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.type }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="partNumber">
        <th mat-header-cell *matHeaderCellDef> Part Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.partNumber }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="MSN">
        <th mat-header-cell *matHeaderCellDef> MSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="asset">
        <th mat-header-cell *matHeaderCellDef> Asset </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.asset }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="totalAmt">
        <th mat-header-cell *matHeaderCellDef> Total </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.totalAmt | currency: 'USD' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="parameter1">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.parameter1 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="value1">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.value1 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="rate1">
        <th mat-header-cell *matHeaderCellDef> Rate </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.rate1 | currency: 'USD' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="parameter2">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.parameter2 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="value2">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.value2 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="rate2">
        <th mat-header-cell *matHeaderCellDef> Rate </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.rate2 | currency: 'USD' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="parameter3">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.parameter3 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="value3">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.value3 }} </td>
       <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="rate3">
        <th mat-header-cell *matHeaderCellDef> Rate </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.rate3 | currency: 'USD' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="parameter4">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.parameter4 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="value4">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.value4 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="rate4">
        <th mat-header-cell *matHeaderCellDef> Rate </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.rate4 | currency: 'USD' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
    </table>

    <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
      <mat-paginator class="paginator" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>

  </div>

  <div *ngIf="sortedRes.length === 0">
    <mat-toolbar-row class="toolbar-row">
      <span class="noRecords">No records found!</span>
    </mat-toolbar-row>
  </div>

</div>
