import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { MatTable } from '@angular/material/table'
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataService, GetDetailService } from 'src/app/services/getDetails.service';
import { UpdateDetails } from 'src/app/services/updateDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-asset-mngt-details',
  templateUrl: './asset-mngt-details.component.html',
  styleUrls: ['./asset-mngt-details.component.scss']
})
export class AssetMngtDetailsComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private alert: MatSnackBar,
    private gd: GetDetailService,
    private ud: UpdateDetails,
    private snotService: SnotifyService,
    private ds: DataService,) { }

  currentUser: any;
  id: any;
  isLessee: boolean = false;
  activeTab: number = 0;
  noChangeConf: boolean = true;
  noChangeUtil: boolean = true;
  noChangeBill: boolean = true;
  noChangeForm: boolean = true;

  today = new Date();

  pickListValues = new PickList();
  assetStatusOpts = this.pickListValues.assetStatus
  billStatusOpts = this.pickListValues.billStatus

  lessor: any;
  operator: any;
  contractNumb: any;
  MSN: any;
  allTotalAmnt: number = 0;
  contNoOpts: any = [];

  startDt: any = '';
  endDt: any = '';
  openDt: any = '';
  closeDt: any = '';

  assetConfData: any = [];
  UpdatedassetConfData: FormArray;
  displayedConfColumns: any[] = ['asset', 'modelNumber', 'MSN', 'age', 'lessor', 'operator', 'registration', 'contract', 'status', 'action'];
  confDataSource: MatTableDataSource<any>;

  assetUtilData: any = [];
  UpdatedassetUtilData: FormArray;
  displayedUtilColumns: any[] = ['asset', 'modelNumber', 'MSN', 'mnftYear', 'lessor', 'operator', 'registration', 'contract', 'startDate', 'endDate', 'utilStatus', 'action'];
  utilDataSource: MatTableDataSource<any>;
  assetDetailsUtil: any = [];
  assetUtilDetailsData: any = [];

  assetMntncData: any = [];
  UpdatedassetMntncData: FormArray;
  displayedMntncColumns: any[] = ['asset', 'modelNumber', 'MSN', 'mnftYear', 'lessor', 'operator', 'registration'];
  mntncDataSource: MatTableDataSource<any>;

  assetBillData: any = [];
  UpdatedassetBillData: FormArray;
  displayedBillColumns: any[] = ['asset', 'modelNumber', 'MSN', 'mnftYear', 'lessor', 'operator', 'registration', 'contract', 'openDate', 'closeDate', 'totalAmnt', 'wireRef', 'billStatus', 'revNumb', 'action'];
  billDataSource: MatTableDataSource<any>;
  assetDetailsBill: any = [];
  assetBillDetailsData: any = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.id = this.route.snapshot.params.id

    this.addInfoForm = this.fb.group({
      registration: [null, Validators.required],
      currentMSN1: [null, Validators.required],
      currentMSN2: [null, Validators.required],
      thrust1: [null, Validators.required],
      engLocation1: [null, Validators.required],
      nextVisit1: [null, Validators.required],
      engLocation2: [null, Validators.required],
      apuLocation: [null, Validators.required],
      apuMSN: [null, Validators.required],
      thrust2: [null, Validators.required],
      nextVisit2: [null, Validators.required],
    })

    this.getContent();
  }

  getContent() {

    this.ds.currentBill.subscribe(res => {
      console.log("current bill data  :", res);
      this.allTotalAmnt = res.TotalAmnt
    });

    this.gd.getAllContracts()
      .subscribe(res => {
        console.log("all contracts :", res);
        this.contNoOpts = []
        res.forEach(el => {
          if (this.currentUser.role == 'Admin') {
            this.contNoOpts.push({ viewValue: el.ContNo, StartDt: el.StartDt, EndDt: el.EndDt })
          }
          if ((this.currentUser.role == 'Lessor Manager' || this.currentUser.role == 'Lessor Accountant') && this.currentUser.userName == el.CreateBy) {
            this.contNoOpts.push({ viewValue: el.ContNo, StartDt: el.StartDt, EndDt: el.EndDt })
          }
        })
      });

    this.gd.getAsset(this.id)
      .subscribe(res => {
        console.log("top asset details  :", res);
        this.assetConfData = []
        this.assetUtilData = []
        this.assetMntncData = []
        this.assetBillData = []

        res.forEach(el => {
          this.lessor = el.LessorID
          this.operator = el.Operator
          this.contractNumb = el.ContNo
          this.MSN = el.MSN
          this.assetConfData.push({ asset: el.TAssetID, modelNumber: el.ModelID, MSN: el.MSN, age: el.Age, lessor: el.LessorID, operator: el.Operator, registration: el.RegistNo, contract: el.ContNo, status: el.Status })
          this.assetUtilData.push({ asset: el.TAssetID, modelNumber: el.ModelID, MSN: el.MSN, mnftYear: el.Age, lessor: el.LessorID, operator: el.Operator, registration: el.RegistNo, contract: el.ContNo, startDate: '', endDate: '', billStatus: el.BillStatus })
          this.assetMntncData.push({ asset: el.TAssetID, modelNumber: el.ModelID, MSN: el.MSN, mnftYear: el.Age, lessor: el.LessorID, operator: el.Operator, registration: el.RegistNo })
          this.assetBillData.push({ asset: el.TAssetID, modelNumber: el.ModelID, MSN: el.MSN, mnftYear: el.Age, lessor: el.LessorID, operator: el.Operator, registration: el.RegistNo, contract: el.ContNo, openDate: '', closeDate: '', totalAmnt: el.TotalAmt, wireRef: '', billStatus: el.BillStatus, revNumb: '0' })
          if (el.BillStatus == '' || el.BillStatus == undefined) { this.assetUtilData[0].utilStatus = 'Draft', this.assetBillData[0].billStatus = 'Draft' }

          this.ds.changeData({ "Lessor": this.lessor, "Operator": this.operator, "ContNo": this.contractNumb })

        });

        this.confDataSource = new MatTableDataSource(this.assetConfData);
        const confMngtArray = this.assetConfData.map(data => {
          return new FormGroup({
            registration: new FormControl(data.registration, Validators.required),
            contract: new FormControl(data.contract, Validators.required),
            status: new FormControl(data.status, Validators.required),
          }, { updateOn: "blur" });
        });
        this.UpdatedassetConfData = new FormArray(confMngtArray);

        this.utilDataSource = new MatTableDataSource(this.assetUtilData);
        const utilMngtArray = this.assetUtilData.map(data => {
          return new FormGroup({
            registration: new FormControl(data.registration, Validators.required),
            startDate: new FormControl(data.startDate, Validators.required),
            endDate: new FormControl(data.endDate, Validators.required),
            utilStatus: new FormControl(data.utilStatus, Validators.required),
          }, { updateOn: "blur" });
        });
        this.UpdatedassetUtilData = new FormArray(utilMngtArray);

        this.mntncDataSource = new MatTableDataSource(this.assetMntncData);

        this.billDataSource = new MatTableDataSource(this.assetBillData);
        const billMngtArray = this.assetBillData.map(data => {
          return new FormGroup({
            openDate: new FormControl(data.openDate, Validators.required),
            closeDate: new FormControl(data.closeDate, Validators.required),
            wireRef: new FormControl(data.wireRef, Validators.required),
            billStatus: new FormControl(data.billStatus, Validators.required),
          }, { updateOn: "blur" });
        });
        this.UpdatedassetBillData = new FormArray(billMngtArray);
      });

  }

  changeConfVal() { // triggers on change of any field(s)
    this.noChangeConf = false;
  }


  changeUtilVal() { // triggers on change of any field(s)
    this.noChangeUtil = false;
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("-");
  }

  onStartDtChange(event: any) {
    this.startDt = this.convert(event.target.value)
    if ((this.startDt != '' && this.endDt != '')) {
      this.assetDetailsUtil = []
      this.getUtilDetails(this.id, this.startDt, this.endDt)
    }
  }

  onEndDtChange(event: any) {
    this.endDt = this.convert(event.target.value)
    if ((this.startDt != '' && this.endDt != '')) {
      this.assetDetailsUtil = []
      this.getUtilDetails(this.id, this.startDt, this.endDt)
    }
  }

  changeBillVal() { // triggers on change of any field(s)
    this.noChangeBill = false;
  }

  onOpenDtChange(event: any) {
    this.openDt = this.convert(event.target.value)
    if ((this.openDt != '' && this.closeDt != '')) {
      this.assetDetailsBill = []
      this.getBillDetails(this.id, this.openDt, this.closeDt)
    }
  }

  onCloseDtChange(event: any) {
    this.closeDt = this.convert(event.target.value)
    if ((this.openDt != '' && this.closeDt != '')) {
      this.assetDetailsBill = []
      this.getBillDetails(this.id, this.openDt, this.closeDt)
    }
  }

  changeFormVal() { // triggers on change of any field(s)
    this.noChangeForm = false;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.activeTab = tabChangeEvent.index

    if ((this.startDt != '' && this.endDt != '') && this.activeTab == 1) {
      this.assetDetailsUtil = []
      this.getUtilDetails(this.id, this.startDt, this.endDt)
    }
  }

  updateConfField(index, field) {
    const control = this.getConfControl(index, field);
    if (control.valid) {
      this.assetConfData = this.assetConfData.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value
          }
        }
        return e;
      });
      this.onChangeConfDataSource(this.assetConfData);
    }
  }
  getConfControl(index, fieldName) {
    return this.UpdatedassetConfData.at(index).get(fieldName) as FormControl;
  }
  onChangeConfDataSource(updatedValue) {
    this.confDataSource = new MatTableDataSource(updatedValue);
    this.confDataSource.paginator = this.paginator;
  }

  updateUtilField(index, field) {
    const control = this.getUtilControl(index, field);
    if (control.valid) {
      this.assetUtilData = this.assetUtilData.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value
          }
        }
        return e;
      });
      this.onChangeUtilDataSource(this.assetUtilData);
    }
  }
  getUtilControl(index, fieldName) {
    return this.UpdatedassetUtilData.at(index).get(fieldName) as FormControl;
  }
  onChangeUtilDataSource(updatedValue) {
    this.utilDataSource = new MatTableDataSource(updatedValue);
  }

  updateBillField(index, field) {
    const control = this.getBillControl(index, field);
    if (control.valid) {
      this.assetBillData = this.assetBillData.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value
          }
        }
        return e;
      });
      this.onChangeBillDataSource(this.assetBillData);
    }
  }
  getBillControl(index, fieldName) {
    return this.UpdatedassetBillData.at(index).get(fieldName) as FormControl;
  }
  onChangeBillDataSource(updatedValue) {
    this.billDataSource = new MatTableDataSource(updatedValue);
  }

  onSubmitConf() {
    console.log("Updated Data on table: ", this.assetConfData, this.UpdatedassetConfData);

    let contStartDt = ''
    let contEndDt = ''
    this.contNoOpts.forEach(el => {
      if (this.UpdatedassetConfData.value[0].contract == el.viewValue) {
        contStartDt = el.StartDt
        contEndDt = el.EndDt
      }
    });

    var data = {
      "TAssetID": this.assetConfData[0].asset,
      "RegistNo": this.UpdatedassetConfData.value[0].registration,
      "ContNo": this.UpdatedassetConfData.value[0].contract,
      "StartDt": contStartDt,
      "EndDt": contEndDt,
      "Status": this.UpdatedassetConfData.value[0].status,
      "UpdatedBy": this.currentUser.userName
    }
    console.log("new user details data: ", data);

    this.updateAsset(data, 'conf');
  }

  onSubmitUtil() {
    console.log("Updated Data on table: ", this.assetUtilData, this.UpdatedassetUtilData);

    if ((this.startDt == '' && this.endDt == '') || this.startDt == '' || this.endDt == '') {
      return this.alert.open("Please select Start and End dates", "OK", { duration: 4000, verticalPosition: 'top' });
    }

    var data = {
      "TAssetID": this.assetUtilData[0].asset,
      "RegistNo": this.UpdatedassetUtilData.value[0].registration,
      "UpdatedBy": this.currentUser.userName
    }
    console.log("new user details data: ", data);

    this.updateAsset(data, 'util');
  }

  onSubmitBill() {
    console.log("Updated Data on table: ", this.assetBillData, this.UpdatedassetBillData);

    if ((this.openDt == '' && this.closeDt == '') || this.openDt == '' || this.closeDt == '') {
      return this.alert.open("Please select Open and Close dates", "OK", { duration: 4000, verticalPosition: 'top' });
    }

    var data = {
      "TAssetID": this.assetBillData[0].asset,
      "TotalAmt": JSON.stringify(this.allTotalAmnt),
      "WireRef": this.UpdatedassetBillData.value[0].wireRef,
      "BillStatus": this.UpdatedassetBillData.value[0].billStatus,
      "UpdatedBy": this.currentUser.userName
    }
    console.log("new user details data: ", data);

    this.updateAsset(data, 'bill');
  }

  submitted: boolean = false;

  addInfoForm: FormGroup;

  get f() { return this.addInfoForm.controls; }

  onSubmitAddInfo() {
    this.submitted = true;
    if (this.addInfoForm.invalid) {
      console.log("Invalid Form: ", this.addInfoForm);
      return;
    }
    console.log("Valid Form: ", this.addInfoForm);

    var data = {
      "TAssetID": this.id,
      "Val1": this.addInfoForm.value.registration,
      "Val2": this.addInfoForm.value.currentMSN1,
      "Val3": this.addInfoForm.value.currentMSN2,
      "Val4": this.addInfoForm.value.thrust1,
      "Val5": this.addInfoForm.value.engLocation1,
      "Val6": this.addInfoForm.value.nextVisit1,
      "Val7": this.addInfoForm.value.engLocation2,
      "Val8": this.addInfoForm.value.apuLocation,
      "Val9": this.addInfoForm.value.apuMSN,
      "Val10": this.addInfoForm.value.thrust2,
      "Val11": this.addInfoForm.value.nextVisit2,
      "UpdatedBy": this.currentUser.userName
    }
    console.log("add form details data: ", data);

    this.updateAsset(data, 'addInfo');
  }

  updateAsset(data, value) {
    this.ud.updateAsset(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          if (value == 'conf') {
            this.noChangeConf = true;
          } else if (value == 'util') {
            this.noChangeUtil = true;
          } else if (value == 'bill') {
            this.noChangeBill = true;
          } else if (value == 'addInfo') {
            this.noChangeForm = true;
          }
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Details of MSN: " + this.MSN + " updated successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Details of MSN: " + this.MSN + " updation failed!", snConfig);
        }
      });
  }

  getUtilDetails(TAssetID, StartDt, EndDt) {
    this.gd.getAssetDetailsUtil(TAssetID, StartDt, EndDt)
      .subscribe(res => {
        console.log("all asset details util :", res);
        this.assetDetailsUtil = []
        this.assetDetailsUtil = res
        this.assetUtilDetailsData = []

        if (this.assetDetailsUtil != []) {
          this.assetDetailsUtil.forEach(el => {
            this.assetUtilDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, MSN: el.MSN, asset: el.AssetID, value1: el.Value1, parameter1: el.Param1, value2: el.Value2, parameter2: el.Param2, value3: el.Value3, parameter3: el.Param3 })
          });
        }
      });
  }

  getBillDetails(TAssetID, StartDt, EndDt) {
    if (this.currentUser.role == 'Lessee Manager' || this.currentUser.role == 'Lessee Accountant') {
      this.isLessee = true
    }
    this.gd.getAssetDetailsUtil(TAssetID, StartDt, EndDt)
      .subscribe(utilRes => {
        console.log("all asset details util inside bill:", utilRes)
        this.assetDetailsUtil = utilRes

        if (utilRes.length > 0) {
          this.gd.getAssetDetailsBill(TAssetID, StartDt, EndDt)
            .subscribe(res => {
              console.log("all asset details bill :", res);
              this.assetDetailsBill = []
              this.assetDetailsBill = res
              this.assetBillDetailsData = []
              this.allTotalAmnt = 0

              if (this.assetDetailsBill != []) {
                this.assetDetailsBill.forEach(el => {
                  if(el.Total == 'null') {
                    el.Total = 0
                  }
                  this.allTotalAmnt = this.allTotalAmnt + Number(el.Total)

                  this.assetBillDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, MSN: el.MSN, asset: el.AssetID, totalAmt: el.Total, parameter1: el.Param1, value1: el.Value1, rate1: el.Rate1, parameter2: el.Param2, value2: el.Value2, rate2: el.Rate2, parameter3: el.Param3, value3: el.Value3, rate3: el.Rate3, parameter4: el.Param4, value4: el.Value4, rate4: el.Rate4 })

                });
              }
            });
        } else {
          this.assetDetailsUtil = []
          this.allTotalAmnt = 0
          return this.alert.open("No Utilization Details for selected time period", "OK", { duration: 4000, verticalPosition: 'top' });
        }
      });
  }

}

