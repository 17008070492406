import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FormControl, FormArray } from '@angular/forms';
import { TableFilterService } from 'src/app/services/table-filter.service';
import { GetDetailService } from 'src/app/services/getDetails.service';

@Component({
  selector: 'app-org-master',
  templateUrl: './org-master.component.html',
  styleUrls: ['./org-master.component.scss']
})
export class OrgMasterComponent implements OnInit {

  currentUser: any;
  col1Clicked: boolean = false;
  col2Clicked: boolean = false;

  sortedRes: any = [];

  orgMasterData: any = [];
  UpdatedorgMasterData: FormArray;
  displayedColumns: any[] = ['orgName', 'status'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  orgIdFilter = new FormControl("");
  orgNameFilter = new FormControl("");
  statusFilter = new FormControl("");

  constructor(
    private tableFilter: TableFilterService,
    private router: Router,
    private gd: GetDetailService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.getContent();

    this.orgNameFilter.valueChanges.subscribe(orgName => {
      this.tableFilter.filterValues.OrgName = orgName;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.statusFilter.valueChanges.subscribe(status => {
      this.tableFilter.filterValues.Status = status;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });

  }

  getContent() {

    if (this.currentUser.role == "Admin") {
      this.gd.getAllOrgs()
        .subscribe(res => {
          console.log("all orgs :", res);
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }
          this.dataSource = new MatTableDataSource(this.sortedRes);
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = this.tableFilter.createOrgFilter();

        });
    } else if (this.currentUser.role == "Lessor Manager" || this.currentUser.role == "Lessor Accountant") {
      this.gd.getAllOrgsLessor(this.currentUser.orgName)
        .subscribe(res => {
          console.log("all lessor orgs :", res);
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }
          this.dataSource = new MatTableDataSource(this.sortedRes);
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = this.tableFilter.createOrgFilter();

        });
    }

  }

  clear(dataFilter) {
    if (dataFilter == 'orgNameFilter') {
      this.orgNameFilter.setValue('')
    } else if (dataFilter == 'statusFilter') {
      this.statusFilter.setValue('')
    }
  }

  onRedirectToOrgDetails(orgId, row) {
    this.router.navigateByUrl('/org-master/' + orgId)
  }

  createOrg(val1, val2) {
    this.router.navigateByUrl('/org-master/' + val1 + '/' + val2)
  }

}
