import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PickList } from 'src/app/models/pick-list'
import { GetDetailService } from 'src/app/services/getDetails.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  currentUser: any;

  pickListValues = new PickList();
  reportOpts: any = [];

  showLessorUtil: boolean = false;
  showLesseeUtil: boolean = false;
  showLessorMntnc: boolean = false;
  showLesseeMntnc: boolean = false;
  showPaginator: boolean = false;

  lessorUtilSortedRes: any = [];
  lesseeUtilSortedRes: any = [];
  lessorMntncSortedRes: any = [];
  lesseeMntncSortedRes: any = [];

  reportData: any = [];
  fileName: any = '';

  searchData: any = [];
  displayedColumns: any[] = ['startDate', 'endDate', 'report', 'action'];
  searchDataSource: MatTableDataSource<any>;

  displayedLessorUtilColumns: any[] = ['asset', 'MSN', 'crntUtilCycles', 'avgUtilCycles', 'varCycles', 'TCSN', 'totalLTMUtilCycles', 'avgLTMUtilCycles', 'varLTMCycles', 'crntUtilHours', 'avgUtilHours', 'varHours', 'THSN', 'totalLTMUtilHours', 'avgLTMUtilHours', 'varLTMHours'];
  lessorUtilReportDataSource: MatTableDataSource<any>;

  displayedLesseeUtilColumns: any[] = ['asset', 'MSN', 'currentFC', 'ltmFC', 'avgLtmFC', 'varFC', 'currentFH', 'ltmFH', 'avgLtmFH', 'varFH'];
  lesseeUtilReportDataSource: MatTableDataSource<any>;

  displayedLessorMntncColumns: any[] = ['asset', 'MSN', 'crntMRBill', 'avgMRBill', 'varMRBill', 'ltmMRBill', 'avgLtmMRBill', 'varLtmMRBill', 'acMRBalance', 'avgMRBalance', 'varBalance'];
  lessorMntncReportDataSource: MatTableDataSource<any>;

  displayedLesseeMntncColumns: any[] = ['asset', 'MSN', 'currentMRBill', 'ltmAvgMRBill', 'var', 'acMRBalance'];
  lesseeMntncReportDataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private alert: MatSnackBar,
    private gd: GetDetailService,
    private es: ExcelService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    if (this.currentUser.role == "Admin") {
      this.reportOpts = this.pickListValues.reports
    } else if (this.currentUser.role == "Lessor Manager" || this.currentUser.role == "Lessor Accountant") {
      this.reportOpts = this.pickListValues.reportsForLessor
    } else if (this.currentUser.role == "Lessee Manager" || this.currentUser.role == "Lessee Accountant") {
      this.reportOpts = this.pickListValues.reportsForLessee
    }

    this.searchData = [
      { startDate: '', endDate: '', report: '' },
    ]
    this.searchDataSource = new MatTableDataSource(this.searchData);
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("-");
  }

  onGenerate() {

    console.log("reports start n end dates", this.searchData[0].startDate, this.searchData[0].endDate)

    if ((this.searchData[0].startDate == '' && this.searchData[0].endDate == '' && this.searchData[0].report == '') || this.searchData[0].startDate == '' || this.searchData[0].endDate == '' || this.searchData[0].report == '') {
      return this.alert.open("Please select all the details", "OK", { duration: 4000, verticalPosition: 'top' });
    }

    this.showLessorUtil = false
    this.showLesseeUtil = false
    this.showLessorMntnc = false
    this.showLesseeMntnc = false
    this.showPaginator = false

    let orgName = ''
    let startDt = ''
    let endDt = ''

    if (this.currentUser.role == "Admin") {
      orgName = 'admin'
    } else {
      orgName = this.currentUser.orgName
    }

    startDt = this.convert(this.searchData[0].startDate)
    endDt = this.convert(this.searchData[0].endDate)

    console.log("get report values", orgName, startDt, endDt)

    if (this.searchData[0].report == 'Lessor Monthly Utilization') {
      this.getLrUtilReport('Airframe', orgName, startDt, endDt)
    } else if (this.searchData[0].report == 'Lessee Monthly Utilization') {
      this.getLeUtilReport('Airframe', orgName, startDt, endDt)
    } else if (this.searchData[0].report == 'Lessor Monthly Maintenance') {
      this.getLrBillReport('Airframe', orgName, startDt, endDt)
    } else if (this.searchData[0].report == 'Lessee Monthly Maintenance') {
      this.getLeBillReport('Airframe', orgName, startDt, endDt)
    }

  }

  getLrUtilReport(type, orgName, startDt, endDt) {
    this.gd.getLessorUtilReport(type, orgName, startDt, endDt)
      .subscribe(res => {
        console.log("getLessorUtilReport :", res);
        this.showLessorUtil = true
        if (res.length > 0) { this.showPaginator = true }

        this.lessorUtilSortedRes = []
        this.reportData = []
        if (res != []) {
          this.lessorUtilSortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
        }
        this.lessorUtilReportDataSource = new MatTableDataSource(this.lessorUtilSortedRes);
        this.lessorUtilReportDataSource.paginator = this.paginator;

        this.lessorUtilSortedRes.forEach(el => {
          this.reportData.push({ "Asset ID": (el.AssetID), "MSN": (el.MSN), "Lessor Portfolio Current Utilization n Cycles": (el.LessorCurrentUtilCycles), "Lessor Portfolio Avg Utilization n Cycles": (el.LessorAvgUtilCycles), "Var %": (el.VarCycles), "Lessor TCSN": (el.LessorTCSN), "Lessor Portfolio Total LTM Utilization n Cycles": (el.LessorTotalLTMUtilCycles), "Lessor Portfolio Avg LTM Utilization n Cycles": (el.LessorAvgLTMUtilCycles), "Var%": (el.VarLTMCycles), "Lessor Current Utilization n Hours": (el.LessorCurrentHours), "Lessor Portfolio Avg Utilization n Hours": (el.LessorAvgUtilHours), "Var (%)": (el.VarHours), "Lessor THSN": (el.LessorTHSN), "Lessor Portfolio Total LTM Utilization n Hours": (el.LessorTotalLTMUtilHours), "Lessor Portfolio Avg LTM Utilization n Hours": (el.LessorAvgLTMUtilHours), "Var(%)": (el.VarLTMHours) })
        });
        this.fileName = 'Lessor Utilization Report'

      });
  }

  getLeUtilReport(type, orgName, startDt, endDt) {
    this.gd.getLesseeUtilReport(type, orgName, startDt, endDt)
      .subscribe(res => {
        console.log("getLesseeUtilReport :", res);
        this.showLesseeUtil = true
        if (res.length > 0) { this.showPaginator = true }

        this.lesseeUtilSortedRes = []
        this.reportData = []
        if (res != []) {
          this.lesseeUtilSortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
        }
        this.lesseeUtilReportDataSource = new MatTableDataSource(this.lesseeUtilSortedRes);
        this.lesseeUtilReportDataSource.paginator = this.paginator;

        this.lesseeUtilSortedRes.forEach(el => {
          this.reportData.push({ "Asset ID": (el.AssetID), "MSN": (el.MSN), "Lessee Current FC": (el.LesseeCurrentFC), "Lessee LTM FC": (el.LesseeLtmFC), "Lessee Avg LTM FC": (el.LesseeAvgLtmFC), "Var %": (el.VarFC), "Lessee Current FH": (el.LesseeCurrentFH), "Lessee LTM FH": (el.LesseeLtmFH), "Lessee Avg LTM FH": (el.LesseeAvgLtmFH), "Var%": (el.VarFH) })
        });
        this.fileName = 'Lessee Utilization Report'

      });
  }

  getLrBillReport(type, orgName, startDt, endDt) {
    this.gd.getLessorBillReport(type, orgName, startDt, endDt)
      .subscribe(res => {
        console.log("getLessorMntncReport :", res);
        this.showLessorMntnc = true
        if (res.length > 0) { this.showPaginator = true }

        this.lessorMntncSortedRes = []
        this.reportData = []
        if (res != []) {
          this.lessorMntncSortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
        }
        this.lessorMntncReportDataSource = new MatTableDataSource(this.lessorMntncSortedRes);
        this.lessorMntncReportDataSource.paginator = this.paginator;

        this.lessorMntncSortedRes.forEach(el => {
          this.reportData.push({ "Asset ID": (el.AssetID), "MSN": (el.MSN), "Lessor Current MR Bill": (el.LessorCurrentMRBill), "Lessor Port Avg MR Bill": (el.LessorAvgMrBill), "Var %": (el.VarMRBill), "Lessor LTM MR Bill": (el.LessorLtmMRBill), "Lessor Port Avg LTM MR Bill": (el.LessorAvgLtmMrBill), "Var%": (el.VarLtmMRBill), "A/C MR Balance": (el.AcMrBal), "Lessor Port Avg MR Balance": (el.LessorAvgMrBal), "Var (%)": (el.VarMRBal) })

        });
        this.fileName = 'Lessor Maintenance Report'

      });
  }

  getLeBillReport(type, orgName, startDt, endDt) {
    this.gd.getLesseeBillReport(type, orgName, startDt, endDt)
      .subscribe(res => {
        console.log("getLesseeMntncReport :", res);
        this.showLesseeMntnc = true
        if (res.length > 0) { this.showPaginator = true }

        this.lesseeMntncSortedRes = []
        this.reportData = []
        if (res != []) {
          this.lesseeMntncSortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
        }
        this.lesseeMntncReportDataSource = new MatTableDataSource(this.lesseeMntncSortedRes);
        this.lesseeMntncReportDataSource.paginator = this.paginator;

        this.lesseeMntncSortedRes.forEach(el => {
          this.reportData.push({ "Asset ID": (el.AssetID), "MSN": (el.MSN), "Lessee Current MR Bill": (el.LesseeCurrentMRBill), "Lessee LTM Avg MR Bill": (el.LesseeAvgMrBill), "Var %": (el.VarMRBill), "A/C MR Balance": (el.AcMrBal) })
        });
        this.fileName = 'Lessee Maintenance Report'

      });
  }

  onExport(data, fileName) {
    console.log("inside export function", data)
    if (fileName == 'Lessor Utilization Report') {
      this.es.exportAsExcelFile(data, fileName)
    } else if (fileName == 'Lessee Utilization Report') {
      this.es.exportAsExcelFile(data, fileName)
    } else if (fileName == 'Lessor Maintenance Report') {
      this.es.exportAsExcelFile(data, fileName)
    } else if (fileName == 'Lessee Maintenance Report') {
      this.es.exportAsExcelFile(data, fileName)
    }

  }

}
