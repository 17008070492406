import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { TableEditService } from 'src/app/services/table-edit.service';
import { MatTable } from '@angular/material/table'
import { GetDetailService } from 'src/app/services/getDetails.service';
import { UpdateDetails } from 'src/app/services/updateDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-conf-mngt-details',
  templateUrl: './conf-mngt-details.component.html',
  styleUrls: ['./conf-mngt-details.component.scss']
})
export class ConfMngtDetailsComponent implements OnInit {

  constructor(
    private tableEdit: TableEditService,
    private route: ActivatedRoute,
    private gd: GetDetailService,
    private ud: UpdateDetails,
    private snotService: SnotifyService) { }

  currentUser: any;
  id: any;
  noChangeYet: boolean = true;
  noDetailsChangeYet: boolean = true;

  pickListValues = new PickList();
  recordStatus = this.pickListValues.recStatus
  positionOpts = this.pickListValues.position
  assetTypeOpts = this.pickListValues.type
  lifeParamsOpts = this.pickListValues.lifeParams
  utilParamsOpts = this.pickListValues.utilParams

  sortedRes: any = [];

  confMngtData: any = [];
  UpdatedconfMngtData: FormArray;
  displayedColumns: any[] = ['manufacturer', 'modelNumber', 'type', 'status', 'lastUpdated', 'action'];
  dataSource: MatTableDataSource<any>;

  confDetailsData: any = [];
  specId: any = [];
  UpdatedconfDetailsData: FormArray;
  displayedDetailsColumns: any[] = ['position', 'type', 'partNumber'];
  dataDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.id = this.route.snapshot.params.id

    this.content()
    this.contentDetails()
  }

  content() {

    this.gd.getModel(this.id)
      .subscribe(res => {
        console.log("model details  :", res);
        this.confMngtData = []
        res.forEach(el => {
          this.confMngtData.push({ manufacturer: el.Mfg, modelNumber: el.ModelID, type: el.Type, status: el.Status, lastUpdated: el.UpdateTS })
        });

        this.dataSource = new MatTableDataSource(this.confMngtData);
        const confMngtArray = this.confMngtData.map(data => {
          return new FormGroup({
            status: new FormControl(data.status, Validators.required),
          }, { updateOn: "blur" });
        });

        this.UpdatedconfMngtData = new FormArray(confMngtArray);
      });

  }

  contentDetails() {

    this.gd.getModelDetails(this.id)
      .subscribe(res => {
        console.log("model details  :", res);
        this.confDetailsData = []
        this.specId = []
        this.sortedRes = []

        res.forEach(el => {
          this.confDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, SpecID: el.SpecID })
        });

        if (res != []) {
          this.sortedRes = this.confDetailsData.sort((a, b) => a.type.localeCompare(b.type))
        }
        this.sortedRes.forEach(el => {
          this.specId.push(el.SpecID)
        });

        this.dataDetailsSource = new MatTableDataSource(this.sortedRes);
        this.dataDetailsSource.paginator = this.paginator;
        const confDetailsArray = this.sortedRes.map(data => {
          return new FormGroup({
            position: new FormControl(data.position, Validators.required),
            type: new FormControl(data.type, Validators.required),
            partNumber: new FormControl(data.partNumber, Validators.required),
          }, { updateOn: "blur" });
        });

        this.UpdatedconfDetailsData = new FormArray(confDetailsArray);
      });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      this.tableEdit.update(index, field, control, this.confMngtData);
    }

    this.dataSource = new MatTableDataSource(this.tableEdit.updatedDataSource.data);
    this.dataSource.paginator = this.paginator;
  }

  getControl(index, fieldName) {
    return this.UpdatedconfMngtData.at(index).get(fieldName) as FormControl;
  }

  updateDetailsField(index, field) {
    const control = this.getDetailsControl(index, field);
    if (control.valid) {
      this.confDetailsData = this.confDetailsData.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value
          }
        }
        return e;
      });
      this.onChangeDataDetailsSource(this.confDetailsData);
    }
  }

  getDetailsControl(index, fieldName) {
    return this.UpdatedconfDetailsData.at(index).get(fieldName) as FormControl;
  }

  onChangeDataDetailsSource(updatedValue) {
    this.dataDetailsSource = new MatTableDataSource(updatedValue);
    this.dataDetailsSource.paginator = this.paginator;
  }

  changeVal() { // triggers on change of any field(s)
    this.noChangeYet = false;
  }

  changeDetailsVal() { // triggers on change of any field(s)
    this.noDetailsChangeYet = false;
  }

  onSubmit() {
    console.log("Updated Data on table: ", this.UpdatedconfMngtData);
    var data = {
      "ModelID": this.id,
      "Status": this.UpdatedconfMngtData.value[0].status,
      "UpdatedBy": this.currentUser.userName
    }
    console.log("new user details data: ", data);

    this.ud.updateModel(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.noChangeYet = true;
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Status of Model Number " + this.id + " updated successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Status of Model Number " + this.id + " updation failed!", snConfig);
        }
      });
  }

  onSubmitDetails() {
    console.log("Updated Data on table: ", this.UpdatedconfDetailsData, this.specId);

    let data = []
    for (let i = 0; i < this.UpdatedconfDetailsData.length; i++) {
      var obj = {
        "SpecID": this.specId[i],
        "Position": this.UpdatedconfDetailsData.value[i].position,
        "AstType": this.UpdatedconfDetailsData.value[i].type,
        "PartNo": this.UpdatedconfDetailsData.value[i].partNumber,
        "UpdatedBy": this.currentUser.userName
      }
      data.push(obj)
    }
    console.log("new user details data: ", data);

    this.ud.updateModelDetails(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.noDetailsChangeYet = true;
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Model details of " + this.id + " updated successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Model details of " + this.id + " updation failed!", snConfig);
        }
      });
  }

}
