<div class="orgMaster">
  <div class="fixed">
    <h4><strong> Organization Master </strong>
      <small class="float-right m-3">* Click on the table rows to view the details </small>
    </h4>
  </div>
  <mat-divider></mat-divider>

  <div class="orgMaster-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orgName">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col1Clicked == false">Organization Name
            <button type="button" mat-button matTooltip='Click to search' (click)="col1Clicked = !col1Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col1Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Organization Name" [formControl]="orgNameFilter">
              <button type="button" mat-button (click)="col1Clicked = !col1Clicked; clear('orgNameFilter')" matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.OrgName }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col2Clicked == false">Status
            <button type="button" mat-button matTooltip='Click to search' (click)="col2Clicked = !col2Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col2Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Status" [formControl]="statusFilter">
              <button type="button" mat-button (click)="col2Clicked = !col2Clicked; clear('statusFilter')" matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.Status }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRedirectToOrgDetails(row?.OrgID,row)">
      </tr>
    </table>

    <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
      <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>

  </div>

  <div *ngIf="sortedRes.length === 0">
    <mat-toolbar-row class="toolbar-row">
      <span class="noRecords">No records found!</span>
    </mat-toolbar-row>
  </div>

  <div class="table-actions col-sm-12 m-3">
    <div class="float-right">
      <button type="button" mat-button class="primary-btn mr-2" (click)="createOrg('create','new')">
        <mat-icon>create</mat-icon> Create New
      </button>
    </div>
  </div>

</div>
