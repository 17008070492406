<div class="assetMngt">
  <div class="assetMngt-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataDetailsSource">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Position </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.position }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Asset Type </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.type }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="partNumber">
        <th mat-header-cell *matHeaderCellDef> Part Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.partNumber }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="MSN">
        <th mat-header-cell *matHeaderCellDef> MSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }}
          <button mat-button *ngIf="element?.type != 'Airframe'" (click)="onViewAssetConfig(index,element)">
            <mat-icon class="search-icon">search</mat-icon>
          </button>
        </td>
        <td mat-cell class="non-edit" *ngIf="selectedMSNDetails != {}"> {{ selectedMSNDetails?.MSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="asset">
        <th mat-header-cell *matHeaderCellDef> Asset </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.asset }} </td>
        <td mat-cell class="non-edit" *ngIf="selectedMSNDetails != {}"> {{ selectedMSNDetails?.asset }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="THSN">
        <th mat-header-cell *matHeaderCellDef> THSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.THSN }} </td>
        <td mat-cell class="non-edit" *ngIf="selectedMSNDetails != {}"> {{ selectedMSNDetails?.THSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="TCSN">
        <th mat-header-cell *matHeaderCellDef> TCSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.TCSN }} </td>
        <td mat-cell class="non-edit" *ngIf="selectedMSNDetails != {}"> {{ selectedMSNDetails?.TCSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="parameter1">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.parameter1 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="value1">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.value1 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="parameter2">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.parameter2 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="value2">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.value2 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="parameter3">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.parameter3 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="value3">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.value3 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
    </table>

    <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
      <mat-paginator class="paginator" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>

  </div>

  <div *ngIf="sortedRes.length === 0">
    <mat-toolbar-row class="toolbar-row">
      <span class="noRecords">No records found!</span>
    </mat-toolbar-row>
  </div>

  <div class="table-actions col-sm-12 m-3">
    <div class="float-right">
      <button type="submit" mat-button class="save-btn mr-2" (click)="onSubmitDetails()" [disabled]="noChangeYet">
        <mat-icon>save</mat-icon> Save
      </button>
    </div>
  </div>

</div>
