<mat-toolbar>
  <button mat-button *ngIf="isLoggedIn" class="sidebar-toggle" (click)="onSidebarToggle()">
    <mat-icon>dehaze</mat-icon>
  </button>
  <div>
    <img src="../../../assets/images/aerlink.png" class="mr-3" alt="Aerlink" width="160" height="35">
    <strong class="title">
    </strong>
  </div>
  <div class="spacer"></div>
  <div class="menubar-actions" *ngIf="isLoggedIn">
    <button mat-button class="profile" [matMenuTriggerFor]="menu">
      <span>Sanjaya</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <ng-template matMenuContent>
        <button mat-menu-item (click)="onLogOut()">
          <mat-icon>power_settings_new</mat-icon>Log out
        </button>
      </ng-template>
    </mat-menu>
  </div>
</mat-toolbar>
