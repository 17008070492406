import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { TableEditService } from 'src/app/services/table-edit.service';
import { MatTable } from '@angular/material/table'
import { GetDetailService } from 'src/app/services/getDetails.service';
import { UpdateDetails } from 'src/app/services/updateDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-org-master-details',
  templateUrl: './org-master-details.component.html',
  styleUrls: ['./org-master-details.component.scss']
})
export class OrgMasterDetailsComponent implements OnInit {

  constructor(
    private tableEdit: TableEditService,
    private route: ActivatedRoute,
    private gd: GetDetailService,
    private ud: UpdateDetails,
    private snotService: SnotifyService) { }

  currentUser: any;
  id: any;

  pickListValues = new PickList();
  statusOpts = this.pickListValues.recStatus
  positionOpts = this.pickListValues.position
  assetTypeOpts = this.pickListValues.type
  lifeParamsOpts = this.pickListValues.lifeParams
  utilParamsOpts = this.pickListValues.utilParams

  orgMasterData: any = [];
  UpdatedorgMasterData: FormArray;
  displayedColumns: any[] = ['orgName', 'orgType', 'address', 'status'];
  dataSource: MatTableDataSource<any>;

  orgDetailsData: any = [];
  UpdatedorgDetailsData: FormArray;
  displayedDetailsColumns: any[] = ['bankName', 'number', 'accntNumb'];
  dataDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.id = this.route.snapshot.params.id
    this.getContent();
  }

  getContent() {

    this.gd.getOrgDetails(this.id)
      .subscribe(res => {
        console.log("org details  :", res);
        this.orgMasterData = []
        this.orgDetailsData = []

        res.forEach(el => {
          this.orgMasterData.push({ orgName: el.OrgName, orgType: el.OrgType, address: el.Address, status: el.Status })
          this.orgDetailsData.push({ bankName: el.BankName, number: el.Numb, accntNumb: el.AccNo })
        });

        this.dataSource = new MatTableDataSource(this.orgMasterData);
        const orgMasterArray = this.orgMasterData.map(data => {
          return new FormGroup({
            address: new FormControl(data.address, Validators.required),
            status: new FormControl(data.status, Validators.required),
          }, { updateOn: "blur" });
        });

        this.UpdatedorgMasterData = new FormArray(orgMasterArray);

        this.dataDetailsSource = new MatTableDataSource(this.orgDetailsData);
        const orgDetailsArray = this.orgDetailsData.map(data => {
          return new FormGroup({
            bankName: new FormControl(data.bankName, Validators.required),
            number: new FormControl(data.number, Validators.required),
            accntNumb: new FormControl(data.accntNumb, Validators.required),
          }, { updateOn: "blur" });
        });

        this.UpdatedorgDetailsData = new FormArray(orgDetailsArray);
      });
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      this.tableEdit.update(index, field, control, this.orgMasterData);
    }

    this.dataSource = new MatTableDataSource(this.tableEdit.updatedDataSource.data);
    this.dataSource.paginator = this.paginator;
  }

  getControl(index, fieldName) {
    return this.UpdatedorgMasterData.at(index).get(fieldName) as FormControl;
  }

  updateDetailsField(index, field) {
    const control = this.getDetailsControl(index, field);
    if (control.valid) {
      this.tableEdit.update(index, field, control, this.orgDetailsData);
    }

    this.dataDetailsSource = new MatTableDataSource(this.tableEdit.updatedDataSource.data);
    this.dataDetailsSource.paginator = this.paginator;
  }

  getDetailsControl(index, fieldName) {
    return this.UpdatedorgDetailsData.at(index).get(fieldName) as FormControl;
  }

  onSubmit() {
    console.log("Updated Data on table: ", this.UpdatedorgMasterData);
    var data = {
      "OrgID": this.id,
      "Address": this.UpdatedorgMasterData.value[0].address,
      "Status": this.UpdatedorgMasterData.value[0].status,
      "BankName": this.UpdatedorgDetailsData.value[0].bankName,
      "Numb": this.UpdatedorgDetailsData.value[0].number,
      "AccNo": this.UpdatedorgDetailsData.value[0].accntNumb,
      "UpdatedBy": this.currentUser.userName
    }
    console.log("new user details data: ", data);

    this.ud.updateOrg(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Organization details of " + this.orgMasterData[0].orgName + " with ID: " + this.id + " updated successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Organization details of " + this.orgMasterData[0].orgName + " with ID: " + this.id + " failed!", snConfig);
        }
        this.getContent()
      });
  }

}
