import { ChangeDetectorRef, Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { TableEditService } from 'src/app/services/table-edit.service';
import { MatTable } from '@angular/material/table'
import { DataService, GetDetailService } from 'src/app/services/getDetails.service';
import { CreateDetails } from 'src/app/services/createDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';
import { CurrencyPipe } from "@angular/common";

@Component({
  selector: 'app-asset-mngt-billing',
  templateUrl: './asset-mngt-billing.component.html',
  styleUrls: ['./asset-mngt-billing.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AssetMngtBillingComponent implements OnInit {

  @Input() openDt: string;
  @Input() closeDt: string;
  @Input() assetDetailsUtil: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private alert: MatSnackBar,
    private gd: GetDetailService,
    private snotService: SnotifyService,
    private cdref: ChangeDetectorRef,
    private cd: CreateDetails,
    private ds: DataService,
    private currencyPipe: CurrencyPipe) { }

  currentUser: any;
  id: any;
  noChangeYet: boolean = true;
  hideFormBtn: boolean = false;
  disablebutton: any[];

  showInnerTable: boolean = true

  pickListValues = new PickList();
  rentalParamOpts = this.pickListValues.rentalParams

  sortedRes: any = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  @ViewChildren('innerTables') innerTables: QueryList<MatTable<UtilRates>>;

  dataDetailsSource: MatTableDataSource<Asset>;
  dataValues: any;
  topAssetId: any;
  assetDetailsAllData: any = [];
  assetDetailsData: any = [];
  UpdatedassetDetailsData: FormArray;
  columnsToDisplay = ['position', 'assetType', 'partNumb', 'msn', 'assetNumb', 'totalAmnt'];
  innerDisplayedColumns = ['utilParam1', 'utilValue1', 'utilRate1', 'utilParam2', 'utilValue2', 'utilRate2', 'action'];

  expandedElement: Asset | null;
  rowIndex: number;
  utilRates: any[];
  utilData: any[];
  airframeData: any[];
  afTotal1: number = 0
  afTotal2: number = 0
  afTotal3: number = 0
  afTotal4: number = 0
  afTotalAmnt: number = 0

  rowTotalAmnt: number = 0
  allTotalAmnt: number = 0

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.id = this.route.snapshot.params.id

    this.airframeForm = this.fb.group({
      parameter1: [''],
      rental1: [{ value: '', disabled: true }],
      billingRate1: [{ value: '', disabled: true }],
      parameter2: [''],
      rental2: [{ value: '', disabled: true }],
      billingRate2: [{ value: '', disabled: true }],
      parameter3: [''],
      rental3: [{ value: '', disabled: true }],
      billingRate3: [{ value: '', disabled: true }],
      parameter4: [''],
      rental4: [{ value: '', disabled: true }],
      billingRate4: [{ value: '', disabled: true }],
      FHValue: [{ value: '', disabled: true }],
    })

    this.getContent()

  }

  toggleRow(element: Asset, index) {
    this.rowIndex = index

    if ((this.openDt == '' && this.closeDt == '') || this.openDt == '' || this.closeDt == '') {
      return this.alert.open("Please select Opening and Closing dates to get details", "OK", { duration: 4000, verticalPosition: 'top' });
    }

    if (this.assetDetailsUtil.length == 0) {
      return this.alert.open("No Utilization Details for selected time period", "OK", { duration: 4000, verticalPosition: 'top' });
    }

    if (this.assetDetailsUtil.length > 0) {

      element.utilRates && (element.utilRates as MatTableDataSource<UtilRates>) ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
      this.cdref.detectChanges();

      if (element.assetType === 'Airframe' || element.assetType === 'AirFrame') {
        this.showInnerTable = false

        this.gd.getContractDetails(this.dataValues.ContNo)
          .subscribe(cont => {
            console.log("airframe contarct details  :", cont);

            cont.forEach(contElement => {
              if (element.assetType == contElement.AstType) {

                this.gd.getAssetUtilRates(this.id, this.openDt, this.closeDt)
                  .subscribe(res => {
                    console.log("billing getAssetUtilRates details  :", res);
                    res.forEach(el => {
                      if (element.assetType == el.AstType) {
                        this.f.FHValue.setValue(el.Value2)
                      }
                    });
                  })
                this.airframeData = [{ assetType: element.assetType, msn: element.msn, assetId: element.assetNumb, fixedRental1: contElement.RR1, fixedRental2: contElement.RR2, fixedRental3: contElement.RR3, fixedRental4: contElement.RR4, billRate1: contElement.Rate1, billRate2: contElement.Rate2, billRate3: contElement.Rate3, billRate4: contElement.Rate4 }]
              }
            });
          });
      } else if (element.assetType === 'Engine' || element.assetType === 'APU' || element.assetType === 'Landing Gear') {
        this.showInnerTable = true
        this.gd.getContractDetails(this.dataValues.ContNo)
          .subscribe(cont => {
            console.log("contarct details  :", cont);

            cont.forEach(contElement => {

              if (element.position == contElement.Position && element.assetType == contElement.AstType && element.partNumb == contElement.PartNo) {
                this.utilRates = [{ utilRate1: contElement.Rate1, utilRate2: contElement.Rate2 }]

                this.gd.getAssetUtilRates(this.id, this.openDt, this.closeDt)
                  .subscribe(res => {
                    console.log("billing getAssetUtilRates details  :", res);
                    res.forEach(el => {
                      if (element.assetNumb == el.AssetID && element.msn == el.MSN) {
                        this.utilData = [{ utilParam1: el.Param1, utilValue1: el.Value1, utilRate1: this.utilRates[0].utilRate1, utilParam2: el.Param2, utilValue2: el.Value2, utilRate2: this.utilRates[0].utilRate2 }]

                        this.assetDetailsData[index].utilRates = new MatTableDataSource(this.utilData);

                        if(this.utilData[0].utilValue1 == "" || this.utilData[0].utilValue1 == undefined) {
                          this.utilData[0].utilValue1 = "0"
                        }
                        if(this.utilData[0].utilValue2 == "" || this.utilData[0].utilValue2 == undefined) {
                          this.utilData[0].utilValue2 = "0"
                        }
                        this.rowTotalAmnt = (((Number(this.utilData[0].utilValue1)) * (Number(this.utilData[0].utilRate1))) + ((Number(this.utilData[0].utilValue2)) * (Number(this.utilData[0].utilRate2))))
                      }
                    });
                  })
              }
            });
          });

      }

    }
  }

  onSelectParam(param, paramVal) {
    this.noChangeYet = false;
    if (param == 'param1') {
      if (paramVal == "6 Year Check") {
        this.f.rental1.setValue(this.airframeData[0].fixedRental1)
        this.f.billingRate1.setValue(this.airframeData[0].billRate1)
      } else if (paramVal == "8 Year Check") {
        this.f.rental1.setValue(this.airframeData[0].fixedRental2)
        this.f.billingRate1.setValue(this.airframeData[0].billRate2)
      } else if (paramVal == "10 Year Check") {
        this.f.rental1.setValue(this.airframeData[0].fixedRental3)
        this.f.billingRate1.setValue(this.airframeData[0].billRate3)
      } else if (paramVal == "12 Year Check") {
        this.f.rental1.setValue(this.airframeData[0].fixedRental4)
        this.f.billingRate1.setValue(this.airframeData[0].billRate4)
      }
      this.calcAirframeTotal(Number(this.f.rental1.value), Number(this.f.billingRate1.value), 'afTotal1', this.rowIndex)
    }

    if (param == 'param2') {
      if (paramVal == "6 Year Check") {
        this.f.rental2.setValue(this.airframeData[0].fixedRental1)
        this.f.billingRate2.setValue(this.airframeData[0].billRate1)
      } else if (paramVal == "8 Year Check") {
        this.f.rental2.setValue(this.airframeData[0].fixedRental2)
        this.f.billingRate2.setValue(this.airframeData[0].billRate2)
      } else if (paramVal == "10 Year Check") {
        this.f.rental2.setValue(this.airframeData[0].fixedRental3)
        this.f.billingRate2.setValue(this.airframeData[0].billRate3)
      } else if (paramVal == "12 Year Check") {
        this.f.rental2.setValue(this.airframeData[0].fixedRental4)
        this.f.billingRate2.setValue(this.airframeData[0].billRate4)
      }
      this.calcAirframeTotal(Number(this.f.rental2.value), Number(this.f.billingRate2.value), 'afTotal2', this.rowIndex)
    }

    if (param == 'param3') {
      if (paramVal == "6 Year Check") {
        this.f.rental3.setValue(this.airframeData[0].fixedRental1)
        this.f.billingRate3.setValue(this.airframeData[0].billRate1)
      } else if (paramVal == "8 Year Check") {
        this.f.rental3.setValue(this.airframeData[0].fixedRental2)
        this.f.billingRate3.setValue(this.airframeData[0].billRate2)
      } else if (paramVal == "10 Year Check") {
        this.f.rental3.setValue(this.airframeData[0].fixedRental3)
        this.f.billingRate3.setValue(this.airframeData[0].billRate3)
      } else if (paramVal == "12 Year Check") {
        this.f.rental3.setValue(this.airframeData[0].fixedRental4)
        this.f.billingRate3.setValue(this.airframeData[0].billRate4)
      }
      this.calcAirframeTotal(Number(this.f.rental3.value), Number(this.f.billingRate3.value), 'afTotal3', this.rowIndex)
    }

    if (param == 'param4') {
      if (paramVal == "6 Year Check") {
        this.f.rental4.setValue(this.airframeData[0].fixedRental1)
        this.f.billingRate4.setValue(this.airframeData[0].billRate1)
      } else if (paramVal == "8 Year Check") {
        this.f.rental4.setValue(this.airframeData[0].fixedRental2)
        this.f.billingRate4.setValue(this.airframeData[0].billRate2)
      } else if (paramVal == "10 Year Check") {
        this.f.rental4.setValue(this.airframeData[0].fixedRental3)
        this.f.billingRate4.setValue(this.airframeData[0].billRate3)
      } else if (paramVal == "12 Year Check") {
        this.f.rental4.setValue(this.airframeData[0].fixedRental4)
        this.f.billingRate4.setValue(this.airframeData[0].billRate4)
      }
      this.calcAirframeTotal(Number(this.f.rental4.value), Number(this.f.billingRate4.value), 'afTotal4', this.rowIndex)
    }

  }

  calcAirframeTotal(rentalRate, billRate, val, index) {
    const total = rentalRate + ((billRate) * (this.f.FHValue.value))
    if (val == 'afTotal1') { this.afTotal1 = total }
    if (val == 'afTotal2') { this.afTotal2 = total }
    if (val == 'afTotal3') { this.afTotal3 = total }
    if (val == 'afTotal4') { this.afTotal4 = total }
    this.afTotalAmnt = this.afTotal1 + this.afTotal2 + this.afTotal3 + this.afTotal4
    this.assetDetailsData[index].totalAmnt = this.afTotalAmnt
  }

  calcAllTotalAmnt(val) {
    this.allTotalAmnt = this.allTotalAmnt + val
    this.ds.billData({ "TotalAmnt": this.allTotalAmnt })
  }

  getContent() {

    this.ds.currentData.subscribe(res => {
      console.log("dataValues in billing", res)
      this.dataValues = res
    });

    this.gd.getAssetDetails(this.id)
      .subscribe(res => {
        console.log("model details  :", res);
        this.topAssetId = res[0].TAssetID
        this.assetDetailsAllData = res
        this.assetDetailsData = []
        this.disablebutton = []
        this.sortedRes = []

        res.forEach((el, i) => {
          this.assetDetailsData.push({ position: el.Position, assetType: el.AstType, partNumb: el.PartNo, msn: el.MSN, assetNumb: el.AssetID, totalAmnt: '', utilRates: new MatTableDataSource([{ utilParam1: '', utilValue1: '', utilRate1: '', utilParam2: '', utilValue2: '', utilRate2: '' }]), SpecID: el.SpecID })
          this.disablebutton.push(false)
        });

        if (res != []) {
          this.sortedRes = this.assetDetailsData.sort((a, b) => a.assetType.localeCompare(b.assetType))
        }

        this.dataDetailsSource = new MatTableDataSource(this.sortedRes);
        this.dataDetailsSource.paginator = this.paginator;

      });
  }

  submitted: boolean = false;

  airframeForm: FormGroup;

  get f() { return this.airframeForm.controls; }

  onSubmitForm() {
    this.submitted = true;

    if (this.airframeForm.invalid) {
      console.log("Invalid Form: ", this.airframeForm);
      return;
    }

    console.log("Valid Form: ", this.airframeForm);

    if ((this.openDt == '' && this.closeDt == '') || this.openDt == '' || this.closeDt == '') {
      return this.alert.open("Please select Start and End dates", "OK", { duration: 4000, verticalPosition: 'top' });
    }

    this.calcAllTotalAmnt(this.afTotalAmnt)
    // for Airframe we dont have position and part number

    let data = []
    var obj = {
      "TAssetID": this.topAssetId,
      "Position": '',
      "AstType": this.airframeData[0].assetType,
      "PartNo": '',
      "MSN": this.airframeData[0].msn,
      "AssetID": this.airframeData[0].assetId,
      "OpenDt": this.openDt,
      "CloseDt": this.closeDt,
      "FH": this.f.FHValue.value,
      "Total": JSON.stringify(this.afTotalAmnt),
      "Value1": this.f.billingRate1.value,
      "Param1": this.f.parameter1.value,
      "Rate1": this.f.rental1.value,
      "Value2": this.f.billingRate2.value,
      "Param2": this.f.parameter2.value,
      "Rate2": this.f.rental2.value,
      "Value3": this.f.billingRate3.value,
      "Rate3": this.f.rental3.value,
      "Param3": this.f.parameter3.value,
      "Value4": this.f.billingRate4.value,
      "Rate4": this.f.rental4.value,
      "Param4": this.f.parameter4.value,
      "LessorID": this.dataValues.Lessor,
      "Operator": this.dataValues.Operator,
      "CreateBy": this.currentUser.userName
    }
    data.push(obj)
    console.log("util asset details data: ", data);

    this.cd.createAssetBill(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.noChangeYet = true;
          this.hideFormBtn = true;
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Bill details of Top AssetID: " + this.topAssetId + " created successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Bill details of Top AssetID: " + this.topAssetId + " creation failed!", snConfig);
        }
      });
  }

  onSubmit(i) {
    console.log("bill assetDetailsData", this.assetDetailsData)

    if ((this.openDt == '' && this.closeDt == '') || this.openDt == '' || this.closeDt == '') {
      return this.alert.open("Please select Start and End dates", "OK", { duration: 4000, verticalPosition: 'top' });
    }

    this.assetDetailsData[i].totalAmnt = this.rowTotalAmnt
    this.calcAllTotalAmnt(this.assetDetailsData[i].totalAmnt)

    let data = []
    var obj = {
      "TAssetID": this.topAssetId,
      "Position": this.assetDetailsData[i].position,
      "AstType": this.assetDetailsData[i].assetType,
      "PartNo": this.assetDetailsData[i].partNumb,
      "MSN": this.assetDetailsData[i].msn,
      "AssetID": this.assetDetailsData[i].assetNumb,
      "THSN": this.assetDetailsAllData[i].THSN,
      "TCSN": this.assetDetailsAllData[i].TCSN,
      "OpenDt": this.openDt,
      "CloseDt": this.closeDt,
      "Total": JSON.stringify(this.assetDetailsData[i].totalAmnt),
      "Value1": this.assetDetailsData[i].utilRates.data[0].utilValue1,
      "Param1": this.assetDetailsData[i].utilRates.data[0].utilParam1,
      "Rate1": this.assetDetailsData[i].utilRates.data[0].utilRate1,
      "Value2": this.assetDetailsData[i].utilRates.data[0].utilValue2,
      "Param2": this.assetDetailsData[i].utilRates.data[0].utilParam2,
      "Rate2": this.assetDetailsData[i].utilRates.data[0].utilRate2,
      "LessorID": this.dataValues.Lessor,
      "Operator": this.dataValues.Operator,
      "CreateBy": this.currentUser.userName
    }
    data.push(obj)
    console.log("util asset details data: ", data);

    this.cd.createAssetBill(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.noChangeYet = true;
          this.disablebutton[i] = true;
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Bill details of Top AssetID: " + this.topAssetId + " created successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Bill details of Top AssetID: " + this.topAssetId + " creation failed!", snConfig);
        }
      });
  }

}

export interface Asset {
  position: string;
  assetType: string;
  partNumb: string;
  msn: number;
  assetNumb: number;
  totalAmnt: number;
  utilRates?: any;
}

export interface UtilRates {
  utilParam1: string;
  utilValue1: number;
  utilRate1: number;
  utilParam2: string;
  utilValue2: number;
  utilRate2: number;
}
