<div class="assetMngt">
  <h4><strong> <a [routerLink]="['/asset-mngt']">
        <mat-icon>arrow_back</mat-icon>
      </a> Create New Asset </strong></h4>
  <mat-divider></mat-divider>

  <div class="assetMngt-table mt-3 table-responsive">
    <form [formGroup]="assetMngtForm" (ngSubmit)="onSubmit()">

      <table mat-table [dataSource]="newAssetSource">
        <ng-container matColumnDef="asset">
          <th mat-header-cell *matHeaderCellDef> Top Asset </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ topAssetId }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="modelNumber">
          <th mat-header-cell *matHeaderCellDef> Model Number </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="modelNumber" placeholder="Model Number" [value]="element.modelNumber">
                <mat-option *ngFor="let opt of modelNumbOpts" [value]="opt.viewValue">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.modelNumber.errors?.required || f.modelNumber.touched">
                Please select the model number
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="MSN">
          <th mat-header-cell *matHeaderCellDef> MSN </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="MSN" placeholder="MSN" [value]="element.MSN">
              <mat-error *ngIf="submitted || f.MSN.errors?.required || f.MSN.touched">
                Please enter the MSN
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Asset Type </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
            {{ this.assetMngtForm.value.type }} </td>
        </ng-container>
        <ng-container matColumnDef="age">
          <th mat-header-cell *matHeaderCellDef> Age (Years) </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="age" placeholder="Age (Years)" [value]="element.age">
              <mat-error *ngIf="submitted || f.age.errors?.required || f.age.touched">
                Please enter the age
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="lessor">
          <th mat-header-cell *matHeaderCellDef> Lessor </th>
          <ng-container *ngIf="isLessor == false">
            <td mat-cell *matCellDef="let element">
              <mat-form-field floatLabel="never">
                <mat-select formControlName="lessor" placeholder="Lessor" [value]="element.lessor">
                  <mat-option *ngFor="let opt of lessorOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="submitted || f.lessor.errors?.required || f.lessor.touched">
                  Please enter the lessor
                </mat-error>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngIf="isLessor == true">
            <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ this.currentUser.orgName }}
            </td>
          </ng-container>
        </ng-container>
        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef> Operator </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="operator" placeholder="Operator" [value]="element.operator">
                <mat-option *ngFor="let opt of operatorOpts" [value]="opt.viewValue">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.operator.errors?.required || f.operator.touched">
                Please select the operator
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="registration">
          <th mat-header-cell *matHeaderCellDef> Registration </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="registration" placeholder="Registration" [value]="element.registration">
              <mat-error *ngIf="submitted || f.registration.errors?.required || f.registration.touched">
                Please enter the registration
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="contract">
          <th mat-header-cell *matHeaderCellDef> Contract </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="contract" placeholder="Contract" [value]="element.contract">
              <mat-error *ngIf="submitted || f.contract.errors?.required || f.contract.touched">
                Please enter the contract
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef> Start Date </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.startDate }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef> End Date </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.endDate }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="status" placeholder="Status" [value]="element.status">
                <mat-option *ngFor="let opt of assetStatusOpts" [value]="opt.viewValue">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.status.errors?.required || f.status.touched">
                Please select the status
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
            <button type="submit" mat-button class="save-btn" [disabled]="isSaved">
              <mat-icon>save</mat-icon> Save
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </form>
  </div>

  <div class="assetMngtDetails-table mt-3 table-responsive" *ngIf="showModelDetails == true">
    <form [formGroup]="assetMngtDetailsForm" (ngSubmit)="onSubmitDetails()">

      <table mat-table formArrayName="assetMngtDetails" [dataSource]="newAssetDetailsSource">
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> Position </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.position }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Asset Type </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.type }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="partNumber">
          <th mat-header-cell *matHeaderCellDef> Part Number </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.partNumber }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="MSN">
          <th mat-header-cell *matHeaderCellDef> MSN </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="MSN" placeholder="MSN" [value]="element.MSN" required>
            </mat-form-field>
            <button *ngIf="element?.type != 'Airframe'" type="button" mat-button (click)="onViewAssetConfig(rowIndex)">
              <mat-icon class="search-icon">search</mat-icon>
            </button>
          </td>
          <td mat-cell class="non-edit" *ngIf="selectedMSNDetails != {}"> {{ selectedMSNDetails?.MSN }} </td>
        </ng-container>
        <ng-container matColumnDef="asset">
          <th mat-header-cell *matHeaderCellDef> Asset </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="asset" placeholder="Asset" [value]="element.asset">
            </mat-form-field>
          </td>
          <td mat-cell class="non-edit" *ngIf="selectedMSNDetails != {}"> {{ selectedMSNDetails?.asset }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="THSN">
          <th mat-header-cell *matHeaderCellDef> THSN </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="THSN" placeholder="THSN" [value]="element.THSN" required>
            </mat-form-field>
          </td>
          <td mat-cell class="non-edit" *ngIf="selectedMSNDetails != {}"> {{ selectedMSNDetails?.THSN }} </td>
        </ng-container>
        <ng-container matColumnDef="TCSN">
          <th mat-header-cell *matHeaderCellDef> TCSN </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="TCSN" placeholder="TCSN" [value]="element.TCSN" required>
            </mat-form-field>
          </td>
          <td mat-cell class="non-edit" *ngIf="selectedMSNDetails != {}"> {{ selectedMSNDetails?.TCSN }} </td>
        </ng-container>
        <ng-container matColumnDef="parameter1">
          <th mat-header-cell *matHeaderCellDef> Parameter </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="parameter1" placeholder="Parameter" [value]="element.parameter1" required>
                <mat-option *ngFor="let opt of paramsOpts" [value]="opt.viewValue">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="value1">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="value1" placeholder="Value" [value]="element.value1" required>
            </mat-form-field>
          </td>
          <td mat-cell class="non-edit" *ngIf="selectedMSNDetails != {}"> {{ selectedMSNDetails?.value1 }} </td>
        </ng-container>
        <ng-container matColumnDef="parameter2">
          <th mat-header-cell *matHeaderCellDef> Parameter </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="parameter2" placeholder="Parameter" [value]="element.parameter2" required>
                <mat-option *ngFor="let opt of paramsOpts" [value]="opt.viewValue">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="value2">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="value2" placeholder="Value" [value]="element.value2" required>
            </mat-form-field>
          </td>
          <td mat-cell class="non-edit" *ngIf="selectedMSNDetails != {}"> {{ selectedMSNDetails?.value2 }} </td>
        </ng-container>
        <ng-container matColumnDef="parameter3">
          <th mat-header-cell *matHeaderCellDef> Parameter </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <mat-form-field *ngIf="element?.type == 'Engine'" floatLabel="never">
              <mat-select formControlName="parameter3" placeholder="Parameter" [value]="element.parameter3" required>
                <mat-option *ngFor="let opt of paramsOpts" [value]="opt.viewValue">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="value3">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <mat-form-field *ngIf="element?.type == 'Engine'" floatLabel="never">
              <input matInput formControlName="value3" placeholder="Value" [value]="element.value3" required>
            </mat-form-field>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
      </table>

      <div class="table-actions mt-2">
        <button type="submit" mat-button class="save-btn" [disabled]="isDetailsSaved">
          <mat-icon>save</mat-icon> Save
        </button>
      </div>
    </form>
  </div>
  <br><br>
</div>
