<mat-toolbar class="aerkink-toolbar">
  <mat-icon class="nav-item" (click)="isExpanded = !isExpanded" *ngIf="authService.isSidebarOpen"
    matTooltip="Menu List"> menu</mat-icon>
  <div>
    <img src="../../../assets/images/aerlink.png" class="ml-4" alt="Aerlink" width="160" height="35">
    <strong class="title">
    </strong>
  </div>
  <div class="spacer"></div>
  <mat-icon class="nav-item" (click)="onLogOut()" *ngIf="authService.isSidebarOpen" matTooltip="Logout">logout
  </mat-icon>
</mat-toolbar>

<mat-drawer-container [hasBackdrop]="false" autosize>
  <mat-drawer #drawer mode="side" [opened]="authService.isSidebarOpen">
    <div class="side-bar-cover">
      <div class="custom-side-bar">
        <ul class="side-bar-list">

          <li class="list-item">
            <a class="list-link">
              <mat-icon *ngIf="isLoggedIn" matTooltip="{{currentUser.name}}">account_circle</mat-icon>
              <span matLine *ngIf="isExpanded">Welcome {{currentUser.name}}</span>
            </a>
          </li>
          <hr class="side-bar-line" />

          <li class="list-item">
            <a class="list-link" *ngIf="homeRoutePath == 'admin-home'" [routerLink]="['/admin-home']"
              [routerLinkActive]="['active']" (click)="toggleActive($event)">
              <mat-icon matTooltip="Dashboard">home</mat-icon>
              <span matLine *ngIf="isExpanded">Home</span>
            </a>

            <a class="list-link" *ngIf="homeRoutePath == 'lessor-mng-home'" [routerLink]="['/lessor-mng-home']"
              [routerLinkActive]="['active']" (click)="toggleActive($event)">
              <mat-icon matTooltip="Dashboard">home</mat-icon>
              <span matLine *ngIf="isExpanded">Home</span>
            </a>

            <a class="list-link" *ngIf="homeRoutePath == 'lessor-acc-home'" [routerLink]="['/lessor-acc-home']"
              [routerLinkActive]="['active']" (click)="toggleActive($event)">
              <mat-icon matTooltip="Dashboard">home</mat-icon>
              <span matLine *ngIf="isExpanded">Home</span>
            </a>

            <a class="list-link" *ngIf="homeRoutePath == 'lessee-mng-home'" [routerLink]="['/lessee-mng-home']"
              [routerLinkActive]="['active']" (click)="toggleActive($event)">
              <mat-icon matTooltip="Dashboard">home</mat-icon>
              <span matLine *ngIf="isExpanded">Home</span>
            </a>

            <a class="list-link" *ngIf="homeRoutePath == 'lessee-acc-home'" [routerLink]="['/lessee-acc-home']"
              [routerLinkActive]="['active']" (click)="toggleActive($event)">
              <mat-icon matTooltip="Dashboard">home</mat-icon>
              <span matLine *ngIf="isExpanded">Home</span>
            </a>
          </li>

          <li class="list-item" *ngIf="confMngtAccess == true">
            <a class="list-link" [routerLink]="['/conf-mngt']" [routerLinkActive]="['active']"
              (click)="toggleActive($event)">
              <mat-icon matTooltip="Configuration Management">build</mat-icon>
              <span matLine *ngIf="isExpanded">Configuration Management</span>
            </a>
          </li>

          <li class="list-item" *ngIf="assetMngtAccess == true">
            <a class="list-link" [routerLink]="['/asset-mngt']" [routerLinkActive]="['active']"
              (click)="toggleActive($event)">
              <mat-icon matTooltip="Asset Management">flight_takeoff</mat-icon>
              <span matLine *ngIf="isExpanded">Asset Management</span>
            </a>
          </li>

          <li class="list-item" *ngIf="orgMngtAccess == true">
            <a class="list-link" [routerLink]="['/org-master']" [routerLinkActive]="['active']"
              (click)="toggleActive($event)">
              <mat-icon matTooltip="Organization Master">groups</mat-icon>
              <span matLine *ngIf="isExpanded">Organization Master</span>
            </a>
          </li>

          <li class="list-item" *ngIf="userMngtAccess == true">
            <a class="list-link" [routerLink]="['/user-mngt']" [routerLinkActive]="['active']"
              (click)="toggleActive($event)">
              <mat-icon matTooltip="User Management">person_search</mat-icon>
              <span matLine *ngIf="isExpanded">User Management</span>
            </a>
          </li>

          <li class="list-item" *ngIf="contractMngtAccess == true">
            <a class="list-link" [routerLink]="['/contract-mngt']" [routerLinkActive]="['active']"
              (click)="toggleActive($event)">
              <mat-icon matTooltip="Contract Management">assignment</mat-icon>
              <span matLine *ngIf="isExpanded">Contract Management</span>
            </a>
          </li>

          <li class="list-item" *ngIf="utilAccess == true">
            <a class="list-link" [routerLink]="['/util-reports']" [routerLinkActive]="['active']"
              (click)="toggleActive($event)">
              <mat-icon matTooltip="Utilization Reporting">trending_up</mat-icon>
              <span matLine *ngIf="isExpanded">Utilization Reporting</span>
            </a>
          </li>

          <li class="list-item" *ngIf="billAccess == true">
            <a class="list-link" [routerLink]="['/invoices']" [routerLinkActive]="['active']"
              (click)="toggleActive($event)">
              <mat-icon matTooltip="Invoices">payment</mat-icon>
              <span matLine *ngIf="isExpanded">Invoices</span>
            </a>
          </li>

          <li class="list-item pb-4">
            <a class="list-link" [routerLink]="['/reports']" [routerLinkActive]="['active']"
              (click)="toggleActive($event)">
              <mat-icon matTooltip="Reports">list_alt</mat-icon>
              <span matLine *ngIf="isExpanded">Reports</span>
            </a>
          </li>

        </ul>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content class="drawer-content" [ngClass]="isLoggedIn ? 'pb-5' : '' ">
    <div [ngClass]="!isLoggedIn ? 'bg' : '' "></div>
    <div class="main-content" [ngClass]="isLoggedIn ? 'p-4' : 'p-0'">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
