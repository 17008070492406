import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FormControl, FormArray } from '@angular/forms';
import { TableFilterService } from 'src/app/services/table-filter.service';
import { GetDetailService } from 'src/app/services/getDetails.service';

@Component({
  selector: 'app-user-mngt',
  templateUrl: './user-mngt.component.html',
  styleUrls: ['./user-mngt.component.scss']
})
export class UserMngtComponent implements OnInit {

  currentUser: any;
  col1Clicked: boolean = false;
  col2Clicked: boolean = false;
  col3Clicked: boolean = false;
  col4Clicked: boolean = false;
  col5Clicked: boolean = false;

  sortedRes: any = [];

  userMngtData: any = [];
  UpdateduserMngtData: FormArray;
  displayedColumns: any[] = ['orgName', 'userName', 'role', 'name', 'status'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  orgIdFilter = new FormControl("");
  orgNameFilter = new FormControl("");
  userNameFilter = new FormControl("");
  roleFilter = new FormControl("");
  nameFilter = new FormControl("");
  statusFilter = new FormControl("");

  constructor(
    private tableFilter: TableFilterService,
    private router: Router,
    private gd: GetDetailService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.getContent();

    this.orgNameFilter.valueChanges.subscribe(orgName => {
      this.tableFilter.filterValues.orgName = orgName;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.userNameFilter.valueChanges.subscribe(userName => {
      this.tableFilter.filterValues.userName = userName;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.roleFilter.valueChanges.subscribe(role => {
      this.tableFilter.filterValues.role = role;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.nameFilter.valueChanges.subscribe(name => {
      this.tableFilter.filterValues.name = name;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.statusFilter.valueChanges.subscribe(status => {
      this.tableFilter.filterValues.status = status;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
  }

  getContent() {
      if (this.currentUser.role == "Admin") {
        this.gd.getAllUsers()
          .subscribe(res => {
            console.log("all users :", res);
            this.sortedRes = []
            if (res != []) {
              this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
            }
            this.dataSource = new MatTableDataSource(this.sortedRes);
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.tableFilter.createUserFilter();

          });
      } else if (this.currentUser.role == "Lessor Manager" || this.currentUser.role == "Lessor Accountant") {
        this.gd.getAllUsersLessor(this.currentUser.orgName)
          .subscribe(res => {
            console.log("all lessor users :", res);
            this.sortedRes = []
            if (res != []) {
              this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
            }
            this.dataSource = new MatTableDataSource(this.sortedRes);
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.tableFilter.createUserFilter();

          });
      }
  }

  clear(dataFilter) {
    if (dataFilter == 'orgNameFilter') {
      this.orgNameFilter.setValue('')
    } else if (dataFilter == 'userNameFilter') {
      this.userNameFilter.setValue('')
    } else if (dataFilter == 'roleFilter') {
      this.roleFilter.setValue('')
    } else if (dataFilter == 'nameFilter') {
      this.nameFilter.setValue('')
    } else if (dataFilter == 'statusFilter') {
      this.statusFilter.setValue('')
    }
  }

  onRedirectToUserDetails(userName, row) {
    this.router.navigateByUrl('/user-mngt/' + userName)
  }

  createUser(val1, val2) {
    this.router.navigateByUrl('/user-mngt/' + val1 + '/' + val2)
  }

}
