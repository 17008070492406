<div class="contractMngt">
  <h4><strong> <a [routerLink]="['/contract-mngt']">
        <mat-icon>arrow_back</mat-icon>
      </a> Details of Contract : {{ id }} </strong></h4>
  <div class="editInfo m-3">
    <small>* Blue colored data are editable in the table, click on the data to edit </small>
  </div>
  <mat-divider></mat-divider>

  <div class="contractMngt-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef> Contract </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.contract }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="lessor">
        <th mat-header-cell *matHeaderCellDef> Lessor </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.lessor }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef> Operator </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.operator }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="model">
        <th mat-header-cell *matHeaderCellDef> Model </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.model }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="msn">
        <th mat-header-cell *matHeaderCellDef> MSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.msn }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editableDate (update)="updateField(index, 'startDate')">
            <ng-template viewMode *ngIf="contStartDt != ''">
              {{contStartDt | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template viewMode *ngIf="element?.startDate != ''">
              {{((element?.startDate)*1000) | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="startDate" (click)="startDate.open()" [min]="today"
                  [max]="getControl(index, 'endDate')" [(value)]="element.startDate"
                  [formControl]="getControl(index, 'startDate')" (dateChange)="changeVal();formatDate($event,'startDt')"
                  placeholder="Start Date" editableOnEnter>
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
                <mat-hint align="end">Click the Date picker icon</mat-hint>
                <mat-error *ngIf="getControl(index, 'startDate').hasError('required')">
                  Please provide a valid Start Date
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editableDate>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editableDate (update)="updateField(index, 'endDate')">
            <ng-template viewMode *ngIf="contEndDt != ''">
              {{contEndDt | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template viewMode *ngIf="element?.endDate != ''">
              {{((element?.endDate)*1000) | date:"MM/dd/yyyy"}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="endDate" (click)="endDate.open()" [min]="element.startDate"
                  [(value)]="element.endDate" [formControl]="getControl(index, 'endDate')"
                  (dateChange)="changeVal();formatDate($event,'endDt')" placeholder="End Date" editableOnEnter>
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
                <mat-hint align="end">Click the Date picker icon</mat-hint>
                <mat-error *ngIf="getControl(index, 'endDate').hasError('required')">
                  Please provide a valid End Date
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editableDate>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateField(index, 'status')">
            <ng-template viewMode>
              {{element?.status}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.status" placeholder="Status" matInput
                  [formControl]="getControl(index, 'status')" (selectionChange)="changeVal()" editableOnEnter>
                  <mat-option *ngFor="let opt of statusOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getControl(index, 'status').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <button mat-button class="save-btn" (click)="onSubmit()" [disabled]="noChangeYet">
            <mat-icon>save</mat-icon> Save
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div class="contractMngt-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataDetailsSource">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Position </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.position }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Asset Type </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.type }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="partNumber">
        <th mat-header-cell *matHeaderCellDef> Part Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.partNumber }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="utilParam1">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.utilParam1 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="utilRate1">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Rate </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'utilRate1')">
            <ng-template viewMode>
              {{element?.utilRate1 | currency: 'USD'}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'utilRate1')" (change)="changeDetailsVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'utilRate1').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="utilParam2">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.utilParam2 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="utilRate2">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Rate </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'utilRate2')">
            <ng-template viewMode>
              {{element?.utilRate2 | currency: 'USD'}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'utilRate2')" (change)="changeDetailsVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'utilRate2').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="utilParam3">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.utilParam3 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="utilRate3">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Rate </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'utilRate3')">
            <ng-template viewMode>
              {{element?.utilRate3 | currency: 'USD'}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'utilRate3')" (change)="changeDetailsVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'utilRate3').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="utilParam4">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.utilParam4 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="utilRate4">
        <th mat-header-cell *matHeaderCellDef class="second-header-row"> Rate </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'utilRate4')">
            <ng-template viewMode>
              {{element?.utilRate4 | currency: 'USD'}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'utilRate4')" (change)="changeDetailsVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'utilRate4').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="rentalRate1">
        <th mat-header-cell *matHeaderCellDef> 6 Years </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'rentalRate1')">
            <ng-template viewMode>
              {{element?.rentalRate1 | currency: 'USD'}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'rentalRate1')" (change)="changeDetailsVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'rentalRate1').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="rentalRate2">
        <th mat-header-cell *matHeaderCellDef> 8 Years </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'rentalRate2')">
            <ng-template viewMode>
              {{element?.rentalRate2 | currency: 'USD'}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'rentalRate2')" (change)="changeDetailsVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'rentalRate2').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="rentalRate3">
        <th mat-header-cell *matHeaderCellDef> 10 Years </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'rentalRate3')">
            <ng-template viewMode>
              {{element?.rentalRate3 | currency: 'USD'}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'rentalRate3')" (change)="changeDetailsVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'rentalRate3').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="rentalRate4">
        <th mat-header-cell *matHeaderCellDef> 12 Years </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'rentalRate4')">
            <ng-template viewMode>
              {{element?.rentalRate4 | currency: 'USD'}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'rentalRate4')" (change)="changeDetailsVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'rentalRate4').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <ng-container matColumnDef="header-row-first-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="header-row-first-group"></th>
      </ng-container>

      <ng-container matColumnDef="header-row-second-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="8" class="header-row-second-group"> Utilization Based
          Rates </th>
      </ng-container>

      <ng-container matColumnDef="header-row-third-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="4" class="header-row-third-group"> Rental Rates </th>
      </ng-container>

      <tr mat-header-row
        *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group']"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
    </table>

    <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
      <mat-paginator class="paginator" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>

  </div>

  <div *ngIf="sortedRes.length === 0">
    <mat-toolbar-row class="toolbar-row">
      <span class="noRecords">No records found!</span>
    </mat-toolbar-row>
  </div>

  <div class="table-actions col-sm-12 m-3">
    <div class="float-right">
      <button type="submit" mat-button class="save-btn mr-2" (click)="onSubmitDetails()"
        [disabled]="noDetailsChangeYet">
        <mat-icon>save</mat-icon> Save
      </button>
    </div>
  </div>

</div>
