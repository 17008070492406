import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { GetDetailService } from 'src/app/services/getDetails.service';
import { CreateDetails } from 'src/app/services/createDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AssetConfigDialogComponent } from 'src/app/helpers/dialog/asset-config-dialog/asset-config-dialog.component';

@Component({
  selector: 'app-create-new-asset',
  templateUrl: './create-new-asset.component.html',
  styleUrls: ['./create-new-asset.component.scss']
})
export class CreateNewAssetComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private gd: GetDetailService,
    private cd: CreateDetails,
    private snotService: SnotifyService) {
    this.newAssetData = [
      { asset: '', modelNumber: '', MSN: '', age: '', lessor: '', operator: '', registration: '', contract: '', startDate: '', endDate: '', status: '' },
    ]
    this.newAssetSource = new MatTableDataSource(this.newAssetData);
    this.showModelDetails = false
  }

  currentUser: any;
  isLessor: boolean = false;
  isHidden: boolean = false;
  submitted: boolean = false;
  isSaved: boolean = false;
  isDetailsSaved: boolean = false;

  assetMngtForm: FormGroup;
  get f() { return this.assetMngtForm.controls; }

  assetMngtDetailsForm: FormGroup;
  get fd() { return this.assetMngtDetailsForm.controls; }

  topAssetId: any;
  showModelDetails: boolean = false;

  pickListValues = new PickList();
  assetStatusOpts = this.pickListValues.assetStatus
  positionOpts = this.pickListValues.position
  assetTypeOpts = this.pickListValues.type
  paramsOpts = this.pickListValues.utilParams

  modelNumbOpts = []
  operatorOpts = []
  lessorOpts = []

  newAssetData: any = [];
  displayedColumns: any[] = ['asset', 'modelNumber', 'MSN', 'type', 'age', 'lessor', 'operator', 'registration', 'contract', 'startDate', 'endDate', 'status', 'action'];
  newAssetSource: MatTableDataSource<any>;

  newAssetDetailsData: any = [];
  updatedAssetDetailsData: any = [];

  displayedDetailsColumns: any[] = ['position', 'type', 'partNumber', 'MSN', 'asset', 'THSN', 'TCSN', 'parameter1', 'value1', 'parameter2', 'value2', 'parameter3', 'value3'];
  newAssetDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  assetConfigDialogRef: MatDialogRef<AssetConfigDialogComponent>;
  selectedMSNDetails: any = {}

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    if (this.currentUser.role == "Lessor Manager" || this.currentUser.role == "Lessor Accountant") {
      this.isLessor = true;
    }

    this.assetMngtForm = this.fb.group({
      asset: [''],
      modelNumber: ['', Validators.required],
      MSN: ['', Validators.required],
      type: ['Airframe'],
      age: ['', Validators.required],
      lessor: ['', Validators.required],
      operator: ['', Validators.required],
      registration: ['', Validators.required],
      contract: [''],
      startDate: [''],
      endDate: [''],
      status: ['', Validators.required],
    })

    this.getContent();
  }

  private setAssetMngtDetailsForm() {
    const rowDetailsCtrl = this.assetMngtDetailsForm.get('assetMngtDetails') as FormArray;
    (this.newAssetDetailsData).forEach((rowDetails) => {
      rowDetailsCtrl.push(this.setAssetMngtDetailsFormArray(rowDetails))
    })
  };
  private setAssetMngtDetailsFormArray(rowDetails) {
    return this.fb.group({
      position: [rowDetails.position],
      type: [rowDetails.type],
      partNumber: [rowDetails.partNumber],
      MSN: [rowDetails.MSN],
      asset: [rowDetails.asset],
      THSN: [rowDetails.THSN],
      TCSN: [rowDetails.TCSN],
      value1: [rowDetails.value1],
      parameter1: [rowDetails.parameter1],
      value2: [rowDetails.value2],
      parameter2: [rowDetails.parameter2],
      value3: [rowDetails.value3],
      parameter3: [rowDetails.parameter3],
    });
  }

  getContent() {
    this.gd.getAllModels()
      .subscribe(res => {
        console.log("all models :", res);
        this.modelNumbOpts = []
        res.forEach(element => {
          if (this.currentUser.role == 'Admin') {
            this.modelNumbOpts.push({ viewValue: element.ModelID })
          }
          if ((this.currentUser.role == 'Lessor Manager' || this.currentUser.role == 'Lessor Accountant') && this.currentUser.orgName == element.OrgName) {
            this.modelNumbOpts.push({ viewValue: element.ModelID })
          }
        });
      });

    this.gd.getAllOrgs()
      .subscribe(res => {
        console.log("all orgs :", res);
        res.forEach(element => {
          if (element.OrgType == "Operator" || element.OrgType == "Lessee") {
            this.operatorOpts.push({ viewValue: element.OrgName })
          }
          if (element.OrgType == "Lessor") {
            this.lessorOpts.push({ viewValue: element.OrgName })
          }
        });
      })

  }

  onSubmit() {

    if (this.currentUser.role == "Lessor Manager" || this.currentUser.role == "Lessor Accountant") {
      this.assetMngtForm.get('lessor').setValue(this.currentUser.orgName)
    }
    this.submitted = true;
    if (this.assetMngtForm.invalid) {
      console.log("Invalid Form: ", this.assetMngtForm);
      return;
    }

    console.log("Valid Form: ", this.assetMngtForm);

    var data = {
      "ModelID": this.assetMngtForm.value.modelNumber,
      "MSN": this.assetMngtForm.value.MSN,
      "AstType": this.assetMngtForm.value.type,
      "Operator": this.assetMngtForm.value.operator,
      "RegistNo": this.assetMngtForm.value.registration,
      "ContNo": this.assetMngtForm.value.contract,
      "LessorID": this.assetMngtForm.value.lessor,
      "StartDt": this.assetMngtForm.value.startDate,
      "EndDt": this.assetMngtForm.value.endDate,
      "Age": this.assetMngtForm.value.age,
      "Status": this.assetMngtForm.value.status,
      "CreateBy": this.currentUser.userName
    }
    console.log("new asset data: ", data);

    this.cd.createAsset(data)
      .subscribe(r => {
        console.log("r :" + r, JSON.stringify(r));
        this.topAssetId = r.Data
        if (r.status == 'success') {
          this.isSaved = true
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("New AssetID: " + this.topAssetId + " created successfully!", snConfig);

          this.gd.getModelDetails(data.ModelID)
            .subscribe(res => {
              console.log("model details  :", res);
              this.newAssetDetailsData = []
              res.forEach(el => {
                if (el.AstType == 'Engine') {
                  this.newAssetDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, MSN: '', asset: '', THSN: '', TCSN: '', parameter1: 'FC', value1: '', parameter2: 'FH', value2: '', parameter3: 'Derate', value3: '' })
                } else if (el.AstType == 'APU') {
                  this.newAssetDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, MSN: '', asset: '', THSN: '', TCSN: '', parameter1: 'APU Cycles', value1: '', parameter2: 'APU Hours', value2: '', parameter3: '', value3: '' })
                } else if (el.AstType == 'Airframe') {
                  this.newAssetDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, MSN: data.MSN, asset: this.topAssetId, THSN: '', TCSN: '', parameter1: 'FC', value1: '', parameter2: 'FH', value2: '', parameter3: '', value3: '' })
                } else {
                  this.newAssetDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, MSN: '', asset: '', THSN: '', TCSN: '', parameter1: 'FC', value1: '', parameter2: 'FH', value2: '', parameter3: '', value3: '' })
                }
              });
              console.log("newAssetDetailsData", this.newAssetDetailsData)
              this.newAssetDetailsData.sort((a, b) => a.type.localeCompare(b.type))

              this.newAssetDetailsSource = new MatTableDataSource(this.newAssetDetailsData);
              this.newAssetDetailsSource.paginator = this.paginator;

            })

          setTimeout(() => {
            this.assetMngtDetailsForm = this.fb.group({
              assetMngtDetails: this.fb.array([])
            })
            this.setAssetMngtDetailsForm();
            this.assetMngtDetailsForm.get('assetMngtDetails').valueChanges.subscribe(assetMngtDetails => {
              this.updatedAssetDetailsData = assetMngtDetails
            });

            this.showModelDetails = true
          }, 2000);

        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("New AssetID: " + this.topAssetId + " creation failed!", snConfig);
        }
      });
  }

  onViewAssetConfig(index) {
    this.selectedMSNDetails = {}
    this.assetConfigDialogRef = this.dialog.open(AssetConfigDialogComponent, {
      data: {},
      disableClose: true
    });

    this.assetConfigDialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.selectedMSNDetails = result

      const control = this.assetMngtDetailsForm.get('assetMngtDetails')['controls'][index].controls

      if (this.selectedMSNDetails != '') {

        if (this.newAssetDetailsData[index].type == 'APU') {
          this.newAssetDetailsData[index].parameter1 = 'APU Cycles'
          this.newAssetDetailsData[index].parameter2 = 'APU Hours'

          control.parameter1.setValue('APU Cycles')
          control.parameter2.setValue('APU Hours')
        } else {
          this.newAssetDetailsData[index].parameter1 = 'FC'
          this.newAssetDetailsData[index].parameter2 = 'FH'

          control.parameter1.setValue('FC')
          control.parameter2.setValue('FH')
        }

        this.newAssetDetailsData[index].MSN = this.selectedMSNDetails.MSN
        this.newAssetDetailsData[index].asset = this.selectedMSNDetails.asset
        this.newAssetDetailsData[index].THSN = this.selectedMSNDetails.THSN
        this.newAssetDetailsData[index].TCSN = this.selectedMSNDetails.TCSN
        this.newAssetDetailsData[index].value1 = this.selectedMSNDetails.FC
        this.newAssetDetailsData[index].value2 = this.selectedMSNDetails.FH

        control.MSN.setValue(this.selectedMSNDetails.MSN)
        control.asset.setValue(this.selectedMSNDetails.asset)
        control.THSN.setValue(this.selectedMSNDetails.THSN)
        control.TCSN.setValue(this.selectedMSNDetails.TCSN)
        control.value1.setValue(this.selectedMSNDetails.FC)
        control.value2.setValue(this.selectedMSNDetails.FH)

        control.MSN.clearValidators();
        control.MSN.updateValueAndValidity();
        control.asset.clearValidators();
        control.asset.updateValueAndValidity();
        control.THSN.clearValidators();
        control.THSN.updateValueAndValidity();
        control.TCSN.clearValidators();
        control.TCSN.updateValueAndValidity();
        control.parameter1.clearValidators();
        control.parameter1.updateValueAndValidity();
        control.value1.clearValidators();
        control.value1.updateValueAndValidity();
        control.parameter2.clearValidators();
        control.parameter2.updateValueAndValidity();
        control.value2.clearValidators();
        control.value2.updateValueAndValidity();
      }
    })
  }

  onSubmitDetails() {
    console.log("saveNewAssetDetails table: ", this.updatedAssetDetailsData, this.assetMngtDetailsForm);

    this.submitted = true;
    if (this.assetMngtDetailsForm.invalid) {
      console.log("Invalid Form: ", this.assetMngtDetailsForm);
      return;
    }

    console.log("Valid Form: ", this.assetMngtDetailsForm);
    let data = []
    for (let i = 0; i < this.updatedAssetDetailsData.length; i++) {
      var obj = {
        "TAssetID": this.topAssetId,
        "Position": this.newAssetDetailsData[i].position,
        "AstType": this.newAssetDetailsData[i].type,
        "PartNo": this.newAssetDetailsData[i].partNumber,
        "MSN": this.updatedAssetDetailsData[i].MSN,
        "AssetID": this.updatedAssetDetailsData[i].asset,
        "THSN": this.updatedAssetDetailsData[i].THSN,
        "TCSN": this.updatedAssetDetailsData[i].TCSN,
        "Value1": this.updatedAssetDetailsData[i].value1,
        "Value2": this.updatedAssetDetailsData[i].value2,
        "Value3": this.updatedAssetDetailsData[i].value3,
        "Param1": this.updatedAssetDetailsData[i].parameter1,
        "Param2": this.updatedAssetDetailsData[i].parameter2,
        "Param3": this.updatedAssetDetailsData[i].parameter3,
        "LessorID": this.assetMngtForm.value.lessor,
        "Operator": this.assetMngtForm.value.operator,
        "CreateBy": this.currentUser.userName
      }
      data.push(obj)
    }
    console.log("new asset details data: ", data);

    this.cd.createAssetDetails(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.isDetailsSaved = true
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("New details of AssetID: " + this.topAssetId + " created successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("New details of AssetID: " + this.topAssetId + " creation failed!", snConfig);
        }
      });
  }

}


