import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { FormControl, FormArray } from '@angular/forms';
import { TableFilterService } from 'src/app/services/table-filter.service';
import { GetDetailService } from 'src/app/services/getDetails.service';

@Component({
  selector: 'app-conf-mngt',
  templateUrl: './conf-mngt.component.html',
  styleUrls: ['./conf-mngt.component.scss']
})
export class ConfMngtComponent implements OnInit {

  currentUser: any;
  canCreateNew: boolean = true;
  col1Clicked: boolean = false;
  col2Clicked: boolean = false;
  col3Clicked: boolean = false;
  col4Clicked: boolean = false;

  sortedRes: any = [];

  confMngtData: any = [];
  UpdatedconfMngtData: FormArray;
  displayedColumns: any[] = ['manufacturer', 'modelNumber', 'type', 'status'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('cmSort', { static: true, read: MatSort }) cmSort: MatSort;

  manufacturerFilter = new FormControl("");
  modelNumberFilter = new FormControl("");
  typeFilter = new FormControl("");
  statusFilter = new FormControl("");

  constructor(
    private tableFilter: TableFilterService,
    private router: Router,
    private gd: GetDetailService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.createModeAccess()
    this.getContent();

    this.manufacturerFilter.valueChanges.subscribe(manufacturer => {
      this.tableFilter.filterValues.Mfg = manufacturer;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.modelNumberFilter.valueChanges.subscribe(modelNumber => {
      this.tableFilter.filterValues.ModelID = modelNumber;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.typeFilter.valueChanges.subscribe(type => {
      this.tableFilter.filterValues.Type = type;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.statusFilter.valueChanges.subscribe(status => {
      this.tableFilter.filterValues.Status = status;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
  }

  createModeAccess() {
    this.gd.getUserAccess(this.currentUser.role)
      .subscribe(res => {
        console.log("user access :", res);
        if (res.configurationManagement == "E") {
          this.canCreateNew = false
        } else { this.canCreateNew = true }
      });
  }

  getContent() {

    if (this.currentUser.role == "Admin") {
      this.gd.getAllModels()
        .subscribe(res => {
          console.log("all models :", res);
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }
          this.dataSource = new MatTableDataSource(this.sortedRes);
          this.dataSource.sort = this.cmSort;
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = this.tableFilter.createConfFilter();

        });
    } else if (this.currentUser.role == "Lessor Manager" || this.currentUser.role == "Lessor Accountant") {
      this.gd.getAllModelsLessor(this.currentUser.orgName)
        .subscribe(res => {
          console.log("all lessor models :", res);
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }
          this.dataSource = new MatTableDataSource(this.sortedRes);
          this.dataSource.sort = this.cmSort;
          this.dataSource.paginator = this.paginator;
          this.dataSource.filterPredicate = this.tableFilter.createConfFilter();

        });
    }

  }

  clear(dataFilter) {
    if (dataFilter == 'manufacturerFilter') {
      this.manufacturerFilter.setValue('')
    } else if (dataFilter == 'modelNumberFilter') {
      this.modelNumberFilter.setValue('')
    } else if (dataFilter == 'typeFilter') {
      this.typeFilter.setValue('')
    } else if (dataFilter == 'statusFilter') {
      this.statusFilter.setValue('')
    }
  }

  onRedirectToConfDetails(modelNumber, row) {
    this.router.navigateByUrl('/conf-mngt/' + modelNumber)
  }

  createConf(val1, val2) {
    this.router.navigateByUrl('/conf-mngt/' + val1 + '/' + val2)
  }

}
