import { Component, OnInit } from '@angular/core';
import * as pluginLabels from 'chartjs-plugin-labels';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  public chartColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(93, 127, 225, 0.2)',
        'rgba(192, 159, 67, 0.2)',
        'rgba(94, 192, 145, 0.2)',
      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(93, 127, 225, 1)',
        'rgba(192, 159, 67, 1)',
        'rgba(94, 192, 145, 1)',
      ],
      borderWidth: 2,
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  public aircraftAgeChartData: Array<any> = [
    { data: [27.8, 11.1, 22.2, 22.2, 5.6, 11.1, 16.7, 11.1], label: '% of Portfolio' }
  ];
  public aircraftAgeChartLabels: Array<any> = ['0-2', '2-4', '4-6', '6-8', '8-10', '10-12', '12-14', '14-16'];
  public aircraftAgeChartPlugins = [pluginLabels];
  public aircraftAgeChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'Lessor Portfolio (by Aircraft Age)',
      padding: 20
    },
    legend: {
      display: false
    },
    plugins: {
      labels: {
        render: function (args) {
          let max = 100.0; //Custom maximum value
          return (args.value * 100 / max) + '%';
        },
        precision: 2,
        showZero: true,
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          min: 0.0,
          max: 45.0
        },
        scaleLabel: {
          display: true,
          labelString: '% of Portfolio',
          padding: 10
        }
      }],
      xAxes: [{
        barPercentage: 0.4,
        scaleLabel: {
          display: true,
          labelString: 'Aircraft Age (years)',
          padding: 10
        },
        gridLines: {
          display: false
        }
      }]
    }
  };

  public remngLeaseChartData: Array<any> = [
    { data: [18.5, 25.9, 14.8, 3.7, 14.8, 18.5, 3.7, 0.0], label: '% of Portfolio' }
  ];
  public remngLeaseChartLabels: Array<any> = ['0-1', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '7-8'];
  public remngLeaseChartPlugins = [pluginLabels];
  public remngLeaseChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'Lessor Portfolio (by Remaining Lease Term)',
      padding: 20
    },
    legend: {
      display: false
    },
    plugins: {
      labels: {
        render: function (args) {
          let max = 100.0; //Custom maximum value
          return (args.value * 100 / max) + '%';
        },
        precision: 2,
        showZero: true,
      }
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          min: 0.0,
          max: 45.0,
        },
        scaleLabel: {
          display: true,
          labelString: '% of Portfolio',
          padding: 10
        }
      }],
      xAxes: [{
        barPercentage: 0.4,
        scaleLabel: {
          display: true,
          labelString: 'Remaining Lease Term (years)',
          padding: 10
        },
        gridLines: {
          display: false
        }
      }]
    }
  };

  public assetTypeChartData: Array<any> = [
    { data: [22.2, 8.8, 4.5, 6.9, 20.5, 4.6, 9.5, 7.9, 15.1], label: '% of Portfolio' }
  ];
  public assetTypeChartLabels: Array<any> = ['A320-300', 'A321-200', 'A330-300', 'A350-900', 'B737-800', 'B737-900ER', 'B787-8', 'B787-9', 'B777-300ER'];
  public assetTypeChartPlugins = [pluginLabels];
  public assetTypeChartOptions: any = {
    responsive: true,
    title: {
      display: true,
      text: 'Lessor Portfolio (by Asset Type)',
      padding: 20
    },
    legend: { position: "right" },
    plugins: {
      labels: {
        render: 'percentage',
        precision: 2,
        position: 'outside',
      }
    },
  };

  public leaseRentsChartData: Array<any> = [
    { data: [19.4, 8.8, 2.5, 8.4, 15.0, 5.1, 10.2, 11.2, 19.4], label: '% of Portfolio' }
  ];
  public leaseRentsChartLabels: Array<any> = ['A320-300', 'A321-200', 'A330-300', 'A350-900', 'B737-800', 'B737-900ER', 'B787-8', 'B787-9', 'B777-300ER'];
  public leaseRentsChartPlugins = [pluginLabels];
  public leaseRentsChartOptions: any = {
    responsive: true,
    title: {
      display: true,
      text: 'Lessor Portfolio (by NVP of Lease Rents)',
      padding: 20
    },
    legend: { position: "right" },
    plugins: {
      labels: {
        render: 'percentage',
        precision: 2,
        position: 'outside',
      }
    },
  };

  public marketTypeChartData: Array<any> = [
    { data: [53.3, 43.3, 3.3], label: '% of Portfolio' }
  ];
  public marketTypeChartLabels: Array<any> = ['Developed', 'Emerging', 'Other'];
  public marketTypeChartPlugins = [pluginLabels];
  public marketTypeChartOptions: any = {
    responsive: true,
    title: {
      display: true,
      text: 'Lessor Portfolio (by Market Type)',
      padding: 20
    },
    legend: { position: "right" },
    plugins: {
      labels: {
        render: 'percentage',
        precision: 2,
        position: 'outside',
      }
    },
  };

  public regionChartData: Array<any> = [
    { data: [23.3, 26.7, 50.0], label: '% of Portfolio' }
  ];
  public regionChartLabels: Array<any> = ['Americas', 'APAC', 'EMEA'];
  public regionChartPlugins = [pluginLabels];
  public regionChartOptions: any = {
    responsive: true,
    title: {
      display: true,
      text: 'Lessor Portfolio (by Region)',
      padding: 20
    },
    legend: { position: "right" },
    plugins: {
      labels: {
        render: 'percentage',
        precision: 2,
        position: 'outside',
      }
    },
  };

}
