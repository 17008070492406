<div class="report">
  <div class="fixed">
    <h4><strong> Reports </strong></h4>
    <mat-divider class="mb-3"></mat-divider>
  </div>

  <div class="report-table mt-3 mb-5 table-responsive">
    <table mat-table [dataSource]="searchDataSource">
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field floatLabel="never">
            <input matInput [matDatepicker]="startDate" (click)="startDate.open()" [(value)]="element.startDate"
              [(ngModel)]="element.startDate" placeholder="Start Date">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
            <mat-hint align="end">Click the Date picker icon</mat-hint>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field floatLabel="never">
            <input matInput [matDatepicker]="endDate" (click)="endDate.open()" [min]="element.startDate"
              [(value)]="element.endDate" [(ngModel)]="element.endDate" placeholder="End Date">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
            <mat-hint align="end">Click the Date picker icon</mat-hint>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="report">
        <th mat-header-cell *matHeaderCellDef> Report </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field floatLabel="never">
            <mat-select placeholder="Report" [value]="element.report" [(ngModel)]="element.report">
              <mat-option *ngFor="let opt of reportOpts" [value]="opt.viewValue">
                {{ opt.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <button mat-button class="primary-btn" (click)="onGenerate()">
            <mat-icon>grading</mat-icon> Generate
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div class="report-table mt-3" [hidden]="showLessorUtil == false">
    <div class="table-responsive">
      <table mat-table [dataSource]="lessorUtilReportDataSource">
        <ng-container matColumnDef="asset" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Asset ID</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.AssetID }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="MSN">
          <th mat-header-cell *matHeaderCellDef>MSN</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="crntUtilCycles" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Lessor Portfolio Current Utilization N Cycles</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
            {{ element?.LessorCurrentUtilCycles }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="avgUtilCycles">
          <th mat-header-cell *matHeaderCellDef>Lessor Portfolio Avg Utilization N Cycles</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorAvgUtilCycles }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="varCycles">
          <th mat-header-cell *matHeaderCellDef>Var %</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.VarCycles }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="TCSN">
          <th mat-header-cell *matHeaderCellDef>Lessor TCSN</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorTCSN }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="totalLTMUtilCycles">
          <th mat-header-cell *matHeaderCellDef>Lessor Portfolio Total LTM Utilization N Cycles</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
            {{ element?.LessorTotalLTMUtilCycles }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="avgLTMUtilCycles">
          <th mat-header-cell *matHeaderCellDef>Lessor Portfolio Avg LTM Utilization N Cycles</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
            {{ element?.LessorAvgLTMUtilCycles }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="varLTMCycles" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Var %</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.VarLTMCycles }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="crntUtilHours" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Lessor Current Utilization N Hours</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorCurrentHours }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="avgUtilHours">
          <th mat-header-cell *matHeaderCellDef>Lessor Portfolio Avg Utilization N Hours</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorAvgUtilHours }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="varHours">
          <th mat-header-cell *matHeaderCellDef>Var %</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.VarHours }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="THSN">
          <th mat-header-cell *matHeaderCellDef>Lessor THSN</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorTHSN }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="totalLTMUtilHours">
          <th mat-header-cell *matHeaderCellDef>Lessor Portfolio Total LTM Utilization N Hours</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
            {{ element?.LessorTotalLTMUtilHours }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="avgLTMUtilHours">
          <th mat-header-cell *matHeaderCellDef>Lessor Portfolio Avg LTM Utilization N Hours</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
            {{ element?.LessorAvgLTMUtilHours }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="varLTMHours">
          <th mat-header-cell *matHeaderCellDef>Var %</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.VarLTMHours }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedLessorUtilColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedLessorUtilColumns;"></tr>
      </table>
    </div>

    <div *ngIf="lessorUtilSortedRes.length === 0">
      <mat-toolbar-row class="toolbar-row">
        <span class="noRecords">No records found!</span>
      </mat-toolbar-row>
    </div>
  </div>


  <div class="report-table mt-3" [hidden]="showLesseeUtil == false">
    <div class="table-responsive">
      <table mat-table [dataSource]="lesseeUtilReportDataSource">
        <ng-container matColumnDef="asset" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Asset ID</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.AssetID }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="MSN">
          <th mat-header-cell *matHeaderCellDef>MSN</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="currentFC" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Lessee Current FC</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LesseeCurrentFC }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="ltmFC">
          <th mat-header-cell *matHeaderCellDef>Lessee LTM FC</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LesseeLtmFC }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="avgLtmFC">
          <th mat-header-cell *matHeaderCellDef>Lessee Avg LTM FC</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LesseeAvgLtmFC }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="varFC">
          <th mat-header-cell *matHeaderCellDef>Var %</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.VarFC }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="currentFH">
          <th mat-header-cell *matHeaderCellDef>Lessee Current FH</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LesseeCurrentFH }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="ltmFH">
          <th mat-header-cell *matHeaderCellDef>Lessee LTM FH</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LesseeLtmFH }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="avgLtmFH" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Lessee Avg LTM FH</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LesseeAvgLtmFH }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="varFH" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Var %</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.VarFH }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedLesseeUtilColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedLesseeUtilColumns;"></tr>
      </table>
    </div>

    <div *ngIf="lesseeUtilSortedRes.length === 0">
      <mat-toolbar-row class="toolbar-row">
        <span class="noRecords">No records found!</span>
      </mat-toolbar-row>
    </div>
  </div>


  <div class="report-table mt-3" [hidden]="showLessorMntnc == false">
    <div class="table-responsive">
      <table mat-table [dataSource]="lessorMntncReportDataSource">
        <ng-container matColumnDef="asset" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Asset ID</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.AssetID }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="MSN">
          <th mat-header-cell *matHeaderCellDef>MSN</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="crntMRBill" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Lessor Current MR Bill</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorCurrentMRBill }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="avgMRBill">
          <th mat-header-cell *matHeaderCellDef>Lessor Port Avg MR Bill</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorAvgMrBill }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="varMRBill">
          <th mat-header-cell *matHeaderCellDef>Var %</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.VarMRBill }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="ltmMRBill">
          <th mat-header-cell *matHeaderCellDef>Lessor LTM MR Bill</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorLtmMRBill }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="avgLtmMRBill">
          <th mat-header-cell *matHeaderCellDef>Lessor Port Avg LTM MR Bill</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorAvgLtmMrBill }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="varLtmMRBill">
          <th mat-header-cell *matHeaderCellDef>Var %</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.VarLtmMRBill }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="acMRBalance" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>A/C MR Balance</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.AcMrBal }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="avgMRBalance" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Lessor Port Avg MR Balance</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorAvgMrBal }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="varBalance">
          <th mat-header-cell *matHeaderCellDef>Var %</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.VarMRBal }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedLessorMntncColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedLessorMntncColumns;"></tr>
      </table>
    </div>

    <div *ngIf="lessorMntncSortedRes.length === 0">
      <mat-toolbar-row class="toolbar-row">
        <span class="noRecords">No records found!</span>
      </mat-toolbar-row>
    </div>
  </div>


  <div class="report-table mt-3" [hidden]="showLesseeMntnc == false">
    <div class="table-responsive">
      <table mat-table [dataSource]="lesseeMntncReportDataSource">
        <ng-container matColumnDef="asset" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Asset ID</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.AssetID }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="MSN">
          <th mat-header-cell *matHeaderCellDef>MSN</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="currentMRBill" floatLabel="never">
          <th mat-header-cell *matHeaderCellDef>Lessee Current MR Bill</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LesseeCurrentMRBill }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="ltmAvgMRBill">
          <th mat-header-cell *matHeaderCellDef>Lessee LTM Avg MR Bill</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LesseeAvgMrBill }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="var">
          <th mat-header-cell *matHeaderCellDef>Var %</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.VarMRBill }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="acMRBalance">
          <th mat-header-cell *matHeaderCellDef>A/C MR Balance</th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.AcMrBal }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedLesseeMntncColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedLesseeMntncColumns;"></tr>
      </table>
    </div>

    <div *ngIf="lesseeMntncSortedRes.length === 0">
      <mat-toolbar-row class="toolbar-row">
        <span class="noRecords">No records found!</span>
      </mat-toolbar-row>
    </div>
  </div>

  <mat-toolbar-row [hidden]="showPaginator == false" class="toolbar-row">
    <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </mat-toolbar-row>

  <div class="float-right mt-4" *ngIf="showPaginator == true">
    <button mat-button class="save-btn" (click)="onExport(reportData, fileName)">
      <mat-icon>download</mat-icon> Download
    </button>
  </div>
</div>
