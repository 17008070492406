<div class="asset-config-dialog">
  <div mat-dialog-title class="dialog-title">
    <h4> Asset Configuration Install/Remove </h4>
    <div class="dialog-close-button">
      <button type="button" matTooltip="close" mat-icon-button mat-dialog-close>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <div class="assetConfig-table mt-3 mb-3 table-responsive">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Asset Type </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.type }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="asset">
          <th mat-header-cell *matHeaderCellDef> Asset </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.asset }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="MSN">
          <th mat-header-cell *matHeaderCellDef> MSN </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="installedAirframe">
          <th mat-header-cell *matHeaderCellDef> Installed on Airframe </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.installedAirframe }}
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="THSN">
          <th mat-header-cell *matHeaderCellDef> THSN </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.THSN }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="TCSN">
          <th mat-header-cell *matHeaderCellDef> TCSN </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.TCSN }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="FC">
          <th mat-header-cell *matHeaderCellDef> FC </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.FC }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="FH">
          <th mat-header-cell *matHeaderCellDef> FH </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.FH }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onSelect(row)"></tr>
      </table>
    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
  </mat-dialog-actions>
</div>
