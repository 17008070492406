<div class="home-content">
  <h4><strong>KPI Reports</strong></h4>
  <mat-divider></mat-divider>
  <div class="row mt-4">
    <div class="aircraft-util-chart col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart" *ngIf="aircraftUtilChartData">
        <canvas baseChart [chartType]="'bar'" [datasets]="aircraftUtilChartData" [labels]="aircraftUtilChartLabels"
          [colors]="aircraftUtilChartColors" [options]="aircraftUtilChartOptions" [legend]="true">
        </canvas>
      </div>
    </div>
    <div class="avg-mntnc-chart col-sm-12 col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart" *ngIf="avgMntncChartData">
        <canvas baseChart [chartType]="'bar'" [datasets]="avgMntncChartData" [labels]="avgMntncChartLabels"
          [colors]="avgMntncChartColors" [options]="avgMntncChartOptions" [legend]="true">
        </canvas>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="invoice-status-chart col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart pb-2" *ngIf="invoiceStatusChartData" style="height: auto;">
        <canvas baseChart [chartType]="'pie'" [datasets]="invoiceStatusChartData" [labels]="invoiceStatusChartLabels"
          [colors]="pieChartColors" [options]="invoiceStatusChartOptions" [legend]="true">
        </canvas>
      </div>
    </div>
    <div class="reserve-invoice-chart col-sm-12 col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart pb-2" *ngIf="reserveInvoiceChartData" style="height: auto;">
        <canvas baseChart [chartType]="'pie'" [datasets]="reserveInvoiceChartData" [labels]="reserveInvoiceChartLabels"
          [colors]="pieChartColors" [options]="reserveInvoiceChartOptions" [legend]="true">
        </canvas>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="report-status-chart col-md-12 col-lg-6 mb-3">
      <div class="canvas-chart pb-2" *ngIf="reportStatusChartData" style="height: auto;">
        <canvas baseChart [chartType]="'pie'" [datasets]="reportStatusChartData" [labels]="reportStatusChartLabels"
          [colors]="pieChartColors" [options]="reportStatusChartOptions" [legend]="true">
        </canvas>
      </div>
    </div>
  </div>

</div>
