<div class="home-content">
  <h4><strong>DASHBOARD</strong></h4>
  <mat-divider></mat-divider>

  <div class="row m-4">
    <div class="col-md-12 col-lg-4 mb-3 mt-3" [routerLink]="['/conf-mngt']">
      <mat-card class="card" style="background: bisque;">
        <img src="../../../../assets/images/configuration-icon-13.jpg" alt="Configuration" height="120" width="120"
          style="margin-left: 32%;" />
        <p class="card-title">Configuration Management</p>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-4 mb-3 mt-3" [routerLink]="['/asset-mngt']">
      <mat-card class="card" style="background: lightgreen;">
        <img src="../../../../assets/images/noun_Airplane_3405650.png" alt="Asset" height="100" width="120"
          style="margin-left: 32%;margin-top: 3%;" />
        <p class="card-title" style="margin-top: 4%;">Asset Management</p>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-4 mb-3 mt-3" [routerLink]="['/org-master']">
      <mat-card class="card" style="background: khaki;">
        <img src="../../../../assets/images/noun_organization_2864652.png" alt="Organization" height="120" width="120"
          style="margin-left: 32%;margin-top: 2%;" />
        <p class="card-title">Organization Master</p>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-4 mb-3 mt-3" [routerLink]="['/user-mngt']">
      <mat-card class="card" style="background: cadetblue;">
        <img src="../../../../assets/images/noun_Settings_2894229.png" alt="User" height="120" width="120"
          style="margin-left: 32%;" />
        <p class="card-title">User Management</p>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-4 mb-3 mt-3" [routerLink]="['/contract-mngt']">
      <mat-card class="card" style="background: darksalmon;">
        <img src="../../../../assets/images/noun_agreement_3562652.png" alt="Contract" height="120" width="120"
          style="margin-left: 32%;" />
        <p class="card-title">Contract Management</p>
      </mat-card>
    </div>
    <div class="col-md-12 col-lg-4 mb-3 mt-3">
      <mat-card class="card" style="background: lightsteelblue;" [routerLink]="['/invoices']">
        <img src="../../../../assets/images/noun_invoice_2206493.png" alt="Invoice" height="120" width="120"
          style="margin-left: 32%;" />
        <p class="card-title">Invoices</p>
      </mat-card>
    </div>
  </div>

</div>
