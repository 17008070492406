<div class="contractMngt">
  <div class="fixed">
    <h4><strong> Contract Management </strong>
      <small class="float-right m-3">* Click on the table rows to view the details </small>
    </h4>
  </div>
  <mat-divider></mat-divider>

  <div class="contractMngt-table mt-3 table-responsive">
    <table mat-table matSort #cmSort [dataSource]="dataSource">
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col1Clicked == false">Model Number
            <button type="button" mat-button matTooltip='Click to search' (click)="col1Clicked = !col1Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col1Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Model Number" [formControl]="modelNumberFilter">
              <button type="button" mat-button (click)="col1Clicked = !col1Clicked; clear('modelNumberFilter')"
                matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.ModelID }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="MSN">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col2Clicked == false">MSN
            <button type="button" mat-button matTooltip='Click to search' (click)="col2Clicked = !col2Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col2Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="MSN" [formControl]="MSNFilter">
              <button type="button" mat-button (click)="col2Clicked = !col2Clicked; clear('MSNFilter')" matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="lessor">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col3Clicked == false">Lessor
            <button type="button" mat-button matTooltip='Click to search' (click)="col3Clicked = !col3Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col3Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Lessor" [formControl]="lessorFilter">
              <button type="button" mat-button (click)="col3Clicked = !col3Clicked; clear('lessorFilter')" matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.LessorID }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col4Clicked == false">Operator
            <button type="button" mat-button matTooltip='Click to search' (click)="col4Clicked = !col4Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col4Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Operator" [formControl]="operatorFilter">
              <button type="button" mat-button (click)="col4Clicked = !col4Clicked; clear('operatorFilter')" matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.Operator }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="registration">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col5Clicked == false">Registration
            <button type="button" mat-button matTooltip='Click to search' (click)="col5Clicked = !col5Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col5Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Registration" [formControl]="registrationFilter">
              <button type="button" mat-button (click)="col5Clicked = !col5Clicked; clear('registrationFilter')"
                matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.RegistNo }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="contract">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col6Clicked == false">Contract#
            <button type="button" mat-button matTooltip='Click to search' (click)="col6Clicked = !col6Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col6Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Contract#" [formControl]="contractFilter">
              <button type="button" mat-button (click)="col6Clicked = !col6Clicked; clear('contractFilter')" matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.ContNo }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
          {{ ((element?.StartDt)*1000) | date: 'MM/dd/yyyy' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
          {{ ((element?.EndDt)*1000) | date: 'MM/dd/yyyy' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col7Clicked == false">Status
            <button type="button" mat-button matTooltip='Click to search' (click)="col7Clicked = !col7Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col7Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Status" [formControl]="statusFilter">
              <button type="button" mat-button (click)="col7Clicked = !col7Clicked; clear('statusFilter')" matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.Status }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="onRedirectToContractDetails(row?.ContNo,row)"></tr>
    </table>

    <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
      <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>

  </div>

  <div *ngIf="sortedRes.length === 0">
    <mat-toolbar-row class="toolbar-row">
      <span class="noRecords">No records found!</span>
    </mat-toolbar-row>
  </div>

  <div class="table-actions col-sm-12 m-3">
    <div class="float-right">
      <button type="button" mat-button class="primary-btn mr-2" (click)="createContract('create','new')">
        <mat-icon>create</mat-icon> Create New
      </button>
    </div>
  </div>

</div>
