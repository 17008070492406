import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { MatTable } from '@angular/material/table'
import { DataService, GetDetailService } from 'src/app/services/getDetails.service';
import { CreateDetails } from 'src/app/services/createDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-asset-mngt-utilization',
  templateUrl: './asset-mngt-utilization.component.html',
  styleUrls: ['./asset-mngt-utilization.component.scss']
})
export class AssetMngtUtilizationComponent implements OnInit {

  @Input() startDt: string;
  @Input() endDt: string;

  constructor(
    private route: ActivatedRoute,
    private alert: MatSnackBar,
    private gd: GetDetailService,
    private cd: CreateDetails,
    private snotService: SnotifyService,
    private ds: DataService,) { }

  currentUser: any;
  id: any;
  noChangeYet: boolean = true;
  hideBtn: boolean = false;

  pickListValues = new PickList();

  topAssetId: any;
  assetDetailsAllData: any = [];
  assetDetailsData: any = [];

  sortedRes: any = [];

  UpdatedassetDetailsData: FormArray;
  displayedDetailsColumns: any[] = ['position', 'type', 'partNumber', 'MSN', 'asset', 'parameter1', 'value1', 'parameter2', 'value2', 'parameter3', 'value3'];
  dataDetailsSource: MatTableDataSource<any>;

  dataValues: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.id = this.route.snapshot.params.id

    this.getContent()
  }

  getContent() {

    this.gd.getAssetDetails(this.id)
      .subscribe(res => {
        console.log("model details  :", res);
        this.topAssetId = res[0].TAssetID
        this.assetDetailsAllData = res
        this.assetDetailsData = []
        this.sortedRes = []

        res.forEach(el => {
          this.assetDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, MSN: el.MSN, asset: el.AssetID, value1: '', parameter1: el.Param1, value2: '', parameter2: el.Param2, value3: '', parameter3: el.Param3, SpecID: el.SpecID })
        });
        if (res != []) {
          this.sortedRes = this.assetDetailsData.sort((a, b) => a.type.localeCompare(b.type))
        }

        this.dataDetailsSource = new MatTableDataSource(this.sortedRes);
        this.dataDetailsSource.paginator = this.paginator;

        const assetDetailsArray = this.sortedRes.map(data => {
          return new FormGroup({
            value1: new FormControl(data.value1, Validators.required),
            value2: new FormControl(data.value2, Validators.required),
            value3: new FormControl(data.value3, Validators.required),
          }, { updateOn: "blur" });
        });

        this.UpdatedassetDetailsData = new FormArray(assetDetailsArray);
      });

    this.ds.currentData.subscribe(res => {
      console.log("current data  :", res);
      this.dataValues = res
    });
  }

  updateDetailsField(index, field) {
    const control = this.getDetailsControl(index, field);
    if (control.valid) {
      this.assetDetailsData = this.assetDetailsData.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value
          }
        }
        return e;
      });
      this.onChangeDataDetailsSource(this.assetDetailsData);
    }

  }

  getDetailsControl(index, fieldName) {
    return this.UpdatedassetDetailsData.at(index).get(fieldName) as FormControl;
  }

  onChangeDataDetailsSource(updatedValue) {
    this.dataDetailsSource = new MatTableDataSource(updatedValue);
    this.dataDetailsSource.paginator = this.paginator;
  }

  changeVal() { // triggers on change of any field(s)
    this.noChangeYet = false;
  }

  onSubmitDetails() {
    console.log("Updated Data on table: ", this.assetDetailsData, this.UpdatedassetDetailsData, this.dataValues);

    if ((this.startDt == '' && this.endDt == '') || this.startDt == '' || this.endDt == '') {
      return this.alert.open("Please select Start and End dates", "OK", { duration: 4000, verticalPosition: 'top' });
    }

    let data = []
    for (let i = 0; i < this.assetDetailsData.length; i++) {
      var obj = {
        "TAssetID": this.topAssetId,
        "Position": this.assetDetailsData[i].position,
        "AstType": this.assetDetailsData[i].type,
        "PartNo": this.assetDetailsData[i].partNumber,
        "MSN": this.assetDetailsData[i].MSN,
        "AssetID": this.assetDetailsData[i].asset,
        "THSN": this.assetDetailsAllData[i].THSN,
        "TCSN": this.assetDetailsAllData[i].TCSN,
        "OpenDt": this.startDt,
        "CloseDt": this.endDt,
        "Value1": this.assetDetailsData[i].value1,
        "Param1": this.assetDetailsData[i].parameter1,
        "Value2": this.assetDetailsData[i].value2,
        "Param2": this.assetDetailsData[i].parameter2,
        "Value3": this.assetDetailsData[i].value3,
        "Param3": this.assetDetailsData[i].parameter3,
        "LessorID": this.dataValues.Lessor,
        "Operator": this.dataValues.Operator,
        "CreateBy": this.currentUser.userName
      }
      data.push(obj)
    }
    console.log("util asset details data: ", data);

    this.cd.createAssetUtil(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.noChangeYet = true;
          this.hideBtn = true;
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Utilization details of Top AssetID: " + this.topAssetId + " created successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Utilization details of Top AssetID: " + this.topAssetId + " creation failed!", snConfig);
        }
      });
  }

}
