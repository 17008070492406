<div class="contract-MSN-dialog">
  <div mat-dialog-title class="dialog-title">
    <h4> Asset Configuration Install/Remove </h4>
    <div class="dialog-close-button">
      <button type="button" matTooltip="close" mat-icon-button mat-dialog-close>
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-content>
    <div class="contractMSN-table mt-3 mb-3 table-responsive">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="lessor">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="col1Clicked == false">Lessor
              <button type="button" mat-button matTooltip='Click to search' (click)="col1Clicked = !col1Clicked">
                <mat-icon matSuffix class="search">search</mat-icon>
              </button>
            </span>
            <div *ngIf="col1Clicked == true" class="filter">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Lessor" [formControl]="lessorFilter">
                <button type="button" mat-button (click)="col1Clicked = !col1Clicked; clear('lessorFilter')" matSuffix>
                  <mat-icon matSuffix class="search">clear</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.lessor }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="col2Clicked == false">Operator
              <button type="button" mat-button matTooltip='Click to search' (click)="col2Clicked = !col2Clicked">
                <mat-icon matSuffix class="search">search</mat-icon>
              </button>
            </span>
            <div *ngIf="col2Clicked == true" class="filter">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Operator" [formControl]="operatorFilter">
                <button type="button" mat-button (click)="col2Clicked = !col2Clicked; clear('operatorFilter')"
                  matSuffix>
                  <mat-icon matSuffix class="search">clear</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.operator }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="modelNumb">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="col3Clicked == false">Model Number
              <button type="button" mat-button matTooltip='Click to search' (click)="col3Clicked = !col3Clicked">
                <mat-icon matSuffix class="search">search</mat-icon>
              </button>
            </span>
            <div *ngIf="col3Clicked == true" class="filter">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Model Number" [formControl]="modelNumbFilter">
                <button type="button" mat-button (click)="col3Clicked = !col3Clicked; clear('modelNumbFilter')"
                  matSuffix>
                  <mat-icon matSuffix class="search">clear</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.modelNumb }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="MSN">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="col4Clicked == false">MSN
              <button type="button" mat-button matTooltip='Click to search' (click)="col4Clicked = !col4Clicked">
                <mat-icon matSuffix class="search">search</mat-icon>
              </button>
            </span>
            <div *ngIf="col4Clicked == true" class="filter">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="MSN" [formControl]="MSNFilter">
                <button type="button" mat-button (click)="col4Clicked = !col4Clicked; clear('MSNFilter')" matSuffix>
                  <mat-icon matSuffix class="search">clear</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="registration">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="col5Clicked == false">Registration
              <button type="button" mat-button matTooltip='Click to search' (click)="col5Clicked = !col5Clicked">
                <mat-icon matSuffix class="search">search</mat-icon>
              </button>
            </span>
            <div *ngIf="col5Clicked == true" class="filter">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Registration" [formControl]="registrationFilter">
                <button type="button" mat-button (click)="col5Clicked = !col5Clicked; clear('registrationFilter')"
                  matSuffix>
                  <mat-icon matSuffix class="search">clear</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.registration }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="assetId">
          <th mat-header-cell *matHeaderCellDef>
            <span *ngIf="col6Clicked == false">Asset
              <button type="button" mat-button matTooltip='Click to search' (click)="col6Clicked = !col6Clicked">
                <mat-icon matSuffix class="search">search</mat-icon>
              </button>
            </span>
            <div *ngIf="col6Clicked == true" class="filter">
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Asset" [formControl]="assetFilter">
                <button type="button" mat-button (click)="col6Clicked = !col6Clicked; clear('assetFilter')" matSuffix>
                  <mat-icon matSuffix class="search">clear</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.assetId }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onSelect(row)"></tr>
      </table>

      <!-- <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
        <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </mat-toolbar-row> -->

    </div>

    <div *ngIf="sortedRes.length === 0">
      <mat-toolbar-row class="toolbar-row">
        <span class="noRecords">No records found!</span>
      </mat-toolbar-row>
    </div>

  </mat-dialog-content>
  <mat-divider></mat-divider>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
  </mat-dialog-actions>
</div>
