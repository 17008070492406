<div class="confMngt">
  <div class="fixed">
    <h4><strong> Configuration Management </strong>
      <small class="float-right m-3">* Click on the table rows to view the details </small>
    </h4>
  </div>
  <mat-divider></mat-divider>

  <div class="confMngt-table mt-3 table-responsive">
    <table mat-table matSort #cmSort [dataSource]="dataSource">
      <ng-container matColumnDef="manufacturer">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col1Clicked == false">Manufacturer
            <button type="button" mat-button matTooltip='Click to search' (click)="col1Clicked = !col1Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col1Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Manufacturer" [formControl]="manufacturerFilter">
              <button type="button" mat-button (click)="col1Clicked = !col1Clicked; clear('manufacturerFilter')"
                matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.Mfg }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col2Clicked == false">Model Number
            <button type="button" mat-button matTooltip='Click to search' (click)="col2Clicked = !col2Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col2Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Model Number" [formControl]="modelNumberFilter">
              <button type="button" mat-button (click)="col2Clicked = !col2Clicked; clear('modelNumberFilter')"
                matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.ModelID }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col3Clicked == false">Type
            <button type="button" mat-button matTooltip='Click to search' (click)="col3Clicked = !col3Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col3Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Type" [formControl]="typeFilter">
              <button type="button" mat-button (click)="col3Clicked = !col3Clicked; clear('typeFilter')" matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.Type }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="col4Clicked == false">Status
            <button type="button" mat-button matTooltip='Click to search' (click)="col4Clicked = !col4Clicked">
              <mat-icon matSuffix class="search">search</mat-icon>
            </button>
          </span>
          <div *ngIf="col4Clicked == true" class="filter">
            <mat-form-field class="example-full-width">
              <input matInput placeholder="Status" [formControl]="statusFilter">
              <button type="button" mat-button (click)="col4Clicked = !col4Clicked; clear('statusFilter')" matSuffix>
                <mat-icon matSuffix class="search">clear</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.Status }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRedirectToConfDetails(row?.ModelID,row)">
      </tr>
    </table>

    <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
      <mat-paginator class="paginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>

  </div>

  <div *ngIf="sortedRes.length === 0">
    <mat-toolbar-row class="toolbar-row">
      <span class="noRecords">No records found!</span>
    </mat-toolbar-row>
  </div>

  <div class="table-actions col-sm-12 m-3">
    <div class="float-right">
      <button type="button" *ngIf='canCreateNew == true' mat-button class="primary-btn mr-2"
        (click)="createConf('create','new')">
        <mat-icon>create</mat-icon> Create New
      </button>
    </div>
  </div>

</div>
