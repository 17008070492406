<div class="contractMngt">
  <h4><strong> <a [routerLink]="['/contract-mngt']">
        <mat-icon>arrow_back</mat-icon>
      </a> Create New Contract </strong></h4>
  <mat-divider></mat-divider>

  <div class="contractMngt-table mt-3 table-responsive">
    <form [formGroup]="contractMngtForm" (ngSubmit)="onSubmit()">

      <table mat-table [dataSource]="newContractSource">
        <ng-container matColumnDef="contract">
          <th mat-header-cell *matHeaderCellDef> Contract </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ contNumb }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="lessor">
          <th mat-header-cell *matHeaderCellDef> Lessor </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="lessor" placeholder="Lessor" [value]="element.lessor">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef> Operator </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="operator" placeholder="Operator" [value]="element.operator">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="modelNumber">
          <th mat-header-cell *matHeaderCellDef> Model Number </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="modelNumber" placeholder="Model Number" [value]="element.modelNumber">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="registration">
          <th mat-header-cell *matHeaderCellDef> Registration </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input matInput formControlName="registration" placeholder="Registration" [value]="element.registration">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="MSN">
          <th mat-header-cell *matHeaderCellDef> MSN </th>
          <td mat-cell *matCellDef="let element;let i = index">
            <mat-form-field floatLabel="never">
              <input formControlName="MSN" matInput placeholder="MSN" [value]="element.MSN">
              <mat-error *ngIf="submitted || f.MSN.errors?.required || f.MSN.touched">
                Please enter the MSN
              </mat-error>
            </mat-form-field>
            <button type="button" mat-button (click)="onViewContractMSN(i)">
              <mat-icon class="search-icon">search</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef> Start Date </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input formControlName="startDate" matInput [matDatepicker]="startDate" (click)="startDate.open()"
                [(value)]="element.startDate" placeholder="Start Date">
              <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
              <mat-hint align="end">Click the Date picker icon</mat-hint>
              <mat-error *ngIf="submitted || f.startDate.errors?.required || f.startDate.touched">
                Please select the start date
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef> End Date </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <input formControlName="endDate" matInput [matDatepicker]="endDate" (click)="endDate.open()"
                [min]="f.startDate.value" [(value)]="element.endDate" placeholder="End Date">
              <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
              <mat-hint align="end">Click the Date picker icon</mat-hint>
              <mat-error *ngIf="submitted || f.endDate.errors?.required || f.endDate.touched">
                Please select the end date
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
            <mat-form-field floatLabel="never">
              <mat-select formControlName="status" placeholder="Status" [value]="element.status">
                <mat-option *ngFor="let opt of statusOpts" [value]="opt.viewValue">
                  {{ opt.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="submitted || f.status.errors?.required || f.status.touched">
                Please select the status
              </mat-error>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
            <button type="submit" mat-button class="save-btn" [disabled]="isSaved">
              <mat-icon>save</mat-icon> Save
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </form>
  </div>

  <div class="contractMngtDetails-table mt-3 table-responsive" *ngIf="showModelDetails == true">
    <form [formGroup]="contractMngtDetailsForm" (ngSubmit)="onSubmitDetails()">

      <table mat-table formArrayName="contractMngtDetails" [dataSource]="newContractDetailsSource">
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef> Position </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.position }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Asset Type </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.type }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="partNumber">
          <th mat-header-cell *matHeaderCellDef> Part Number </th>
          <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.partNumber }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="utilParam1">
          <th mat-header-cell *matHeaderCellDef> Parameter </th>
          <td mat-cell class="non-edit" id="no-wrap" *matCellDef="let element; let index = index">
            {{ element?.utilParam1 }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="utilRate1">
          <th mat-header-cell *matHeaderCellDef> Rate </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            &#36; <mat-form-field floatLabel="never">
              <input matInput formControlName="utilRate1" placeholder="Value" [value]="element.utilRate1" required>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="utilParam2">
          <th mat-header-cell *matHeaderCellDef> Parameter </th>
          <td mat-cell class="non-edit" id="no-wrap" *matCellDef="let element; let index = index">
            {{ element?.utilParam2 }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="utilRate2">
          <th mat-header-cell *matHeaderCellDef> Rate </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            &#36; <mat-form-field floatLabel="never">
              <input matInput formControlName="utilRate2" placeholder="Value" [value]="element.utilRate2" required>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="utilParam3">
          <th mat-header-cell *matHeaderCellDef> Parameter </th>
          <td mat-cell class="non-edit" id="no-wrap" *matCellDef="let element; let index = index">
            {{ element?.utilParam3 }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="utilRate3">
          <th mat-header-cell *matHeaderCellDef> Rate </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="element?.type == 'Airframe'">&#36; </span><mat-form-field *ngIf="element?.type == 'Airframe'" floatLabel="never">
              <input matInput formControlName="utilRate3" placeholder="Value" [value]="element.utilRate3" required>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="utilParam4">
          <th mat-header-cell *matHeaderCellDef> Parameter </th>
          <td mat-cell class="non-edit" id="no-wrap" *matCellDef="let element; let index = index">
            {{ element?.utilParam4 }} </td>
          <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="utilRate4">
          <th mat-header-cell *matHeaderCellDef> Rate </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="element?.type == 'Airframe'">&#36; </span><mat-form-field *ngIf="element?.type == 'Airframe'" floatLabel="never">
              <input matInput formControlName="utilRate4" placeholder="Value" [value]="element.utilRate4" required>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="rentalRate1">
          <th mat-header-cell *matHeaderCellDef> 6 Years </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="element?.type == 'Airframe'">&#36; </span><mat-form-field *ngIf="element?.type == 'Airframe'" floatLabel="never">
              <input matInput formControlName="rentalRate1" placeholder="Value" [value]="element.rentalRate1" required>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="rentalRate2">
          <th mat-header-cell *matHeaderCellDef> 8 Years </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="element?.type == 'Airframe'">&#36; </span><mat-form-field *ngIf="element?.type == 'Airframe'" floatLabel="never">
              <input matInput formControlName="rentalRate2" placeholder="Value" [value]="element.rentalRate2" required>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="rentalRate3">
          <th mat-header-cell *matHeaderCellDef> 10 Years </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="element?.type == 'Airframe'">&#36; </span><mat-form-field *ngIf="element?.type == 'Airframe'" floatLabel="never">
              <input matInput formControlName="rentalRate3" placeholder="Value" [value]="element.rentalRate3" required>
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="rentalRate4">
          <th mat-header-cell *matHeaderCellDef> 12 Years </th>
          <td mat-cell *matCellDef="let element;let row let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="element?.type == 'Airframe'">&#36; </span><mat-form-field *ngIf="element?.type == 'Airframe'" floatLabel="never">
              <input matInput formControlName="rentalRate4" placeholder="Value" [value]="element.rentalRate4" required>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="header-row-first-group">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="3" class="header-row-first-group"></th>
        </ng-container>

        <ng-container matColumnDef="header-row-second-group">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="8" class="header-row-second-group"> Utilization Based
            Rates </th>
        </ng-container>

        <ng-container matColumnDef="header-row-third-group">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="4" class="header-row-third-group"> Rental Rates </th>
        </ng-container>

        <tr mat-header-row
          *matHeaderRowDef="['header-row-first-group', 'header-row-second-group', 'header-row-third-group']"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
      </table>
      <div class="table-actions mt-2">
        <button type="submit" mat-button class="save-btn mt-2" [disabled]="isDetailsSaved">
          <mat-icon>save</mat-icon> Save
        </button>
      </div>
    </form>
  </div>
  <br><br>
</div>
