import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/models/user';
import { Role } from 'src/app/models/role';
import { filter } from 'rxjs/operators';
import { GetDetailService } from 'src/app/services/getDetails.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  constructor(public authService: AuthenticationService, private router: Router, private route: ActivatedRoute, private gd: GetDetailService) { }

  homeRoutePath: string;
  currentUser: User;

  isLoggedIn: boolean = false;
  isLogin: boolean = false;

  confMngtAccess: boolean = true;
  assetMngtAccess: boolean = true;
  orgMngtAccess: boolean = true;
  userMngtAccess: boolean = true;
  contractMngtAccess: boolean = true;
  utilAccess: boolean = true;
  billAccess: boolean = true;
  maintenanceAccess: boolean = true;

  ngOnInit() {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isLogin = event.url.includes("/login") || event.url === '/' ? true : false;
    });

    this.authService.currentUser.subscribe(user => {
      this.isLoggedIn = !!user
      if (this.isLoggedIn) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      }

      this.authService.isSidebarOpen = !!user;
      this.currentUser = user;
      console.log("role is ther or not", this.currentUser)
      if (this.currentUser) {

        if (this.currentUser.role === 'Admin') {
          this.homeRoutePath = 'admin-home';
        }
        else if (this.currentUser.role === 'Lessor Manager') {
          this.homeRoutePath = 'lessor-mng-home';
        }
        else if (this.currentUser.role === 'Lessor Accountant') {
          this.homeRoutePath = 'lessor-acc-home';
        }
        else if (this.currentUser.role === 'Lessee Manager') {
          this.homeRoutePath = 'lessee-mng-home';
        }
        else if (this.currentUser.role === 'Lessee Accountant') {
          this.homeRoutePath = 'lessee-acc-home';
        }

        this.gd.getUserAccess(this.currentUser.role)
          .subscribe(res => {
            console.log("user access :", res);

            if (res.configurationManagement == "NA") {
              this.confMngtAccess = false
            } else { this.confMngtAccess = true }
            if (res.assetManagement == "NA") {
              this.assetMngtAccess = false
            } else { this.assetMngtAccess = true }
            if (res.organizationMaster == "NA") {
              this.orgMngtAccess = false
            } else { this.orgMngtAccess = true }
            if (res.userManagement == "NA") {
              this.userMngtAccess = false
            } else { this.userMngtAccess = true }
            if (res.contractManagement == "NA") {
              this.contractMngtAccess = false
            } else { this.contractMngtAccess = true }
            if (res.utilizationReports == "NA") {
              this.utilAccess = false
            } else { this.utilAccess = true }
            if (res.assetBilling == "NA") {
              this.billAccess = false
            } else { this.billAccess = true }
            if (res.assetMaintenance == "NA") {
              this.maintenanceAccess = false
            } else { this.maintenanceAccess = true }
          });
      }
    });

  }

  isExpanded = false;
  element: HTMLElement;

  toggleActive(event: any) {
    // debugger;
    event.preventDefault();
    if (this.element !== undefined) {
      // this.element.style.backgroundColor = "white";
    }
    var target = event.currentTarget;
    // target.style.backgroundColor = "#e51282";
    this.element = target;
  }

  onLogOut() {
    this.isExpanded = false
    this.authService.logout();
    this.router.navigate(['/']);
  }

}
