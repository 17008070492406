import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  currentUser: User;
  role: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private alert: MatSnackBar,
    private authService: AuthenticationService
  ) {
    // redirect to home if already logged in
    if (this.authService.currentUserValue) {
      console.log("this.authService.currentUserValue", this.authService.currentUserValue)
      this.router.navigate(['/home']);
    }
  }

  loginForm: FormGroup;
  submitted: boolean = false;
  isLoading: boolean = false;
  returnUrl: string;

  ngOnInit() {
    this.loginForm = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    const userName = this.loginForm.value.username;
    const password = this.loginForm.value.password;

    // const role = "user";

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      console.log("Invalid form: ", this.loginForm)
      return;
    }

    if (userName !== password) {
      this.alert.open("The Username or Password entered is invalid", "OK", { duration: 4000 });
    }
    else {
      console.log("Success: ", this.loginForm);
      this.isLoading = true;
      console.log(userName);
      this.authService.login(userName)
        .pipe(first())
        .subscribe(
          res => {
            if (res) {
              console.log("successfull Login: ", res);

              //this.router.navigate([this.returnUrl]);
              this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
              this.role = this.currentUser.role;
              // get return url from route parameters or default to '/'
              if (this.role == 'Admin') {
                this.router.navigate(['/admin-home']);
                this.alert.open("Welcome " + this.currentUser.name + "!", "OK", { duration: 4000, verticalPosition: 'top', horizontalPosition: 'end' });
              }
              else if (this.role == 'Lessor Manager') {
                this.router.navigate(['/lessor-mng-home']);
                this.alert.open("Welcome " + this.currentUser.name + "!", "OK", { duration: 4000, verticalPosition: 'top', horizontalPosition: 'end' });
              }
              else if (this.role == 'Lessor Accountant') {
                this.router.navigate(['/lessor-acc-home']);
                this.alert.open("Welcome " + this.currentUser.name + "!", "OK", { duration: 4000, verticalPosition: 'top', horizontalPosition: 'end' });
              }
              else if (this.role == 'Lessee Manager') {
                this.router.navigate(['/lessee-mng-home']);
                this.alert.open("Welcome " + this.currentUser.name + "!", "OK", { duration: 4000, verticalPosition: 'top', horizontalPosition: 'end' });
              }
              else if (this.role == 'Lessee Accountant') {
                this.router.navigate(['/lessee-acc-home']);
                this.alert.open("Welcome " + this.currentUser.name + "!", "OK", { duration: 4000, verticalPosition: 'top', horizontalPosition: 'end' });
              }
            }
            else {
              this.alert.open("The Username or Password entered is invalid", "OK", { duration: 4000 });
            }
            this.isLoading = false;
          }, error => {
            console.log("Login Error: ", error);
          });
    }
  }

}
