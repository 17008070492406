import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GetDetailService } from 'src/app/services/getDetails.service';
import { TableFilterService } from 'src/app/services/table-filter.service';

@Component({
  selector: 'app-contract-msn-dialog',
  templateUrl: './contract-msn-dialog.component.html',
  styleUrls: ['./contract-msn-dialog.component.scss']
})
export class ContractMsnDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private gd: GetDetailService,
    private tableFilter: TableFilterService,
    private contractMSNDialogRef: MatDialogRef<ContractMsnDialogComponent>
  ) { }

  currentUser: any;
  col1Clicked: boolean = false;
  col2Clicked: boolean = false;
  col3Clicked: boolean = false;
  col4Clicked: boolean = false;
  col5Clicked: boolean = false;
  col6Clicked: boolean = false;
  sortedRes: any = [];

  contractMSNData: any = [];
  displayedColumns: any[] = ['lessor', 'operator', 'modelNumb', 'MSN', 'registration', 'assetId'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  lessorFilter = new FormControl("");
  operatorFilter = new FormControl("");
  modelNumbFilter = new FormControl("");
  MSNFilter = new FormControl("");
  registrationFilter = new FormControl("");
  assetFilter = new FormControl("");

  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.getContent()

    this.lessorFilter.valueChanges.subscribe(lessor => {
      this.tableFilter.filterValues.lessor = lessor;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.operatorFilter.valueChanges.subscribe(operator => {
      this.tableFilter.filterValues.Operator = operator;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.modelNumbFilter.valueChanges.subscribe(model => {
      this.tableFilter.filterValues.ModelID = model;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.MSNFilter.valueChanges.subscribe(MSN => {
      this.tableFilter.filterValues.MSN = MSN;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.registrationFilter.valueChanges.subscribe(registration => {
      this.tableFilter.filterValues.RegistNo = registration;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.assetFilter.valueChanges.subscribe(asset => {
      this.tableFilter.filterValues.AssetID = asset;
      this.dataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
  }

  getContent() {

    if (this.currentUser.role == "Admin") {
      this.gd.getAllAssets()
        .subscribe(res => {
          console.log("all assets :", res);
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }
          this.sortedRes.forEach(element => {
            this.contractMSNData.push({ lessor: element.LessorID, operator: element.Operator, MSN: element.MSN, modelNumb: element.ModelID, registration: element.RegistNo, assetId: element.TAssetID })
          });
          this.dataSource = new MatTableDataSource(this.contractMSNData);
          this.dataSource.filterPredicate = this.tableFilter.createContMSNFilter();
        });
    }
    else if (this.currentUser.role == "Lessor Manager" || this.currentUser.role == "Lessor Accountant") {
      this.gd.getContractMSN(this.currentUser.orgName)
        .subscribe(res => {
          console.log("all assets :", res);
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }
          this.sortedRes.forEach(element => {
            this.contractMSNData.push({ lessor: element.LessorID, operator: element.Operator, MSN: element.MSN, modelNumb: element.ModelID, registration: element.RegistNo, assetId: element.TAssetID })
          });
          this.dataSource = new MatTableDataSource(this.contractMSNData);
          this.dataSource.filterPredicate = this.tableFilter.createContMSNFilter();
        });
    }

  }

  clear(dataFilter) {
    if (dataFilter == 'lessorFilter') {
      this.lessorFilter.setValue('')
    } else if (dataFilter == 'operatorFilter') {
      this.operatorFilter.setValue('')
    } else if (dataFilter == 'modelNumbFilter') {
      this.modelNumbFilter.setValue('')
    } else if (dataFilter == 'MSNFilter') {
      this.MSNFilter.setValue('')
    } else if (dataFilter == 'registrationFilter') {
      this.registrationFilter.setValue('')
    } else if (dataFilter == 'assetFilter') {
      this.assetFilter.setValue('')
    }
  }

  onSelect(row) {
    console.log("row details:::", row)
    this.contractMSNDialogRef.close(row);
  }

}

