import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { User } from '../models/user';
import { environment } from 'src/environments/environment.prod';
// AMB instance
const endpoint = environment.baseUrl;

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  isSidebarOpen: boolean = true;

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  /* public validateUser(emailId,password): Observable<any>{
      return this.http.get(APIConstants.VALIDATEUSER+emailId+'/'+password);

   } */
  login(username: string): Observable<any> {
    return this.http.get<any>(endpoint + `getUserDetails/${username}`, httpOptions)
      .pipe(map(response => {
        console.log("Auth User: ", response);
        console.log("Auth User: ", response !== '');
        if (response !== '') {
          // store user details and role in session storage to keep user logged in between page refreshes
          const user = new User(response.userName, response.role, response.orgName, response.orgId, response.name)
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return this.currentUser;
        }
        return;
      }));
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

}
