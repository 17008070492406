import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { TableEditService } from 'src/app/services/table-edit.service';
import { MatTable } from '@angular/material/table'
import { GetDetailService } from 'src/app/services/getDetails.service';
import { UpdateDetails } from 'src/app/services/updateDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AssetConfigDialogComponent } from 'src/app/helpers/dialog/asset-config-dialog/asset-config-dialog.component';

@Component({
  selector: 'app-asset-mngt-configuration',
  templateUrl: './asset-mngt-configuration.component.html',
  styleUrls: ['./asset-mngt-configuration.component.scss']
})
export class AssetMngtConfigurationComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private tableEdit: TableEditService,
    private route: ActivatedRoute,
    private gd: GetDetailService,
    private ud: UpdateDetails,
    private snotService: SnotifyService) { }

  currentUser: any;
  id: any;
  noChangeYet: boolean = true;

  pickListValues = new PickList();

  topAssetId: any;
  assetDetailsData: any = [];
  specId: any = [];

  sortedRes: any = [];

  UpdatedassetDetailsData: FormArray;
  displayedDetailsColumns: any[] = ['position', 'type', 'partNumber', 'MSN', 'asset', 'THSN', 'TCSN', 'parameter1', 'value1', 'parameter2', 'value2', 'parameter3', 'value3'];
  dataDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  assetConfigDialogRef: MatDialogRef<AssetConfigDialogComponent>;
  selectedMSNDetails: any = {}

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.id = this.route.snapshot.params.id

    this.getContent()
  }

  getContent() {

    this.gd.getAssetDetails(this.id)
      .subscribe(res => {
        console.log("model details  :", res);
        this.topAssetId = res[0].TAssetID
        this.assetDetailsData = []
        this.specId = []
        this.sortedRes = []

        res.forEach(el => {
          this.assetDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, MSN: el.MSN, asset: el.AssetID, THSN: el.THSN, TCSN: el.TCSN, value1: el.Value1, parameter1: el.Param1, value2: el.Value2, parameter2: el.Param2, value3: el.Value3, parameter3: el.Param3, SpecID: el.SpecID })
        });
        if (res != []) {
          this.sortedRes = this.assetDetailsData.sort((a, b) => a.type.localeCompare(b.type))
        }
        this.sortedRes.forEach(el => {
          this.specId.push(el.SpecID)
        });

        this.dataDetailsSource = new MatTableDataSource(this.sortedRes);
        this.dataDetailsSource.paginator = this.paginator;

        const assetDetailsArray = this.sortedRes.map(data => {
          return new FormGroup({
            MSN: new FormControl(data.MSN, Validators.required),
            asset: new FormControl(data.asset, Validators.required),
            THSN: new FormControl(data.THSN, Validators.required),
            TCSN: new FormControl(data.TCSN, Validators.required),
          }, { updateOn: "blur" });
        });

        this.UpdatedassetDetailsData = new FormArray(assetDetailsArray);
      });
  }


  updateDetailsField(index, field) {
    const control = this.getDetailsControl(index, field);
    if (control.valid) {
      this.tableEdit.update(index, field, control, this.assetDetailsData);
    }

    this.dataDetailsSource = new MatTableDataSource(this.tableEdit.updatedDataSource.data);
    this.dataDetailsSource.paginator = this.paginator;
  }

  getDetailsControl(index, fieldName) {
    return this.UpdatedassetDetailsData.at(index).get(fieldName) as FormControl;
  }

  onViewAssetConfig(index, row) {
    this.assetConfigDialogRef = this.dialog.open(AssetConfigDialogComponent, {
      data: {
        type: row.type,
        asset: row.asset,
        MSN: row.MSN,
        installedAirframe: '',
        THSN: row.THSN,
        TCSN: row.TCSN,
        FC: row.value1,
        FH: row.value2,
      },
      disableClose: true
    });

    this.assetConfigDialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.selectedMSNDetails = result

      if (this.selectedMSNDetails == '') {
        // this.getContent()
      } else {

        this.noChangeYet = false;

        if (this.assetDetailsData[index].type == 'APU') {
          this.assetDetailsData[index].parameter1 = 'APU Cycles'
          this.assetDetailsData[index].parameter2 = 'APU Hours'
        } else {
          this.assetDetailsData[index].parameter1 = 'FC'
          this.assetDetailsData[index].parameter2 = 'FH'
        }

        this.assetDetailsData[index].MSN = this.selectedMSNDetails.MSN
        this.assetDetailsData[index].asset = this.selectedMSNDetails.asset
        this.assetDetailsData[index].THSN = this.selectedMSNDetails.THSN
        this.assetDetailsData[index].TCSN = this.selectedMSNDetails.TCSN
        this.assetDetailsData[index].value1 = this.selectedMSNDetails.FC
        this.assetDetailsData[index].value2 = this.selectedMSNDetails.FH

        this.dataDetailsSource = new MatTableDataSource(this.assetDetailsData);
      }

    })
  }

  onSubmitDetails() {
    console.log("Updated Data on table: ", this.assetDetailsData, this.UpdatedassetDetailsData);
    let data = []
    for (let i = 0; i < this.assetDetailsData.length; i++) {
      var obj = {
        "TAssetID": this.topAssetId,
        "SpecID": this.specId[i],
        "MSN": this.assetDetailsData[i].MSN,
        "AssetID": this.assetDetailsData[i].asset,
        "THSN": this.assetDetailsData[i].THSN,
        "TCSN": this.assetDetailsData[i].TCSN,
        "Value1": this.assetDetailsData[i].value1,
        "Param1": this.assetDetailsData[i].parameter1,
        "Value2": this.assetDetailsData[i].value2,
        "Param2": this.assetDetailsData[i].parameter2,
        "UpdatedBy": this.currentUser.userName
      }
      data.push(obj)
    }
    console.log("conf asset details data: ", data);

    this.ud.updateAssetDetails(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.noChangeYet = true;
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Configuration details of Top AssetID: " + this.topAssetId + " updated successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Configuration details of Top AssetID: " + this.topAssetId + " updation failed!", snConfig);
        }
      });
  }

}
