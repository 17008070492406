<div class="assetMngt">
  <div class="assetMngt-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataDetailsSource">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Position </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.position }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Asset Type </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.type }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="partNumber">
        <th mat-header-cell *matHeaderCellDef> Part Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.partNumber }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="MSN">
        <th mat-header-cell *matHeaderCellDef> MSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.MSN }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="asset">
        <th mat-header-cell *matHeaderCellDef> Asset </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.asset }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="parameter1">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.parameter1 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="value1">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'value1')">
            <ng-template viewMode *ngIf="element?.value1 == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{element?.value1}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'value1')" (change)="changeVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'value1').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="parameter2">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.parameter2 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="value2">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'value2')">
            <ng-template viewMode *ngIf="element?.value2 == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{element?.value2}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'value2')" (change)="changeVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'value2').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="parameter3">
        <th mat-header-cell *matHeaderCellDef> Parameter </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.parameter3 }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="value3">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'value3')">
            <ng-template viewMode *ngIf="element?.value3 == ''">
              -----
            </ng-template>
            <ng-template viewMode>
              {{element?.value3}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'value3')" (change)="changeVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'value3').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
    </table>

    <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
      <mat-paginator class="paginator" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>

  </div>

  <div *ngIf="sortedRes.length === 0">
    <mat-toolbar-row class="toolbar-row">
      <span class="noRecords">No records found!</span>
    </mat-toolbar-row>
  </div>

  <div class="table-actions col-sm-12 m-3">
    <div class="float-right">
      <button type="submit" *ngIf="hideBtn == false" mat-button class="save-btn mr-2" (click)="onSubmitDetails()"
        [disabled]="noChangeYet">
        <mat-icon>save</mat-icon> Save
      </button>
    </div>
  </div>

</div>
