import { Injectable } from '@angular/core';
import { APIConstants } from '../restapi';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UpdateDetails {

  constructor(
    private http: HttpClient
  ) { }

  public updateUser(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.put(APIConstants.UPDATEUSERDETAILS, data, { headers }));

  }

  public updateOrg(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.put(APIConstants.UPDATEORGDETAILS, data, { headers }));

  }

  public updateModel(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.put(APIConstants.UPDATEMODEL, data, { headers }));

  }

  public updateModelDetails(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.put(APIConstants.UPDATEMODELDETAILS, data, { headers }));

  }

  public updateAsset(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.put(APIConstants.UPDATEASSET, data, { headers }));

  }

  public updateAssetDetails(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.put(APIConstants.UPDATEASSETDETAILS, data, { headers }));

  }

  public updateContract(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.put(APIConstants.UPDATECONTRACT, data, { headers }));

  }

  public updateContractDetails(data): Observable<any> {

    console.log("data:" + JSON.stringify(data));
    const headers = new HttpHeaders();
    headers.set('content-type', null);
    headers.set('Accept', 'multipart/form-data');
    return (this.http.put(APIConstants.UPDATECONTRACTDETAILS, data, { headers }));

  }

}
