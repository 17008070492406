import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { MatTable } from '@angular/material/table'
import { GetDetailService } from 'src/app/services/getDetails.service';
import { UpdateDetails } from 'src/app/services/updateDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-user-mngt-details',
  templateUrl: './user-mngt-details.component.html',
  styleUrls: ['./user-mngt-details.component.scss']
})
export class UserMngtDetailsComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private gd: GetDetailService,
    private ud: UpdateDetails,
    private snotService: SnotifyService) { }

  currentUser: any;
  id: any;

  pickListValues = new PickList();
  orgOpts = this.pickListValues.organization
  roleOpts = this.pickListValues.role
  moduleOpts = this.pickListValues.module
  recordStatus = this.pickListValues.recStatus

  userMngtData: any = [];
  UpdateduserMngtData: FormArray;
  displayedColumns: any[] = ['orgName', 'role', 'name', 'status'];
  dataSource: MatTableDataSource<any>;

  modules: any = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.id = this.route.snapshot.params.id

    this.moduleForm = this.fb.group({
      checkbox1: [false],
      checkbox2: [false],
      checkbox3: [false],
      checkbox4: [false],
      checkbox5: [false],
      checkbox6: [false],
    })

    this.getContent();
  }

  getContent() {

    this.gd.getUserDetails(this.id)
      .subscribe(res => {
        console.log("all users :", res);

        this.userMngtData.push(res)

        res.module.forEach(el => {
          if (el == "Asset Management") { this.f.checkbox1.setValue(true) }
          if (el == "Configuration Management") { this.f.checkbox2.setValue(true) }
          if (el == "Contract Management") { this.f.checkbox3.setValue(true) }
          if (el == "Organization Master") { this.f.checkbox4.setValue(true) }
          if (el == "User Management") { this.f.checkbox5.setValue(true) }
          if (el == "Reports") { this.f.checkbox6.setValue(true) }
        });

        this.dataSource = new MatTableDataSource(this.userMngtData);
        this.dataSource.paginator = this.paginator;

        const userMngtArray = this.userMngtData.map(data => {
          return new FormGroup({
            name: new FormControl(data.name, Validators.required),
            status: new FormControl(data.status, Validators.required),
          }, { updateOn: "blur" });
        });

        this.UpdateduserMngtData = new FormArray(userMngtArray);

      });



  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      this.userMngtData = this.userMngtData.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value
          }
        }
        return e;
      });
      this.onChangeDataSource(this.userMngtData);
    }
  }

  getControl(index, fieldName) {
    return this.UpdateduserMngtData.at(index).get(fieldName) as FormControl;
  }

  onChangeDataSource(updatedValue) {
    this.dataSource = new MatTableDataSource(updatedValue);
    this.dataSource.paginator = this.paginator;
  }

  submitted: boolean = false;

  moduleForm: FormGroup;

  get f() { return this.moduleForm.controls; }

  onSubmit() {
    console.log("Updated Data on table: ", this.UpdateduserMngtData.value, this.moduleForm);
    this.modules = []
    if (this.f.checkbox1.value == true) { this.modules.push("Asset Management") }
    if (this.f.checkbox2.value == true) { this.modules.push("Configuration Management") }
    if (this.f.checkbox3.value == true) { this.modules.push("Contract Management") }
    if (this.f.checkbox4.value == true) { this.modules.push("Organization Master") }
    if (this.f.checkbox5.value == true) { this.modules.push("User Management") }
    if (this.f.checkbox6.value == true) { this.modules.push("Reports") }

    var data = {
      "userName": this.id,
      "role": this.userMngtData[0].role,
      "name": this.UpdateduserMngtData.value[0].name,
      "status": this.UpdateduserMngtData.value[0].status,
      "module": this.modules
    }
    console.log("new user details data: ", data);

    this.ud.updateUser(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("User details of " + this.id + " updated successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("User details of " + this.id + " failed!", snConfig);
        }
      });
  }

}

