import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { MatTable } from '@angular/material/table'
import { GetDetailService } from 'src/app/services/getDetails.service';
import { UpdateDetails } from 'src/app/services/updateDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-asset-mngt-maintenance',
  templateUrl: './asset-mngt-maintenance.component.html',
  styleUrls: ['./asset-mngt-maintenance.component.scss']
})
export class AssetMngtMaintenanceComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private gd: GetDetailService,
    private ud: UpdateDetails,
    private snotService: SnotifyService) { }

  currentUser: any;
  id: any;
  canEdit: boolean = true;
  noChangeYet: boolean = true;
  lastCmpltDt: any = [];
  nextCmpltDt: any = [];

  pickListValues = new PickList();
  checkTypeOpts = this.pickListValues.checkType

  topAssetId: any;
  assetDetailsData: any = [];
  specId: any = [];

  sortedRes: any = [];
  UpdatedassetDetailsData: FormArray;
  displayedDetailsColumns: any[] = ['position', 'type', 'partNumber', 'MSN', 'asset', 'performedCheckType', 'performedCmpltDate', 'performedParam1', 'performedValue1', 'performedParam2', 'performedValue2', 'dueCheckType', 'dueCmpltDate', 'dueParam1', 'dueValue1', 'dueParam2', 'dueValue2'];
  dataDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.id = this.route.snapshot.params.id

    this.editModeAccess();

    this.getContent()
  }

  editModeAccess() {
    this.gd.getUserAccess(this.currentUser.role)
      .subscribe(res => {
        console.log("user access :", res);
        if (res.assetMaintenance == "NA") {
          this.canEdit = false
        } else { this.canEdit = true }
      });
  }

  getContent() {

    this.gd.getAssetDetails(this.id)
      .subscribe(res => {
        console.log("maintenance details  :", res);
        this.topAssetId = res[0].TAssetID
        this.assetDetailsData = []
        this.specId = []
        this.sortedRes = []

        res.forEach(el => {
          // remove if condition once done in backend
          if (el.LChkTyp == undefined) {
            this.assetDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, MSN: el.MSN, asset: el.AssetID, performedCheckType: '-----', performedCmpltDate: '-----', performedValue1: '-----', performedParam1: el.Param1, performedValue2: '-----', performedParam2: el.Param2, dueCheckType: '-----', dueCmpltDate: '-----', dueValue1: '-----', dueParam1: el.Param1, dueValue2: '-----', dueParam2: el.Param2, SpecID: el.SpecID })
          }
          else {
            this.assetDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, MSN: el.MSN, asset: el.AssetID, performedCheckType: el.LChkTyp, performedCmpltDate: el.LCompDt, performedValue1: el.LMValue1, performedParam1: el.Param1, performedValue2: el.LMValue2, performedParam2: el.Param2, dueCheckType: el.NChkTyp, dueCmpltDate: el.NCompDt, dueValue1: el.NMValue1, dueParam1: el.Param1, dueValue2: el.NMValue2, dueParam2: el.Param2, SpecID: el.SpecID })
          }
        });

        if (res != []) {
          this.sortedRes = this.assetDetailsData.sort((a, b) => a.type.localeCompare(b.type))
        }
        console.log("sortedRes", this.sortedRes)
        this.sortedRes.forEach(el => {
          this.specId.push(el.SpecID)
          this.lastCmpltDt.push(el.performedCmpltDate)
          this.nextCmpltDt.push(el.dueCmpltDate)
        });

        this.dataDetailsSource = new MatTableDataSource(this.sortedRes);
        this.dataDetailsSource.paginator = this.paginator;

        const assetDetailsArray = this.sortedRes.map(data => {
          return new FormGroup({
            performedCheckType: new FormControl(data.performedCheckType, Validators.required),
            performedCmpltDate: new FormControl(data.performedCmpltDate, Validators.required),
            performedValue1: new FormControl(data.performedValue1, Validators.required),
            performedValue2: new FormControl(data.performedValue2, Validators.required),
            dueCheckType: new FormControl(data.dueCheckType, Validators.required),
            dueCmpltDate: new FormControl(data.dueCmpltDate, Validators.required),
            dueValue1: new FormControl(data.dueValue1, Validators.required),
            dueValue2: new FormControl(data.dueValue2, Validators.required),
          }, { updateOn: "blur" });
        });
        this.UpdatedassetDetailsData = new FormArray(assetDetailsArray);
      });
  }

  updateDetailsField(index, field) {
    const control = this.getDetailsControl(index, field);
    if (control.valid) {
      this.assetDetailsData = this.assetDetailsData.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value
          }
        }
        return e;
      });
      this.onChangeDataDetailsSource(this.assetDetailsData);
    }

  }

  getDetailsControl(index, fieldName) {
    return this.UpdatedassetDetailsData.at(index).get(fieldName) as FormControl;
  }

  onChangeDataDetailsSource(updatedValue) {
    this.dataDetailsSource = new MatTableDataSource(updatedValue);
    this.dataDetailsSource.paginator = this.paginator;
  }

  changeVal() { // triggers on change of any field(s)
    this.noChangeYet = false;
  }

  formatDate(e, i, val) {
    if (val == 'lcmplt') {
      this.lastCmpltDt[i] = JSON.stringify(Math.round((e.target.value).getTime() / 1000));
    }
    if (val == 'ncmplt') {
      this.nextCmpltDt[i] = JSON.stringify(Math.round((e.target.value).getTime() / 1000));
    }
  }

  onSubmitDetails() {
    console.log("Updated Data on table: ", this.UpdatedassetDetailsData.value);

    let data = []
    for (let i = 0; i < this.UpdatedassetDetailsData.value.length; i++) {

      var obj = {
        "TAssetID": this.topAssetId,
        "SpecID": this.specId[i],
        "MSN": this.assetDetailsData[i].MSN,
        "AssetID": this.assetDetailsData[i].asset,
        "LChkTyp": this.UpdatedassetDetailsData.value[i].performedCheckType,
        "LCompDt": this.lastCmpltDt[i],
        "LMValue1": this.UpdatedassetDetailsData.value[i].performedValue1,
        "LMValue2": this.UpdatedassetDetailsData.value[i].performedValue2,
        "NChkTyp": this.UpdatedassetDetailsData.value[i].dueCheckType,
        "NCompDt": this.nextCmpltDt[i],
        "NMValue1": this.UpdatedassetDetailsData.value[i].dueValue1,
        "NMValue2": this.UpdatedassetDetailsData.value[i].dueValue2,
        "UpdatedBy": this.currentUser.userName
      }
      data.push(obj)
    }
    console.log("mntnc asset details data: ", data);

    this.ud.updateAssetDetails(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.noChangeYet = true;
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Maintenance details of Top AssetID: " + this.topAssetId + " updated successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Maintenance details of Top AssetID: " + this.topAssetId + " updation failed!", snConfig);
        }
      });
  }

}
