import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { CreateDetails } from 'src/app/services/createDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-create-new-org',
  templateUrl: './create-new-org.component.html',
  styleUrls: ['./create-new-org.component.scss']
})
export class CreateNewOrgComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private cd: CreateDetails,
    private changeDetectorRefs: ChangeDetectorRef,
    private snotService: SnotifyService) { }

  currentUser: any;
  submitted: boolean = false;
  orgMngtForm: FormGroup;
  get f() { return this.orgMngtForm.controls; }

  pickListValues = new PickList();
  statusOpts = this.pickListValues.recStatus
  positionOpts = this.pickListValues.position
  assetTypeOpts = this.pickListValues.type
  lifeParamsOpts = this.pickListValues.lifeParams
  utilParamsOpts = this.pickListValues.utilParams

  orgTypeOpts = this.pickListValues.orgType

  newOrgData: any = [];
  displayedColumns: any[] = ['orgName', 'orgType', 'address', 'status'];
  newOrgSource: MatTableDataSource<any>;

  newOrgDetailsData: any = [];
  UpdatedOrgDetailsData: FormArray;
  displayedDetailsColumns: any[] = ['bankName', 'number', 'accntNumb'];
  newOrgDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.newOrgData = [
      { orgName: '', orgType: '', address: '' },
    ]
    this.newOrgSource = new MatTableDataSource(this.newOrgData);

    this.newOrgDetailsData = [
      { bankName: '', number: '', accntNumb: '' },

    ]
    this.newOrgDetailsSource = new MatTableDataSource(this.newOrgDetailsData);

    this.orgMngtForm = this.fb.group({
      orgName: ['', Validators.required],
      orgType: ['', Validators.required],
      address: ['', Validators.required],
      status: ['', Validators.required],
      bankName: ['', Validators.required],
      number: ['', Validators.required],
      accntNumb: ['', Validators.required],
    })
  }

  onSubmit() {
    console.log("saveNewOrgDetails table: ", this.newOrgDetailsData, this.orgMngtForm.value);

    this.submitted = true;
    if (this.orgMngtForm.invalid) {
      console.log("Invalid Form: ", this.orgMngtForm);
      return;
    }

    console.log("Valid Form: ", this.orgMngtForm);

    var data = {
      "OrgType": this.orgMngtForm.value.orgType,
      "OrgName": this.orgMngtForm.value.orgName,
      "Address": this.orgMngtForm.value.address,
      "Status": this.orgMngtForm.value.status,
      "BankName": this.orgMngtForm.value.bankName,
      "Numb": this.orgMngtForm.value.number,
      "AccNo": this.orgMngtForm.value.accntNumb,
      "CreateBy": this.currentUser.userName
    }
    console.log("new org data: ", data);

    this.cd.createOrg(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("New organization " + data.OrgName + " created successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("New organization " + data.OrgName + " creation failed!", snConfig);
        }
        this.refresh()
      });
  }

  refresh() {
    this.orgMngtForm.reset();
    Object.keys(this.orgMngtForm.controls).forEach(key => {
      this.orgMngtForm.controls[key].setErrors(null)
    });
    this.changeDetectorRefs.detectChanges();
  }

}


