<div class="assetMngt">
  <div class="float-right m-3">
    <small>* Click on the table rows to view the details </small>
  </div>
  <div class="assetMngt-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataDetailsSource" multiTemplateDataRows class="mat-elevation-z8">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Position </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.position }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="assetType">
        <th mat-header-cell *matHeaderCellDef> Asset Type </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.assetType }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="partNumb">
        <th mat-header-cell *matHeaderCellDef> Part Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.partNumb }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="msn">
        <th mat-header-cell *matHeaderCellDef> MSN </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.msn }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="assetNumb">
        <th mat-header-cell *matHeaderCellDef> Asset Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.assetNumb }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="totalAmnt">
        <th mat-header-cell *matHeaderCellDef> Total Amount </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
          {{ element?.totalAmnt | currency: 'USD' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="billing-element-detail" *ngIf="element.utilRates?.data.length && showInnerTable == true"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
              <div class="tableRow row">
                <div class="tableLabel col-md-2">
                  <label><strong>Utilization Based Rates</strong></label>
                </div>
                <div class="col-md-10">
                  <table #innerTables mat-table [dataSource]="element.utilRates">
                    <ng-container matColumnDef="utilParam1">
                      <th mat-header-cell *matHeaderCellDef> Parameter </th>
                      <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
                        {{ element?.utilParam1 }} </td>
                      <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="utilValue1">
                      <th mat-header-cell *matHeaderCellDef> Value </th>
                      <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
                        {{ element?.utilValue1 }} </td>
                      <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="utilRate1">
                      <th mat-header-cell *matHeaderCellDef> Rate </th>
                      <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
                        {{ element?.utilRate1 | currency: 'USD' }} </td>
                      <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="utilParam2">
                      <th mat-header-cell *matHeaderCellDef> Parameter </th>
                      <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
                        {{ element?.utilParam2 }} </td>
                      <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="utilValue2">
                      <th mat-header-cell *matHeaderCellDef> Value </th>
                      <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
                        {{ element?.utilValue2 }} </td>
                      <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="utilRate2">
                      <th mat-header-cell *matHeaderCellDef> Rate </th>
                      <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
                        {{ element?.utilRate2 | currency: 'USD' }} </td>
                      <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef> Action </th>
                      <td mat-cell *matCellDef="let element; let index = index">
                        <button type="submit" mat-button class="save-btn" (click)="onSubmit(rowIndex)"
                          [disabled]="disablebutton[rowIndex]">
                          <mat-icon>calculate</mat-icon> Calculate
                        </button>
                      </td>
                      <td mat-footer-cell *matFooterCellDef> </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="billing-element-detail" *ngIf="showInnerTable == false"
            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="airframe-form mt-3">
              <form [formGroup]="airframeForm" (ngSubmit)="onSubmitForm()">
                <mat-card>
                  <div class="row afRow">
                    <div class="col-md-6 afRowSection1">
                      <div class="row">
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">Parameter</mat-label>
                          <mat-form-field class="col-sm-12" appearance="outline">
                            <mat-select placeholder="Parameter" formControlName="parameter1"
                              (selectionChange)="onSelectParam('param1',f.parameter1.value)">
                              <mat-option *ngFor="let opt of rentalParamOpts" [value]="opt.viewValue">
                                {{ opt.viewValue }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="submitted || f.parameter1.errors?.required || f.parameter1.touched">
                              Please select one parameter
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">Fixed Rental</mat-label>
                          &#36; <mat-form-field class="col-sm-12 formField" appearance="outline">
                            <input matInput formControlName="rental1">
                            <mat-error *ngIf="submitted || f.rental1.errors?.required || f.rental1.touched">
                              Please enter the fixed rental
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">FH Billing Rate</mat-label>
                          &#36; <mat-form-field class="col-sm-12 formField" appearance="outline">
                            <input matInput formControlName="billingRate1">
                            <mat-error *ngIf="submitted || f.billingRate1.errors?.required || f.billingRate1.touched">
                              Please enter the billing rate
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 afRowSection2">
                      <div class="row">
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">Parameter</mat-label>
                          <mat-form-field class="col-sm-12" appearance="outline">
                            <mat-select placeholder="Parameter" formControlName="parameter2"
                              (selectionChange)="onSelectParam('param2',f.parameter2.value)">
                              <mat-option *ngFor="let opt of rentalParamOpts" [value]="opt.viewValue">
                                {{ opt.viewValue }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="submitted || f.parameter2.errors?.required || f.parameter2.touched">
                              Please select one parameter
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">Fixed Rental</mat-label>
                          &#36; <mat-form-field class="col-sm-12 formField" appearance="outline">
                            <input matInput formControlName="rental2">
                            <mat-error *ngIf="submitted || f.rental2.errors?.required || f.rental2.touched">
                              Please enter the fixed rental
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">FH Billing Rate</mat-label>
                          &#36; <mat-form-field class="col-sm-12 formField" appearance="outline">
                            <input matInput formControlName="billingRate2">
                            <mat-error *ngIf="submitted || f.billingRate2.errors?.required || f.billingRate2.touched">
                              Please enter the billing rate
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row afRow">
                    <div class="col-md-6 afRowSection1">
                      <div class="row">
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">Parameter</mat-label>
                          <mat-form-field class="col-sm-12" appearance="outline">
                            <mat-select placeholder="Parameter" formControlName="parameter3"
                              (selectionChange)="onSelectParam('param3',f.parameter3.value)">
                              <mat-option *ngFor="let opt of rentalParamOpts" [value]="opt.viewValue">
                                {{ opt.viewValue }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="submitted || f.parameter3.errors?.required || f.parameter3.touched">
                              Please select one parameter
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">Fixed Rental</mat-label>
                          &#36; <mat-form-field class="col-sm-12 formField" appearance="outline">
                            <input matInput formControlName="rental3">
                            <mat-error *ngIf="submitted || f.rental3.errors?.required || f.rental3.touched">
                              Please enter the fixed rental
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">FH Billing Rate</mat-label>
                          &#36; <mat-form-field class="col-sm-12 formField" appearance="outline">
                            <input matInput formControlName="billingRate3">
                            <mat-error *ngIf="submitted || f.billingRate3.errors?.required || f.billingRate3.touched">
                              Please enter the billing rate
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 afRowSection2">
                      <div class="row">
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">Parameter</mat-label>
                          <mat-form-field class="col-sm-12" appearance="outline">
                            <mat-select placeholder="Parameter" formControlName="parameter4"
                              (selectionChange)="onSelectParam('param4',f.parameter4.value)">
                              <mat-option *ngFor="let opt of rentalParamOpts" [value]="opt.viewValue">
                                {{ opt.viewValue }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="submitted || f.parameter4.errors?.required || f.parameter4.touched">
                              Please select one parameter
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">Fixed Rental</mat-label>
                          &#36; <mat-form-field class="col-sm-12 formField" appearance="outline">
                            <input matInput formControlName="rental4">
                            <mat-error *ngIf="submitted || f.rental4.errors?.required || f.rental4.touched">
                              Please enter the fixed rental
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-4">
                          <mat-label class="col-sm-12 formLabel">FH Billing Rate</mat-label>
                          &#36; <mat-form-field class="col-sm-12 formField" appearance="outline">
                            <input matInput formControlName="billingRate4">
                            <mat-error *ngIf="submitted || f.billingRate4.errors?.required || f.billingRate4.touched">
                              Please enter the billing rate
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="float-left mt-2">
                      <mat-label class="col-sm-6 formLabel">FH</mat-label>
                      <mat-form-field class="col-sm-6" appearance="outline">
                        <input matInput formControlName="FHValue">
                      </mat-form-field>
                    </div>
                    <div class="float-right m-3" style="margin-left: 67% !important;">
                      <button type="submit" [hidden]="hideFormBtn == true" class="save-btn" mat-button
                        [disabled]="noChangeYet">
                        <mat-icon>save</mat-icon>Save
                      </button>
                    </div>
                  </div>

                </mat-card>
              </form>
            </div>
          </div>


        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplay; let i = dataIndex;"
        [class.billing-element-row]="element.utilRates?.length"
        [class.billing-expanded-row]="expandedElement === element" (click)="toggleRow(element,i)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="billing-detail-row"></tr>

    </table>
    <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
      <mat-paginator class="paginator" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>

  </div>

  <div *ngIf="sortedRes.length === 0">
    <mat-toolbar-row class="toolbar-row">
      <span class="noRecords">No records found!</span>
    </mat-toolbar-row>
  </div>

</div>
