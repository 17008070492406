import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { TableFilterService } from 'src/app/services/table-filter.service';
import { GetDetailService } from 'src/app/services/getDetails.service';

@Component({
  selector: 'app-asset-mngt',
  templateUrl: './asset-mngt.component.html',
  styleUrls: ['./asset-mngt.component.scss']
})
export class AssetMngtComponent implements OnInit {

  currentUser: any;
  title: string;
  canCreateNew: boolean = true;
  col1Clicked: boolean = false;
  col2Clicked: boolean = false;
  col3Clicked: boolean = false;
  col4Clicked: boolean = false;
  col5Clicked: boolean = false;
  col6Clicked: boolean = false;

  assetRes: any = [];
  sortedRes: any = [];

  displayedColumns: any[] = ['asset', 'model', 'MSN', 'type', 'operator', 'registration', 'contStartDate', 'contEndDate'];
  assetDataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('cmSort', { static: true, read: MatSort }) cmSort: MatSort;

  assetFilter = new FormControl("");
  modelFilter = new FormControl("");
  MSNFilter = new FormControl("");
  typeFilter = new FormControl("");
  operatorFilter = new FormControl("");
  registrationFilter = new FormControl("");

  constructor(
    private tableFilter: TableFilterService,
    private router: Router,
    private gd: GetDetailService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    if (this.router.url == '/asset-mngt') {
      this.title = "Asset Management"
    } else if (this.router.url == '/util-reports') {
      this.title = "Utilization Reports"
    } else if (this.router.url == '/invoices') {
      this.title = "Invoices"
    }
    this.createModeAccess()
    this.getContent()

    this.assetFilter.valueChanges.subscribe(asset => {
      this.tableFilter.filterValues.TAssetID = asset;
      this.assetDataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.modelFilter.valueChanges.subscribe(model => {
      this.tableFilter.filterValues.ModelID = model;
      this.assetDataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.MSNFilter.valueChanges.subscribe(MSN => {
      this.tableFilter.filterValues.MSN = MSN;
      this.assetDataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.typeFilter.valueChanges.subscribe(type => {
      this.tableFilter.filterValues.AstType = type;
      this.assetDataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.operatorFilter.valueChanges.subscribe(operator => {
      this.tableFilter.filterValues.Operator = operator;
      this.assetDataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
    this.registrationFilter.valueChanges.subscribe(registration => {
      this.tableFilter.filterValues.RegistNo = registration;
      this.assetDataSource.filter = JSON.stringify(this.tableFilter.filterValues);
    });
  }

  createModeAccess() {
    this.gd.getUserAccess(this.currentUser.role)
      .subscribe(res => {
        console.log("user access :", res);
        if (res.assetManagement == "E") {
          this.canCreateNew = false
        } else { this.canCreateNew = true }
      });
  }

  getContent() {

    if (this.currentUser.role == "Admin") {
      this.gd.getAllAssets()
        .subscribe(res => {
          console.log("all assets :", res);
          this.assetRes = res
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }
          this.assetDataSource = new MatTableDataSource(this.sortedRes);
          this.assetDataSource.sort = this.cmSort;
          this.assetDataSource.paginator = this.paginator;
          this.assetDataSource.filterPredicate = this.tableFilter.createAssetFilter();

        });
    } else if (this.currentUser.role == "Lessor Manager" || this.currentUser.role == "Lessor Accountant") {
      this.gd.getAllAssetsLessor(this.currentUser.orgName)
        .subscribe(res => {
          console.log("all lessor assets :", res);
          this.assetRes = res
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }
          this.assetDataSource = new MatTableDataSource(this.sortedRes);
          this.assetDataSource.sort = this.cmSort;
          this.assetDataSource.paginator = this.paginator;
          this.assetDataSource.filterPredicate = this.tableFilter.createAssetFilter();

        });
    } else if (this.currentUser.role == "Lessee Manager" || this.currentUser.role == "Lessee Accountant") {
      this.gd.getAllAssetsOperator(this.currentUser.orgName)
        .subscribe(res => {
          console.log("all lessor assets :", res);
          this.sortedRes = []
          if (res != []) {
            this.sortedRes = res.sort((a, b) => new Date((b.UpdateTS) * 1000).getTime() - new Date((a.UpdateTS) * 1000).getTime());
          }
          this.assetDataSource = new MatTableDataSource(this.sortedRes);
          this.assetDataSource.sort = this.cmSort;
          this.assetDataSource.paginator = this.paginator;
          this.assetDataSource.filterPredicate = this.tableFilter.createAssetFilter();

        });
    }

  }

  clear(dataFilter) {
    if (dataFilter == 'assetFilter') {
      this.assetFilter.setValue('')
    } else if (dataFilter == 'modelFilter') {
      this.modelFilter.setValue('')
    } else if (dataFilter == 'MSNFilter') {
      this.MSNFilter.setValue('')
    } else if (dataFilter == 'typeFilter') {
      this.typeFilter.setValue('')
    } else if (dataFilter == 'operatorFilter') {
      this.operatorFilter.setValue('')
    } else if (dataFilter == 'registrationFilter') {
      this.registrationFilter.setValue('')
    }
  }

  onRedirectToAssetDetails(model, row) {
    this.router.navigateByUrl('/asset-mngt/' + model)
  }

  createAsset(val1, val2) {
    this.router.navigateByUrl('/asset-mngt/' + val1 + '/' + val2)
  }

}
