import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

import { LoginComponent } from './components/login/login.component';
import { AdminHomeComponent } from './components/home/admin-home/admin-home.component';
import { LrmHomeComponent } from './components/home/lrm-home/lrm-home.component';
import { LraHomeComponent } from './components/home/lra-home/lra-home.component';
// import { LemHomeComponent } from './components/home/lem-home/lem-home.component';
import { LeaHomeComponent } from './components/home/lea-home/lea-home.component';
import { ConfMngtComponent } from './components/conf-mngt/conf-mngt.component';
import { ConfMngtDetailsComponent } from './components/conf-mngt/conf-mngt-details/conf-mngt-details.component';
import { CreateNewConfComponent } from './components/conf-mngt/create-new-conf/create-new-conf.component';
import { AssetMngtComponent } from './components/asset-mngt/asset-mngt.component';
import { AssetMngtDetailsComponent } from './components/asset-mngt/asset-mngt-details/asset-mngt-details.component';
import { CreateNewAssetComponent } from './components/asset-mngt/create-new-asset/create-new-asset.component';
import { OrgMasterComponent } from './components/org-master/org-master.component';
import { OrgMasterDetailsComponent } from './components/org-master/org-master-details/org-master-details.component';
import { CreateNewOrgComponent } from './components/org-master/create-new-org/create-new-org.component';
import { UserMngtComponent } from './components/user-mngt/user-mngt.component';
import { UserMngtDetailsComponent } from './components/user-mngt/user-mngt-details/user-mngt-details.component';
import { CreateNewUserComponent } from './components/user-mngt/create-new-user/create-new-user.component';
import { ContractMngtComponent } from './components/contract-mngt/contract-mngt.component';
import { ContractMngtDetailsComponent } from './components/contract-mngt/contract-mngt-details/contract-mngt-details.component';
import { CreateNewContractComponent } from './components/contract-mngt/create-new-contract/create-new-contract.component';
import { ReportComponent } from './components/report/report.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'admin-home', component: AdminHomeComponent, canActivate: [AuthGuard], data: { roles: ['Admin'] } },
  { path: 'lessor-mng-home', component: LrmHomeComponent, canActivate: [AuthGuard], data: { roles: ['Lessor Manager'] } },
  { path: 'lessor-acc-home', component: LraHomeComponent, canActivate: [AuthGuard], data: { roles: ['Lessor Accountant'] } },
  { path: 'lessee-mng-home', component: LeaHomeComponent, canActivate: [AuthGuard], data: { roles: ['Lessee Manager'] } },
  { path: 'lessee-acc-home', component: LeaHomeComponent, canActivate: [AuthGuard], data: { roles: ['Lessee Accountant'] } },

  { path: 'conf-mngt', component: ConfMngtComponent, canActivate: [AuthGuard] },
  { path: 'conf-mngt/:id', component: ConfMngtDetailsComponent, canActivate: [AuthGuard] },
  { path: 'conf-mngt/:id/:id', component: CreateNewConfComponent, canActivate: [AuthGuard] },
  { path: 'asset-mngt', component: AssetMngtComponent, canActivate: [AuthGuard] },
  { path: 'asset-mngt/:id', component: AssetMngtDetailsComponent, canActivate: [AuthGuard] },
  { path: 'asset-mngt/:id/:id', component: CreateNewAssetComponent, canActivate: [AuthGuard] },
  { path: 'org-master', component: OrgMasterComponent, canActivate: [AuthGuard] },
  { path: 'org-master/:id', component: OrgMasterDetailsComponent, canActivate: [AuthGuard] },
  { path: 'org-master/:id/:id', component: CreateNewOrgComponent, canActivate: [AuthGuard] },
  { path: 'user-mngt', component: UserMngtComponent, canActivate: [AuthGuard] },
  { path: 'user-mngt/:id', component: UserMngtDetailsComponent, canActivate: [AuthGuard] },
  { path: 'user-mngt/:id/:id', component: CreateNewUserComponent, canActivate: [AuthGuard] },
  { path: 'contract-mngt', component: ContractMngtComponent, canActivate: [AuthGuard] },
  { path: 'contract-mngt/:id', component: ContractMngtDetailsComponent, canActivate: [AuthGuard] },
  { path: 'contract-mngt/:id/:id', component: CreateNewContractComponent, canActivate: [AuthGuard] },
  { path: 'util-reports', component: AssetMngtComponent, canActivate: [AuthGuard] },
  { path: 'invoices', component: AssetMngtComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ReportComponent, canActivate: [AuthGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule], providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl, '_blank');
      },
    }
  ]
})
export class AppRoutingModule { }
