<div class="orgMaster">
  <h4><strong> <a [routerLink]="['/org-master']">
        <mat-icon>arrow_back</mat-icon>
      </a> Details of Org# : {{ id }} </strong></h4>
  <div class="editInfo m-3">
    <small>* Blue colored data are editable in the table, click on the data to edit </small>
  </div>
  <mat-divider></mat-divider>

  <div class="orgMaster-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orgName">
        <th mat-header-cell *matHeaderCellDef> Organization Name </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.orgName }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="orgType">
        <th mat-header-cell *matHeaderCellDef> Organization Type </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.orgType }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> Address </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateField(index, 'address')">
            <ng-template viewMode>
              {{element?.address}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getControl(index, 'address')" editableOnEnter>
                <mat-error *ngIf="getControl(index, 'address').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateField(index, 'status')">
            <ng-template viewMode>
              {{element?.status}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [formControl]="getControl(index, 'status')" placeholder="Status" [value]="element.status"
                  editableOnEnter>
                  <mat-option *ngFor="let opt of statusOpts" [value]="opt.value">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getControl(index, 'status').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <br>

  <div class="orgMaster-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataDetailsSource">
      <ng-container matColumnDef="bankName">
        <th mat-header-cell *matHeaderCellDef> Bank Name </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'bankName')">
            <ng-template viewMode>
              {{element?.bankName}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'bankName')" editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'bankName').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef> Number </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'number')">
            <ng-template viewMode>
              {{element?.number}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'number')" editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'number').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="accntNumb">
        <th mat-header-cell *matHeaderCellDef> Account Number </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'accntNumb')">
            <ng-template viewMode>
              {{element?.accntNumb}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'accntNumb')" editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'accntNumb').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
    </table>
  </div>

  <div class="table-actions col-sm-12 m-3">
    <div class="float-right">
      <button type="submit" mat-button class="save-btn mr-2" (click)="onSubmit()">
        <mat-icon>save</mat-icon> Save
      </button>
    </div>
  </div>

</div>
