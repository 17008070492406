<div class="confMngt">
  <h4><strong> <a [routerLink]="['/conf-mngt']">
        <mat-icon>arrow_back</mat-icon>
      </a> Details of Model Number : {{ id }} </strong></h4>
  <div class="editInfo m-3">
    <small>* Blue colored data are editable in the table, click on the data to edit </small>
  </div>
  <mat-divider></mat-divider>

  <div class="confMngt-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="manufacturer">
        <th mat-header-cell *matHeaderCellDef> Manufacturer </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.manufacturer }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="modelNumber">
        <th mat-header-cell *matHeaderCellDef> Model Number </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.modelNumber }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.type }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateField(index, 'status')">
            <ng-template viewMode>
              {{element?.status}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.status" placeholder="Status" matInput
                  [formControl]="getControl(index, 'status')" (selectionChange)="changeVal()" editableOnEnter>
                  <mat-option *ngFor="let opt of recordStatus" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getControl(index, 'status').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="lastUpdated">
        <th mat-header-cell *matHeaderCellDef> Last Updated </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index">
          {{ ((element?.lastUpdated)*1000) | date: 'MM/dd/yyyy' }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <button mat-button class="save-btn  btn-sm" (click)="onSubmit()" [disabled]="noChangeYet">
            <mat-icon>save</mat-icon> Save
          </button>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div class="confMngt-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataDetailsSource">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef> Position </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'position')">
            <ng-template viewMode>
              {{element?.position}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.position" placeholder="Position" matInput
                  [formControl]="getDetailsControl(index, 'position')" (selectionChange)="changeDetailsVal()"
                  editableOnEnter>
                  <mat-option *ngFor="let opt of positionOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getDetailsControl(index, 'position').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Asset Type </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'type')">
            <ng-template viewMode>
              {{element?.type}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.type" placeholder="Asset Type" matInput
                  [formControl]="getDetailsControl(index, 'type')" (selectionChange)="changeDetailsVal()"
                  editableOnEnter>
                  <mat-option *ngFor="let opt of assetTypeOpts" [value]="opt.viewValue">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getDetailsControl(index, 'type').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="partNumber">
        <th mat-header-cell *matHeaderCellDef> Part Number </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateDetailsField(index, 'partNumber')">
            <ng-template viewMode>
              {{element?.partNumber}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getDetailsControl(index, 'partNumber')" (change)="changeDetailsVal()"
                  editableOnEnter>
                <mat-error *ngIf="getDetailsControl(index, 'partNumber').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedDetailsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedDetailsColumns;"></tr>
    </table>

    <mat-toolbar-row [hidden]="sortedRes.length === 0" class="toolbar-row">
      <mat-paginator class="paginator" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
    </mat-toolbar-row>

  </div>

  <div *ngIf="sortedRes.length === 0">
    <mat-toolbar-row class="toolbar-row">
      <span class="noRecords">No records found!</span>
    </mat-toolbar-row>
  </div>

  <div class="table-actions col-sm-12 m-3">
    <div class="float-right">
      <button type="submit" mat-button class="save-btn mr-2" (click)="onSubmitDetails()"
        [disabled]="noDetailsChangeYet">
        <mat-icon>save</mat-icon> Save
      </button>
    </div>
  </div>

</div>
