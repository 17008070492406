export class PickList {
  recStatus = [
    { value: "Draft", viewValue: "Draft" },
    { value: "Active", viewValue: "Active" },
    { value: "Cancel", viewValue: "Cancel" },
    { value: "Obsolete", viewValue: "Obsolete" }
  ];
  position = [
    { value: "Null", viewValue: "Null" },
    { value: "P1", viewValue: "P1" },
    { value: "P2", viewValue: "P2" },
    { value: "P3", viewValue: "P3" },
    { value: "P4", viewValue: "P4" },
    { value: "NLG", viewValue: "NLG" },
    { value: "MLG 1", viewValue: "MLG 1" },
    { value: "MLG 2", viewValue: "MLG 2" },
    { value: "MLG 3", viewValue: "MLG 3" },
    { value: "MLG 4", viewValue: "MLG 4" },
  ];
  type = [
    { value: "Engine", viewValue: "Engine" },
    { value: "APU", viewValue: "APU" },
    { value: "Landing Gear", viewValue: "Landing Gear" },
  ];
  lifeParams = [
    { value: "THSN", viewValue: "THSN" },
    { value: "TCSN", viewValue: "TCSN" },
  ];
  utilParams = [
    { value: "FC", viewValue: "FC" },
    { value: "FH", viewValue: "FH" },
    { value: "Derate", viewValue: "Derate" },
    { value: "APU Cycles", viewValue: "APU Cycles" },
    { value: "APU Hours", viewValue: "APU Hours" },
  ];
  modelNumber = [
    { value: "737 Max 8", viewValue: "737 Max 8" },
    { value: "737 Max 10", viewValue: "737 Max 10" },
    { value: "737-800", viewValue: "737-800" },
    { value: "737-300", viewValue: "737-300" },
    { value: "787-9", viewValue: "787-9" },
    { value: "A320neo", viewValue: "A320neo" },
    { value: "A320", viewValue: "A320" },
    { value: "A321", viewValue: "A321" },
    { value: "A321neo", viewValue: "A321neo" },
    { value: "A330-200", viewValue: "A330-200" },
    { value: "A330-300", viewValue: "A330-300" },
    { value: "A330-900neo", viewValue: "A330-900neo" },
    { value: "A350-900", viewValue: "A350-900" },
    { value: "LEAP 1B27", viewValue: "LEAP 1B27" },
    { value: "LEAP 1B28", viewValue: "LEAP 1B28" },
    { value: "LEAP 1B28B1", viewValue: "LEAP 1B28B1" },
    { value: "CFM56-7B24", viewValue: "CFM56-7B24" },
    { value: "CFM56-7B24/3", viewValue: "CFM56-7B24/3" },
    { value: "CFM56-7B24E", viewValue: "CFM56-7B24E" },
    { value: "CFM56-7B26/3", viewValue: "CFM56-7B26/3" },
    { value: "CFM56-7B26E", viewValue: "CFM56-7B26E" },
    { value: "CFM56-7B27/3B1F", viewValue: "CFM56-7B27/3B1F" },
    { value: "CFM56-7B27E/B1F", viewValue: "CFM56-7B27E/B1F" },
    { value: "GE90 115BL2", viewValue: "GE90 115BL2" },
    { value: "Trent 1000-74/76 C", viewValue: "Trent 1000-74/76 C" },
    { value: "Trent 1000-74 TEN", viewValue: "Trent 1000-74 TEN" },
    { value: "GEnx 1B76A PIP II", viewValue: "GEnx 1B76A PIP II" },
    { value: "GEnx 1B74/75 PIP II", viewValue: "GEnx 1B74/75 PIP II" },
    { value: "LEAP 1A26", viewValue: "LEAP 1A26" },
    { value: "PW1127G-JM", viewValue: "PW1127G-JM" },
    { value: "CFM56-5B6/3", viewValue: "CFM56-5B6/3" },
    { value: "CFM56-5B4/3", viewValue: "CFM56-5B4/3" },
    { value: "CFM56-5B4/3 PIP", viewValue: "CFM56-5B4/3 PIP" },
    { value: "V2527-A5SelectOne", viewValue: "V2527-A5SelectOne" },
    { value: "CFM56-5B6/3 PIP", viewValue: "CFM56-5B6/3 PIP" },
    { value: "V2527E-A5SelectTwo", viewValue: "V2527E-A5SelectTwo" },
    { value: "Unannounced", viewValue: "Unannounced" },
    { value: "V2533-A5SelectOne", viewValue: "V2533-A5SelectOne" },
    { value: "CFM56-5B2/3 PIP", viewValue: "CFM56-5B2/3 PIP" },
    { value: "CFM56-5B3/3 PIP", viewValue: "CFM56-5B3/3 PIP" },
    { value: "V2533-A5SelectTwo", viewValue: "V2533-A5SelectTwo" },
    { value: "LEAP 1A32", viewValue: "LEAP 1A32" },
    { value: "PW1133G-JM", viewValue: "PW1133G-JM" },
    { value: "Trent 772B-60", viewValue: "Trent 772B-60" },
    { value: "Trent 772B-60EP", viewValue: "Trent 772B-60EP" },
    { value: "Trent 7000-72", viewValue: "Trent 7000-72" },
    { value: "Trent 772C-60EP", viewValue: "Trent 772C-60EP" },
    { value: "Trent XWB-84", viewValue: "Trent XWB-84" },
    { value: "GTCP131-9B", viewValue: "GTCP131-9B" },
    { value: "GTCP331-500B", viewValue: "GTCP331-500B" },
    { value: "APS5000", viewValue: "APS5000" },
    { value: "GTCP131-9A", viewValue: "GTCP131-9A" },
    { value: "APS3200", viewValue: "APS3200" },
    { value: "GTCP331-350", viewValue: "GTCP331-350" },
    { value: "HGT1700", viewValue: "HGT1700" },
  ];
  assetStatus = [
    { value: "Storage", viewValue: "Storage" },
    { value: "On Order", viewValue: "On Order" },
    { value: "Cancelled", viewValue: "Cancelled" },
    { value: "On Option", viewValue: "On Option" },
    { value: "In Service", viewValue: "In Service" },
    { value: "Written Off", viewValue: "Written Off" },
    { value: "Type Swap", viewValue: "Type Swap" },
  ];
  billStatus = [
    { value: "Draft", viewValue: "Draft" },
    { value: "Submitted", viewValue: "Submitted" },
    { value: "Approve", viewValue: "Approve" },
    { value: "Reject", viewValue: "Reject" },
    { value: "Close", viewValue: "Close" },
  ];
  checkType = [
    { value: "C Check", viewValue: "C Check" },
    { value: "D Check", viewValue: "D Check" },
  ];
  organization = [
    { value: "Org1", viewValue: "Org1" },
    { value: "Org2", viewValue: "Org2" },
  ];
  orgType = [
    { value: "Lessor", viewValue: "Lessor" },
    { value: "Operator", viewValue: "Operator" },
  ];
  role = [
    { value: "Lessee Accountant", viewValue: "Lessee Accountant" },
    { value: "Lessor Accountant", viewValue: "Lessor Accountant" },
    { value: "Lessor Manager", viewValue: "Lessor Manager" },
    { value: "Admin", viewValue: "Admin" },
  ];
  module = [
    { value: "Asset Management", viewValue: "Asset Management" },
    { value: "Configuration Management", viewValue: "Configuration Management" },
    { value: "Contract Management", viewValue: "Contract Management" },
    { value: "Organization Master", viewValue: "Organization Master" },
    { value: "User Management", viewValue: "User Management" },
    { value: "Reports", viewValue: "Reports" },
  ];
  manufacturer = [
    { value: "Airbus", viewValue: "Airbus" },
    { value: "Boeing", viewValue: "Boeing" },
    { value: "Bombardier", viewValue: "Bombardier" },
    { value: "CFM International", viewValue: "CFM International" },
    { value: "Embraer", viewValue: "Embraer" },
    { value: "General Electric", viewValue: "General Electric" },
    { value: "Hamilton Sundstrand HQ", viewValue: "Hamilton Sundstrand HQ" },
    { value: "Honeywell", viewValue: "Honeywell" },
    { value: "International Aero Engines", viewValue: "International Aero Engines" },
    { value: "Pratt & Whitney", viewValue: "Pratt & Whitney" },
    { value: "Rolls-Royce", viewValue: "Rolls-Royce" },
    { value: "Safran", viewValue: "Safran" },
    { value: "Unannounced engine manufacturer", viewValue: "Unannounced engine manufacturer" },
  ]
  rentalParams = [
    { value: "6 Year Check", viewValue: "6 Year Check" },
    { value: "8 Year Check", viewValue: "8 Year Check" },
    { value: "10 Year Check", viewValue: "10 Year Check" },
    { value: "12 Year Check", viewValue: "12 Year Check" },
  ];
  reports = [
    { value: "Lessor Monthly Utilization", viewValue: "Lessor Monthly Utilization" },
    { value: "Lessor Monthly Maintenance", viewValue: "Lessor Monthly Maintenance" },
    { value: "Lessee Monthly Utilization", viewValue: "Lessee Monthly Utilization" },
    { value: "Lessee Monthly Maintenance", viewValue: "Lessee Monthly Maintenance" },
  ];
  reportsForLessor = [
    { value: "Lessor Monthly Utilization", viewValue: "Lessor Monthly Utilization" },
    { value: "Lessor Monthly Maintenance", viewValue: "Lessor Monthly Maintenance" },
  ];
  reportsForLessee = [
    { value: "Lessee Monthly Utilization", viewValue: "Lessee Monthly Utilization" },
    { value: "Lessee Monthly Maintenance", viewValue: "Lessee Monthly Maintenance" },
  ];
}
