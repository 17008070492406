import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-asset-config-dialog',
  templateUrl: './asset-config-dialog.component.html',
  styleUrls: ['./asset-config-dialog.component.scss']
})
export class AssetConfigDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private assetConfigDialogRef: MatDialogRef<AssetConfigDialogComponent>
  ) { }

  currentUser: any;

  assetConfigData: any = [];
  displayedColumns: any[] = ['type', 'asset', 'MSN', 'installedAirframe', 'THSN', 'TCSN', 'FC', 'FH'];
  dataSource: MatTableDataSource<any>;


  ngOnInit(): void {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.assetConfigData = [
      { type: '', asset: '', MSN: '', installedAirframe: 'Off wing', THSN: '', TCSN: '', FC: '', FH: '' },
      { type: 'Engine', asset: '63593927', MSN: '2877', installedAirframe: '3358', THSN: '3796', TCSN: '1898', FC: '633', FH: '1265' },
      { type: 'Engine', asset: '35656570', MSN: '1902', installedAirframe: '3650', THSN: '3942', TCSN: '1971', FC: '657', FH: '1314' },
      { type: 'Engine', asset: '55765897', MSN: '6024', installedAirframe: '34255', THSN: '3358', TCSN: '1679', FC: '560', FH: '1119' },
      { type: 'Engine', asset: '48973163', MSN: '8465', installedAirframe: '34256', THSN: '1898', TCSN: '949', FC: '316', FH: '633' },
      { type: 'Engine', asset: '12456717', MSN: '7473', installedAirframe: '2701', THSN: '730', TCSN: '365', FC: '122', FH: '243' },
      { type: 'Engine', asset: '93554273', MSN: '4086', installedAirframe: '43794', THSN: '584', TCSN: '292', FC: '97', FH: '195' },
      { type: 'Engine', asset: '15487852', MSN: '4243', installedAirframe: '43796', THSN: '9271', TCSN: '4636', FC: '1545', FH: '3090' },
      { type: 'Engine', asset: '58314600', MSN: '8650', installedAirframe: '64508', THSN: '8760', TCSN: '4380', FC: '1460', FH: '2920' },
      { type: 'APU', asset: '95783490', MSN: '4242', installedAirframe: '1898', THSN: '5694', TCSN: '2847', FC: '949', FH: '1898' },
      { type: 'APU', asset: '47726083', MSN: '7873', installedAirframe: '1971', THSN: '3650', TCSN: '1825', FC: '608', FH: '1217' },
      { type: 'APU', asset: '64561205', MSN: '6052', installedAirframe: '34268', THSN: '3723', TCSN: '1862', FC: '621', FH: '1241' },
      { type: 'APU', asset: '38295597', MSN: '2952', installedAirframe: '43658', THSN: '3358', TCSN: '1679', FC: '560', FH: '1119' },
      { type: 'APU', asset: '72394623', MSN: '4220', installedAirframe: '37970', THSN: '2774', TCSN: '1387', FC: '462', FH: '925' },
      { type: 'Landing Gear', asset: '73454745', MSN: '1143', installedAirframe: '37976', THSN: '2847', TCSN: '1423.5', FC: '475', FH: '949' },
      { type: 'Landing Gear', asset: '44658502', MSN: '9786', installedAirframe: '37980', THSN: '2701', TCSN: '1350.5', FC: '450', FH: '900' },
      { type: 'Landing Gear', asset: '18973947', MSN: '3541', installedAirframe: '62144', THSN: '1168', TCSN: '584', FC: '195', FH: '389' },
      { type: 'Landing Gear', asset: '33856217', MSN: '9332', installedAirframe: '62145', THSN: '730', TCSN: '365', FC: '122', FH: '243' },
      { type: 'Landing Gear', asset: '18453217', MSN: '5718', installedAirframe: '3394', THSN: '730', TCSN: '365', FC: '122', FH: '243' },

    ]
    this.dataSource = new MatTableDataSource(this.assetConfigData);

  }

  onSelect(row) {
    console.log("row details:::", row)
    if (row == '') {
      this.assetConfigDialogRef.close({ type: this.data.type, asset: this.data.asset, MSN: this.data.MSN, installedAirframe: this.data.installedAirframe, THSN: this.data.THSN, TCSN: this.data.TCSN, FC: this.data.FC, FH: this.data.FH });
    }
    else {
      this.assetConfigDialogRef.close(row);
    }
  }

}
