import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from "ngx-spinner";
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { CurrencyPipe } from '@angular/common';

// user-defined Modules
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from 'src/material.module';

// Interceptors
import { LoaderInterceptor } from './helpers/interceptors/loader.interceptor';
import { ErrorInterceptor } from './helpers/interceptors/error.interceptor';
import { JwtInterceptor } from './helpers/interceptors/jwt.interceptor';

// Directives
import { ViewModeDirective } from './helpers/mat-table-edit/view-mode.directive';
import { EditModeDirective } from './helpers/mat-table-edit/edit-mode.directive';
import { FocusableDirective } from './helpers/mat-table-edit/focusable.directive';
import { EditableOnEnterDirective } from './helpers/mat-table-edit/edit-on-enter.directive';

import { AppComponent } from './app.component';
import { NavBarComponent } from './layouts/nav-bar/nav-bar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { SideBarComponent } from './layouts/side-bar/side-bar.component';
import { ConfMngtComponent } from './components/conf-mngt/conf-mngt.component';
import { EditableComponent } from './helpers/mat-table-edit/editable.component';
import { EditableDateComponent } from './helpers/mat-table-edit/editableDate.component';
import { ConfMngtDetailsComponent } from './components/conf-mngt/conf-mngt-details/conf-mngt-details.component';
import { CreateNewConfComponent } from './components/conf-mngt/create-new-conf/create-new-conf.component';
import { AssetMngtComponent } from './components/asset-mngt/asset-mngt.component';
import { AssetMngtDetailsComponent } from './components/asset-mngt/asset-mngt-details/asset-mngt-details.component';
import { AssetMngtConfigurationComponent } from './components/asset-mngt/asset-mngt-details/asset-mngt-configuration/asset-mngt-configuration.component';
import { AssetMngtUtilizationComponent } from './components/asset-mngt/asset-mngt-details/asset-mngt-utilization/asset-mngt-utilization.component';
import { ViewUtilizationComponent } from './components/asset-mngt/asset-mngt-details/asset-mngt-utilization/view-utilization/view-utilization.component';
import { AssetMngtMaintenanceComponent } from './components/asset-mngt/asset-mngt-details/asset-mngt-maintenance/asset-mngt-maintenance.component';
import { AssetMngtBillingComponent } from './components/asset-mngt/asset-mngt-details/asset-mngt-billing/asset-mngt-billing.component';
import { ViewBillingComponent } from './components/asset-mngt/asset-mngt-details/asset-mngt-billing/view-billing/view-billing.component';
import { CreateNewAssetComponent } from './components/asset-mngt/create-new-asset/create-new-asset.component';
import { AssetConfigDialogComponent } from './helpers/dialog/asset-config-dialog/asset-config-dialog.component';
import { OrgMasterComponent } from './components/org-master/org-master.component';
import { OrgMasterDetailsComponent } from './components/org-master/org-master-details/org-master-details.component';
import { CreateNewOrgComponent } from './components/org-master/create-new-org/create-new-org.component';
import { ContractMngtComponent } from './components/contract-mngt/contract-mngt.component';
import { ContractMngtDetailsComponent } from './components/contract-mngt/contract-mngt-details/contract-mngt-details.component';
import { CreateNewContractComponent } from './components/contract-mngt/create-new-contract/create-new-contract.component';
import { ContractMsnDialogComponent } from './helpers/dialog/contract-msn-dialog/contract-msn-dialog.component';
import { UserMngtComponent } from './components/user-mngt/user-mngt.component';
import { UserMngtDetailsComponent } from './components/user-mngt/user-mngt-details/user-mngt-details.component';
import { CreateNewUserComponent } from './components/user-mngt/create-new-user/create-new-user.component';
import { AdminHomeComponent } from './components/home/admin-home/admin-home.component';
import { LrmHomeComponent } from './components/home/lrm-home/lrm-home.component';
import { LraHomeComponent } from './components/home/lra-home/lra-home.component';
import { LeaHomeComponent } from './components/home/lea-home/lea-home.component';
import { ReportComponent } from './components/report/report.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    FooterComponent,
    LoginComponent,
    SideBarComponent,
    ConfMngtComponent,
    ViewModeDirective,
    EditModeDirective,
    FocusableDirective,
    EditableOnEnterDirective,
    EditableComponent,
    EditableDateComponent,
    ConfMngtDetailsComponent,
    CreateNewConfComponent,
    AssetMngtComponent,
    AssetMngtConfigurationComponent,
    AssetMngtDetailsComponent,
    AssetMngtUtilizationComponent,
    ViewUtilizationComponent,
    AssetMngtMaintenanceComponent,
    AssetMngtBillingComponent,
    ViewBillingComponent,
    CreateNewAssetComponent,
    AssetConfigDialogComponent,
    OrgMasterComponent,
    OrgMasterDetailsComponent,
    CreateNewOrgComponent,
    ContractMngtComponent,
    ContractMngtDetailsComponent,
    CreateNewContractComponent,
    ContractMsnDialogComponent,
    UserMngtComponent,
    UserMngtDetailsComponent,
    CreateNewUserComponent,
    AdminHomeComponent,
    LrmHomeComponent,
    LraHomeComponent,
    LeaHomeComponent,
    ReportComponent,
  ],
  entryComponents: [
    AssetConfigDialogComponent,
    ContractMsnDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
    NgxSpinnerModule,
    SnotifyModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults }, SnotifyService,
    EditableComponent,
    EditableDateComponent,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
