<div class="userMngt">
  <h4><strong> <a [routerLink]="['/user-mngt']">
        <mat-icon>arrow_back</mat-icon>
      </a> Details of User : {{ id }} </strong></h4>
  <div class="editInfo m-3">
    <small>* Blue colored data are editable in the table, click on the data to edit </small>
  </div>
  <mat-divider></mat-divider>

  <div class="userMngt-table mt-3 table-responsive">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="orgName">
        <th mat-header-cell *matHeaderCellDef> Organization Name </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.orgName }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef> Role </th>
        <td mat-cell class="non-edit" *matCellDef="let element; let index = index"> {{ element?.role }} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateField(index, 'name')">
            <ng-template viewMode>
              {{element?.name}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <input matInput [formControl]="getControl(index, 'name')" editableOnEnter>
                <mat-error *ngIf="getControl(index, 'name').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element; let index = index">
          <editable (update)="updateField(index, 'status')">
            <ng-template viewMode>
              {{element?.status}}
            </ng-template>
            <ng-template editMode>
              <mat-form-field class="example-full-width">
                <mat-select [(value)]="element.status" placeholder="Status" matInput
                  [formControl]="getControl(index, 'status')" editableOnEnter>
                  <mat-option *ngFor="let opt of recordStatus" [value]="opt.value">
                    {{ opt.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="getControl(index, 'status').hasError('required')">
                  Field is required
                </mat-error>
              </mat-form-field>
            </ng-template>
          </editable>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div>
    <mat-card class="custom-card col-sm-12">
      <mat-card-title class="custom-card-title col-sm-12">
        <h4 class="text-white">Access to Modules</h4>
      </mat-card-title>
      <mat-card-content>
        <div class="module-form mt-3">
          <form [formGroup]="moduleForm">
            <div class="row">
              <div class="col-md-2">
                <mat-checkbox formControlName="checkbox1">Asset Management</mat-checkbox>
              </div>
              <div class="col-md-2">
                <mat-checkbox formControlName="checkbox2">Configuration Management</mat-checkbox>
              </div>
              <div class="col-md-2">
                <mat-checkbox formControlName="checkbox3">Contract Management</mat-checkbox>
              </div>
              <div class="col-md-2">
                <mat-checkbox formControlName="checkbox4">Organization Master</mat-checkbox>
              </div>
              <div class="col-md-2">
                <mat-checkbox formControlName="checkbox5">User Management</mat-checkbox>
              </div>
              <div class="col-md-2">
                <mat-checkbox formControlName="checkbox6">Reports</mat-checkbox>
              </div>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="table-actions col-sm-12 m-3">

    <div class="float-right">
      <button type="submit" mat-button class="save-btn mr-2" (click)="onSubmit()">
        <mat-icon>save</mat-icon> Save
      </button>
    </div>
  </div>

</div>
