import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { User } from '../../models/user';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) { }

  isLoggedIn: boolean = false;
  isLogin: boolean;
  currentUser: User;


  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isLogin = event.url.includes("/login") || event.url === '/' ? true : false;
    });
    this.authService.currentUser.subscribe(user => {
      this.isLoggedIn = !!user
      console.log("nav bar: ", user);
      if (this.isLoggedIn) {
        this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      }
    });
  }

  onSidebarToggle() {
    this.authService.isSidebarOpen = !this.authService.isSidebarOpen;
  }

  onLogOut() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

}
