import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { GetDetailService } from 'src/app/services/getDetails.service';
import { CreateDetails } from 'src/app/services/createDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private gd: GetDetailService,
    private cd: CreateDetails,
    private changeDetectorRefs: ChangeDetectorRef,
    private snotService: SnotifyService) { }

  currentUser: any;
  isAdmin: boolean = false;
  submitted: boolean = false;
  userMngtForm: FormGroup;
  get f() { return this.userMngtForm.controls; }

  pickListValues = new PickList();
  roleOpts = this.pickListValues.role
  moduleOpts = this.pickListValues.module
  statusOpts = this.pickListValues.recStatus

  orgOpts: any = [];

  newOrgData: any = [];
  displayedColumns: any[] = ['orgName', 'userName', 'role', 'name', 'module', 'status'];
  newOrgSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.newOrgData = [
      { orgName: '', userName: '', role: '', name: '', module: '', status: '' },
    ]
    this.newOrgSource = new MatTableDataSource(this.newOrgData);

    this.userMngtForm = this.fb.group({
      orgName: [null, Validators.required],
      userName: [null, Validators.required],
      role: [null, Validators.required],
      name: [null, Validators.required],
      module: [null, Validators.required],
      status: [null, Validators.required],
    })

    if (this.currentUser.role == "Admin") {
      this.isAdmin = true

      this.gd.getAllOrgs()
        .subscribe(res => {
          console.log("all orgs :", res);
          res.forEach(element => {
            this.orgOpts.push({ value: element.OrgName, viewValue: element.OrgName })
          });
        });
    } else {
      this.f.orgName.setValue(this.currentUser.orgName)
    }
  }

  onSubmit() {

    this.submitted = true;
    if (this.userMngtForm.invalid) {
      console.log("Invalid Form: ", this.userMngtForm, this.userMngtForm.value);
      return;
    }

    console.log("Valid Form: ", this.userMngtForm);

    var data = {
      "orgName": this.userMngtForm.value.orgName,
      "userName": this.userMngtForm.value.userName,
      "passWord": this.userMngtForm.value.userName,
      "role": this.userMngtForm.value.role,
      "name": this.userMngtForm.value.name,
      "status": this.userMngtForm.value.status,
      "module": this.userMngtForm.value.module
    }
    console.log("new user data: ", data);

    this.cd.createUser(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("New user " + data.userName + " created successfully!", snConfig);
        }
        else if (r.status == 'duplicate') {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("User with " + data.userName + " already exists!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("New user " + data.userName + " creation failed!", snConfig);
        }
        this.refresh()
      });
  }

  refresh() {
    this.userMngtForm.reset();
    Object.keys(this.userMngtForm.controls).forEach(key => {
      this.userMngtForm.controls[key].setErrors(null)
    });
    this.changeDetectorRefs.detectChanges();
  }

}


