import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list'
import { MatTable } from '@angular/material/table'
import { GetDetailService } from 'src/app/services/getDetails.service';
import { UpdateDetails } from 'src/app/services/updateDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-contract-mngt-details',
  templateUrl: './contract-mngt-details.component.html',
  styleUrls: ['./contract-mngt-details.component.scss']
})
export class ContractMngtDetailsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private gd: GetDetailService,
    private ud: UpdateDetails,
    private snotService: SnotifyService) { }

  currentUser: any;
  id: any;
  today = new Date();
  noChangeYet: boolean = true;
  noDetailsChangeYet: boolean = true;

  contStartDt: any = '';
  contEndDt: any = '';

  pickListValues = new PickList();
  recordStatus = this.pickListValues.recStatus
  positionOpts = this.pickListValues.position
  assetTypeOpts = this.pickListValues.type
  lifeParamsOpts = this.pickListValues.lifeParams
  utilParamsOpts = this.pickListValues.utilParams

  statusOpts = this.pickListValues.billStatus
  rentalParamOpts = this.pickListValues.rentalParams

  sortedRes: any = [];

  contractMngtData: any = [];
  specId: any = [];
  UpdatedcontractMngtData: FormArray;
  displayedColumns: any[] = ['contract', 'lessor', 'operator', 'model', 'msn', 'startDate', 'endDate', 'status', 'action'];
  dataSource: MatTableDataSource<any>;

  contractDetailsData: any = [];
  UpdatedcontractDetailsData: FormArray;
  displayedDetailsColumns: any[] = ['position', 'type', 'partNumber', 'utilParam1', 'utilRate1', 'utilParam2', 'utilRate2', 'utilParam3', 'utilRate3', 'utilParam4', 'utilRate4', 'rentalRate1', 'rentalRate2', 'rentalRate3', 'rentalRate4'];
  dataDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    this.id = this.route.snapshot.params.id

    this.content()
    this.contentDetails()
  }

  content() {

    this.gd.getContract(this.id)
      .subscribe(res => {
        console.log("contarct details  :", res);
        this.contractMngtData = []
        res.forEach(el => {
          this.contractMngtData.push({ contract: el.ContNo, lessor: el.LessorID, operator: el.Operator, model: el.ModelID, msn: el.MSN, startDate: el.StartDt, endDate: el.EndDt, status: el.Status })
        });

        this.dataSource = new MatTableDataSource(this.contractMngtData);
        const contractMngtArray = this.contractMngtData.map(data => {
          return new FormGroup({
            startDate: new FormControl(data.startDate, Validators.required),
            endDate: new FormControl(data.endDate, Validators.required),
            status: new FormControl(data.status, Validators.required),
          }, { updateOn: "blur" });
        });

        this.UpdatedcontractMngtData = new FormArray(contractMngtArray);
      });


  }

  contentDetails() {

    this.gd.getContractDetails(this.id)
      .subscribe(res => {
        console.log("contarct details  :", res);
        this.contractDetailsData = []
        this.specId = []
        this.sortedRes = []

        res.forEach(el => {
          if (el.AstType == 'Airframe') {
            this.contractDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, utilParam1: el.Param1, utilRate1: el.Rate1, utilParam2: el.Param2, utilRate2: el.Rate2, utilParam3: el.Param3, utilRate3: el.Rate3, utilParam4: el.Param4, utilRate4: el.Rate4, rentalRate1: el.RR1, rentalRate2: el.RR2, rentalRate3: el.RR3, rentalRate4: el.RR4, SpecID: el.SpecID })
          } else {
            this.contractDetailsData.push({ position: el.Position, type: el.AstType, partNumber: el.PartNo, utilParam1: el.Param1, utilRate1: el.Rate1, utilParam2: el.Param2, utilRate2: el.Rate2, SpecID: el.SpecID })
          }
        });
        if (res != []) {
          this.sortedRes = this.contractDetailsData.sort((a, b) => a.type.localeCompare(b.type))
        }
        console.log("after sort", this.contractDetailsData)
        this.sortedRes.forEach(el => {
          this.specId.push(el.SpecID)
        });

        this.dataDetailsSource = new MatTableDataSource(this.sortedRes);

        const contractDetailsArray = this.sortedRes.map(data => {
          return new FormGroup({
            utilRate1: new FormControl(data.utilRate1, Validators.required),
            utilRate2: new FormControl(data.utilRate2, Validators.required),
            utilRate3: new FormControl(data.utilRate3, Validators.required),
            utilRate4: new FormControl(data.utilRate4, Validators.required),
            rentalRate1: new FormControl(data.rentalRate1, Validators.required),
            rentalRate2: new FormControl(data.rentalRate2, Validators.required),
            rentalRate3: new FormControl(data.rentalRate3, Validators.required),
            rentalRate4: new FormControl(data.rentalRate4, Validators.required),
          }, { updateOn: "blur" });
        });

        this.UpdatedcontractDetailsData = new FormArray(contractDetailsArray);
      })
  }

  updateField(index, field) {
    const control = this.getControl(index, field);
    if (control.valid) {
      this.contractMngtData = this.contractMngtData.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value
          }
        }
        return e;
      });
      this.onChangeDataSource(this.contractMngtData);
    }
  }

  getControl(index, fieldName) {
    return this.UpdatedcontractMngtData.at(index).get(fieldName) as FormControl;
  }

  onChangeDataSource(updatedValue) {
    this.dataSource = new MatTableDataSource(updatedValue);
    this.dataSource.paginator = this.paginator;
  }

  updateDetailsField(index, field) {
    const control = this.getDetailsControl(index, field);
    if (control.valid) {
      this.contractDetailsData = this.contractDetailsData.map((e, i) => {
        if (index === i) {
          return {
            ...e,
            [field]: control.value
          }
        }
        return e;
      });
      this.onChangeDataDetailsSource(this.contractDetailsData);
    }
  }

  getDetailsControl(index, fieldName) {
    return this.UpdatedcontractDetailsData.at(index).get(fieldName) as FormControl;
  }

  onChangeDataDetailsSource(updatedValue) {
    this.dataDetailsSource = new MatTableDataSource(updatedValue);
    this.dataDetailsSource.paginator = this.paginator;
  }

  changeVal() { // triggers on change of any field(s)
    this.noChangeYet = false;
  }

  formatDate(e, val) {
    if (val == 'startDt') {
      this.contStartDt = e.target.value
    }
    if (val == 'endDt') {
      this.contEndDt = e.target.value
    }
  }

  changeDetailsVal() { // triggers on change of any field(s)
    this.noDetailsChangeYet = false;
  }

  onSubmit() {
    console.log("Updated Data on table: ", this.UpdatedcontractMngtData);

    let startDt = this.UpdatedcontractMngtData.value[0].startDate
    let endDt = this.UpdatedcontractMngtData.value[0].endDate

    if (this.contStartDt != '') {
      startDt = JSON.stringify(Math.round((this.contStartDt).getTime() / 1000));
    }
    if (this.contEndDt != '') {
      endDt = JSON.stringify(Math.round((this.contEndDt).getTime() / 1000));
    }

    var data = {
      "ContNo": this.id,
      "Status": this.UpdatedcontractMngtData.value[0].status,
      "StartDt": startDt,
      "EndDt": endDt,
      "UpdatedBy": this.currentUser.userName
    }
    console.log("new user details data: ", data);

    this.ud.updateContract(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.noChangeYet = true;
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Status of ContractID: " + this.id + " updated successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Status of ContractID: " + this.id + " updation failed!", snConfig);
        }
      });
  }

  onSubmitDetails() {
    console.log("Updated Data on table: ", this.UpdatedcontractDetailsData, this.specId);

    let data = []
    for (let i = 0; i < this.UpdatedcontractDetailsData.length; i++) {
      var obj = {
        "SpecID": this.specId[i],
        "ContNo": this.id,
        "RR1": this.UpdatedcontractDetailsData.value[i].rentalRate1,
        "RR2": this.UpdatedcontractDetailsData.value[i].rentalRate2,
        "RR3": this.UpdatedcontractDetailsData.value[i].rentalRate3,
        "RR4": this.UpdatedcontractDetailsData.value[i].rentalRate4,
        "Rate1": this.UpdatedcontractDetailsData.value[i].utilRate1,
        "Rate2": this.UpdatedcontractDetailsData.value[i].utilRate2,
        "Rate3": this.UpdatedcontractDetailsData.value[i].utilRate3,
        "Rate4": this.UpdatedcontractDetailsData.value[i].utilRate4,
        "UpdatedBy": this.currentUser.userName
      }
      data.push(obj)
    }

    console.log("new user details data: ", data);

    this.ud.updateContractDetails(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.noDetailsChangeYet = true;
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("Contract details of " + this.id + " updated successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Contract details of " + this.id + " updation failed!", snConfig);
        }
      });
  }

}
