import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { PickList } from 'src/app/models/pick-list';
import { MatTable } from '@angular/material/table';
import { GetDetailService } from 'src/app/services/getDetails.service';
import { CreateDetails } from 'src/app/services/createDetails.service';
import { SnotifyService } from 'ng-snotify';
import { SnotifyPosition } from 'ng-snotify';

@Component({
  selector: 'app-create-new-conf',
  templateUrl: './create-new-conf.component.html',
  styleUrls: ['./create-new-conf.component.scss']
})
export class CreateNewConfComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private gd: GetDetailService,
    private alert: MatSnackBar,
    private cd: CreateDetails,
    private snotService: SnotifyService) {

    this.newConfData = [
      { manufacturer: '', modelNumber: '', type: '', status: '' },
    ]
    this.newConfSource = new MatTableDataSource(this.newConfData);

    this.newConfDetailsData = [
      { position: '', type: 'Airframe', partNumber: '' },
      { position: 'P1', type: 'Engine', partNumber: '' },
      { position: 'P2', type: 'Engine', partNumber: '' },
      { position: '', type: 'APU', partNumber: '' },
      { position: 'NLG', type: 'Landing Gear', partNumber: '' },
      { position: 'MLG 1', type: 'Landing Gear', partNumber: '' },
      { position: 'MLG 2', type: 'Landing Gear', partNumber: '' },
    ]

    this.newConfDetailsSource = new MatTableDataSource(this.newConfDetailsData);
    this.updatedconfDetailsData = this.newConfDetailsData
  }

  currentUser: any;

  submitted: boolean = false;
  isSaved: boolean = false;
  isDetailsSaved: boolean = false;

  confMngtForm: FormGroup;
  get f() { return this.confMngtForm.controls; }

  confMngtDetailsForm: FormGroup;
  get fd() { return this.confMngtDetailsForm.controls; }

  pickListValues = new PickList();
  manufacturerOpts = this.pickListValues.manufacturer
  recordStatus = this.pickListValues.recStatus
  positionOpts = this.pickListValues.position
  assetTypeOpts = this.pickListValues.type
  lifeParamsOpts = this.pickListValues.lifeParams
  utilParamsOpts = this.pickListValues.utilParams

  newConfData: any = [];
  displayedColumns: any[] = ['manufacturer', 'modelNumber', 'type', 'status', 'action'];
  newConfSource: MatTableDataSource<any>;

  newConfDetailsData: any = [];
  updatedconfDetailsData: any = [];

  displayedDetailsColumns: any[] = ['position', 'type', 'partNumber'];
  newConfDetailsSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatTable, { static: true }) table: MatTable<any> //used when add a row, see comment in function addRow()

  ngOnInit() {
    this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.confMngtForm = this.fb.group({
      manufacturer: [null, Validators.required],
      modelNumber: ['', Validators.required],
      type: ['Airframe'],
      status: [null, Validators.required],
    })

    this.confMngtDetailsForm = this.fb.group({
      confMngtDetails: this.fb.array([])
    })
    this.setConfMngtDetailsForm();

    this.confMngtDetailsForm.get('confMngtDetails').valueChanges.subscribe(confMngtDetails => {
      this.updatedconfDetailsData = confMngtDetails
    });

  }

  private setConfMngtDetailsForm() {
    const rowDetailsCtrl = this.confMngtDetailsForm.get('confMngtDetails') as FormArray;
    this.newConfDetailsData.forEach((rowDetails) => {
      rowDetailsCtrl.push(this.setConfMngtDetailsFormArray(rowDetails))
    })
  };
  private setConfMngtDetailsFormArray(rowDetails) {
    return this.fb.group({
      position: [rowDetails.position],
      type: [rowDetails.type],
      partNumber: [rowDetails.partNumber],
    });
  }

  addRow() {
    const newRow = {
      position: '',
      type: '',
      partNumber: '',
    }
    this.newConfDetailsData.push(newRow)

    this.newConfDetailsSource = new MatTableDataSource(this.newConfDetailsData);
    this.newConfDetailsSource.paginator = this.paginator;

    const rowDetailsCtrl = this.confMngtDetailsForm.get('confMngtDetails') as FormArray;
    rowDetailsCtrl.push(this.setConfMngtDetailsFormArray(newRow))

    this.confMngtDetailsForm.get('confMngtDetails').valueChanges.subscribe(confMngtDetails => {
      this.updatedconfDetailsData = confMngtDetails
    });

    console.log("adding roewsss", this.newConfDetailsData, this.newConfDetailsSource)
  }


  onSubmit() {
    console.log("saveNewConfData table: ", this.confMngtForm.value);

    this.submitted = true;
    if (this.confMngtForm.invalid) {
      console.log("Invalid Form: ", this.confMngtForm);
      return;
    }

    console.log("Valid Form: ", this.confMngtForm);

    this.gd.getModel(this.confMngtForm.value.modelNumber)
      .subscribe(res => {
        console.log("model details  :", res);

        if (res.length == 0) {
          var data = {
            "ModelID": this.confMngtForm.value.modelNumber,
            "Mfg": this.confMngtForm.value.manufacturer,
            "Type": this.confMngtForm.value.type,
            "OrgName": this.currentUser.orgName,
            "CreateBy": this.currentUser.userName,
            "Status": this.confMngtForm.value.status
          }
          console.log("new org data: ", data);

          this.cd.createModel(data)
            .subscribe(r => {
              console.log("r :" + r);
              if (r.status == 'success') {
                this.isSaved = true
                const snConfig = {
                  position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
                  buttons: [{ text: 'OK', bold: true }]
                };
                this.snotService.success("New model " + data.ModelID + " created successfully!", snConfig);
              }
              else {
                const snConfig = {
                  position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
                  buttons: [{ text: 'OK', bold: true }]
                };
                this.snotService.error("New model " + data.ModelID + " creation failed!", snConfig);
              }
            });
        } else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("Model Number: " + this.confMngtForm.value.modelNumber + " already exists!", snConfig);
        }
      });

  }

  onSubmitDetails() {
    console.log("saveNewConfDetails table: ", this.updatedconfDetailsData, this.confMngtDetailsForm);

    if (this.confMngtForm.value.modelNumber == '') {
      return this.alert.open("Please enter Model Number", "OK", { duration: 4000, verticalPosition: 'top' });
    }

    this.submitted = true;
    if (this.confMngtDetailsForm.invalid) {
      console.log("Invalid Form: ", this.confMngtDetailsForm);
      return;
    }

    console.log("Valid Form: ", this.confMngtDetailsForm);

    let data = []
    for (let i = 0; i < this.updatedconfDetailsData.length; i++) {
      var obj = {
        "ModelID": this.confMngtForm.value.modelNumber,
        "Position": this.updatedconfDetailsData[i].position,
        "AstType": this.updatedconfDetailsData[i].type,
        "PartNo": this.updatedconfDetailsData[i].partNumber,
        "CreateBy": this.currentUser.userName
      }
      data.push(obj)
    }
    console.log("new model data: ", data);

    this.cd.createModelDetails(data)
      .subscribe(r => {
        console.log("r :" + r);
        if (r.status == 'success') {
          this.isDetailsSaved = true
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.success("New model details of " + this.confMngtForm.value.modelNumber + " created successfully!", snConfig);
        }
        else {
          const snConfig = {
            position: SnotifyPosition.centerCenter, backdrop: .9, timeout: 0,
            buttons: [{ text: 'OK', bold: true }]
          };
          this.snotService.error("New model details of " + this.confMngtForm.value.modelNumber + " creation failed!", snConfig);
        }
      });
  }

}

